// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import * as $$Comment from "./Comment.js";
import * as Hashtbl from "rescript/lib/es6/hashtbl.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReIndexed from "@kaiko.io/rescript-reindexed/lib/es6/src/ReIndexed.js";
import * as Belt_HashSet from "rescript/lib/es6/belt_HashSet.js";

var hash = Hashtbl.hash;

var eq = Caml_obj.equal;

var Hasher = Belt_Id.MakeHashableU({
      hash: hash,
      eq: eq
    });

function fromArray(items) {
  var contents = Belt_HashSet.make(items.length, Hasher);
  Curry._2(Prelude.$$Array.forEach, items, (function (param) {
          return Belt_HashSet.add(contents, param);
        }));
  return contents;
}

var $$Set = {
  fromArray: fromArray,
  has: Belt_HashSet.has
};

var Content = {
  Hasher: Hasher,
  $$Set: $$Set
};

var Definition = {};

var include = ReIndexed.MakeIdModel(Definition, {});

function belongingTo(object_id, c) {
  return c.object_id === object_id;
}

function make(vessel_id, body, replies_to_id, object_app, object_type, object_id, author, profile) {
  return Promise.resolve({
              id: $$Comment.Identifier.make(),
              vessel_id: vessel_id,
              replies_to_id: replies_to_id,
              body: body,
              author: author,
              profile: profile,
              object_app: object_app,
              object_type: object_type,
              object_id: object_id,
              timestamp: new Date()
            });
}

function ofVessel(vesselId) {
  return {
          TAG: "Is",
          _0: "vessel_id",
          _1: vesselId
        };
}

var IdComparator = include.IdComparator;

var Id = include.Id;

export {
  Content ,
  Definition ,
  IdComparator ,
  Id ,
  belongingTo ,
  make ,
  ofVessel ,
}
/* Hasher Not a pure module */
