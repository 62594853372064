// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as RRule from "../../../libs/RRule.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AppSchema from "../../AppSchema.js";
import * as Procedure from "../../survey/Procedure.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ProcedureId from "../../survey/ProcedureId.js";
import * as QuestionType from "../../survey/QuestionType.js";
import * as PermissionAPI from "../endpoints/PermissionAPI.js";
import * as ProcedureUpdate from "../../survey/ProcedureUpdate.js";
import * as ServerSyncPipeline from "../common/ServerSyncPipeline.js";

function $$do(state) {
  var $$final = state.final;
  var initial = state.initial;
  var vessels = state.vessels;
  var user = state.user;
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  return Prelude.catchResolve(Prelude.thenResolve(Query.read((newrecord.procedureUpdates = {
                        TAG: "AnyOf",
                        _0: "vessel_id",
                        _1: vessels
                      }, newrecord.newInspections = {
                        TAG: "AnyOf",
                        _0: "vessel_id",
                        _1: vessels
                      }, newrecord.draftInspections = {
                        TAG: "AnyOf",
                        _0: "vessel_id",
                        _1: vessels
                      }, newrecord.inspections = {
                        TAG: "AnyOf",
                        _0: "vessel_id",
                        _1: vessels
                      }, newrecord.plannedProcedures = {
                        TAG: "AnyOf",
                        _0: "vessel_id",
                        _1: vessels
                      }, newrecord.procedures = {
                        TAG: "AnyOf",
                        _0: "vessel_id",
                        _1: vessels
                      }, newrecord.components = {
                        TAG: "AnyOf",
                        _0: "vessel_id",
                        _1: vessels
                      }, newrecord.sections = {
                        TAG: "AnyOf",
                        _0: "vessel_id",
                        _1: vessels
                      }, newrecord.vessels = {
                        TAG: "In",
                        _0: vessels
                      }, newrecord)), (function (param) {
                    Curry._2(Prelude.$$Array.forEach, param.vessels, (function (extra) {
                            return AppSchema.Vessels.update(initial, extra);
                          }));
                    Curry._2(Prelude.$$Array.forEach, param.inspections, (function (extra) {
                            return AppSchema.Inspections.update(initial, extra);
                          }));
                    Curry._2(Prelude.$$Array.forEach, param.newInspections, (function (extra) {
                            return AppSchema.NewInspections.update(initial, extra);
                          }));
                    Curry._2(Prelude.$$Array.forEach, param.draftInspections, (function (extra) {
                            return AppSchema.DraftInspections.update(initial, extra);
                          }));
                    Curry._2(Prelude.$$Array.forEach, param.sections, (function (extra) {
                            return AppSchema.Sections.update(initial, extra);
                          }));
                    Curry._2(Prelude.$$Array.forEach, param.components, (function (extra) {
                            return AppSchema.Components.update(initial, extra);
                          }));
                    Curry._2(Prelude.$$Array.forEach, param.procedures, (function (extra) {
                            return AppSchema.Procedures.update(initial, extra);
                          }));
                    Curry._2(Prelude.$$Array.forEach, param.plannedProcedures, (function (extra) {
                            return AppSchema.PlannedProcedures.update(initial, extra);
                          }));
                    Curry._2(Prelude.$$Array.forEach, param.procedureUpdates, (function (extra) {
                            return AppSchema.ProcedureUpdates.update(initial, extra);
                          }));
                    return {
                            TAG: "Ok",
                            _0: {
                              user: user,
                              vessels: state.vessels,
                              initial: initial,
                              final: $$final
                            }
                          };
                  })), (function (error) {
                return {
                        TAG: "Error",
                        _0: error
                      };
              }));
}

var FillInitialStatus = {
  $$do: $$do
};

function convertInspection(state, getProcedure, inspection) {
  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, getProcedure(inspection.procedure_id), (function (procedure) {
                    var newInspection_id = inspection.id;
                    var newInspection_procedure_id = inspection.procedure_id;
                    var newInspection_component_id = inspection.component_id;
                    var newInspection_section_id = inspection.section_id;
                    var newInspection_vessel_id = inspection.vessel_id;
                    var newInspection_inspector_id = inspection.inspector_id;
                    var newInspection_timestamp = inspection.timestamp;
                    var newInspection_code = inspection.code;
                    var newInspection_annotations = inspection.annotations;
                    var newInspection_additional_annotation = inspection.additional_annotation;
                    var newInspection_picture_prompted = inspection.picture_prompted;
                    var newInspection_walked_steps = inspection.walked_steps;
                    var newInspection_app_version = inspection.app_version;
                    var newInspection_deficiency_id = inspection.deficiency_id;
                    var newInspection_attachments = inspection.attachments;
                    var newInspection_value = QuestionType.Value.make(procedure.question_type, Caml_option.some(inspection.rating), Caml_option.some(inspection.rating), Caml_option.some(inspection.component_weight_value), Caml_option.some(inspection.date_value), Caml_option.some(inspection.text_value), Caml_option.some(inspection.is_yes_value), Caml_option.some(inspection.integer_value), undefined);
                    var newInspection_profile = inspection.profile;
                    var newInspection = {
                      id: newInspection_id,
                      procedure_id: newInspection_procedure_id,
                      component_id: newInspection_component_id,
                      section_id: newInspection_section_id,
                      vessel_id: newInspection_vessel_id,
                      inspector_id: newInspection_inspector_id,
                      timestamp: newInspection_timestamp,
                      code: newInspection_code,
                      annotations: newInspection_annotations,
                      additional_annotation: newInspection_additional_annotation,
                      picture_prompted: newInspection_picture_prompted,
                      walked_steps: newInspection_walked_steps,
                      app_version: newInspection_app_version,
                      deficiency_id: newInspection_deficiency_id,
                      attachments: newInspection_attachments,
                      value: newInspection_value,
                      deadline: undefined,
                      profile: newInspection_profile
                    };
                    AppSchema.Inspections.update(state, newInspection);
                    return state;
                  })), state);
}

function convertTour(state, tour) {
  var getProcedure = function (extra) {
    return AppSchema.Procedures.get(state, extra);
  };
  return Curry._3(Prelude.$$Array.fold, tour.latest_inspections, state, (function (s, insp) {
                return convertInspection(s, getProcedure, insp);
              }));
}

function convertProcedure(vessel, section, component, state, procedure) {
  var recurrence = RRule.toSet(procedure.recurrence);
  var harmonizationDate = RRule.$$Set.harmonizationDate(recurrence);
  var proc_id = procedure.id;
  var proc_vessel_id = vessel.id;
  var proc_section_id = section.id;
  var proc_component_id = component.id;
  var proc_name = procedure.name;
  var proc_order = procedure.order;
  var proc_position_diagram = procedure.position_diagram;
  var proc_position_diagram_desc = Curry._2(Prelude.OptionExported.$$Option.flatMap, Curry._2(Prelude.OptionExported.$$Option.map, procedure.position_diagram_desc, (function (prim) {
              return prim.trim();
            })), (function (s) {
          if (s.length > 0) {
            return s;
          }
          
        }));
  var proc_example_image = procedure.example_image;
  var proc_example_image_desc = Curry._2(Prelude.OptionExported.$$Option.flatMap, Curry._2(Prelude.OptionExported.$$Option.map, procedure.example_image_desc, (function (prim) {
              return prim.trim();
            })), (function (s) {
          if (s.length > 0) {
            return s;
          }
          
        }));
  var proc_description = Curry._2(Prelude.OptionExported.$$Option.flatMap, Curry._2(Prelude.OptionExported.$$Option.map, procedure.description, (function (prim) {
              return prim.trim();
            })), (function (s) {
          if (s.length > 0) {
            return s;
          }
          
        }));
  var proc_question_type = procedure.question_type;
  var proc_rating_styling_rules_id = procedure.rating_styling_rules_id;
  var proc_require_image_conditions = Prelude.$$Array.first(Prelude.$$Array.keepSome([
            procedure.require_image_conditions,
            component.require_image_conditions,
            section.require_image_conditions
          ]));
  var proc_recurrence = procedure.recurrence;
  var proc_overdue_date = Prelude.default(RRule.$$Set.after(recurrence, harmonizationDate, undefined), harmonizationDate);
  var proc_ignore_overdue_status = procedure.ignore_overdue_status;
  var proc_vessel_position = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, procedure.vessel_position, (function (value) {
              return value;
            })), "");
  var proc_suggested_text_values = procedure.suggested_text_values;
  var proc_not_applicable_status = procedure.not_applicable_status;
  var proc_allow_not_applicable_status = procedure.allow_not_applicable_status;
  var proc_always_require_additional_annotation = procedure.always_require_additional_annotation;
  var proc_rank = procedure.rank;
  var proc_extra = {
    section_name: section.name,
    component_name: component.name,
    section_type: section.type,
    component_type: component.type,
    component_safety_type: component.safety_type
  };
  var proc = {
    id: proc_id,
    vessel_id: proc_vessel_id,
    section_id: proc_section_id,
    component_id: proc_component_id,
    name: proc_name,
    order: proc_order,
    position_diagram: proc_position_diagram,
    position_diagram_desc: proc_position_diagram_desc,
    example_image: proc_example_image,
    example_image_desc: proc_example_image_desc,
    description: proc_description,
    question_type: proc_question_type,
    rating_styling_rules_id: proc_rating_styling_rules_id,
    require_image_conditions: proc_require_image_conditions,
    recurrence: proc_recurrence,
    due_date: harmonizationDate,
    overdue_date: proc_overdue_date,
    ignore_overdue_status: proc_ignore_overdue_status,
    vessel_position: proc_vessel_position,
    suggested_text_values: proc_suggested_text_values,
    not_applicable_status: proc_not_applicable_status,
    allow_not_applicable_status: proc_allow_not_applicable_status,
    always_require_additional_annotation: proc_always_require_additional_annotation,
    rank: proc_rank,
    extra: proc_extra
  };
  AppSchema.Procedures.update(state, proc);
  return state;
}

function convertComponent(vessel, section, state, component) {
  var appComponent_id = component.id;
  var appComponent_vessel_id = vessel.id;
  var appComponent_section_id = component.section_id;
  var appComponent_name = component.name;
  var appComponent_number = component.number;
  var appComponent_order = component.order;
  var appComponent_type = component.type;
  var appComponent_safety_type = component.safety_type;
  var appComponent_suggested_annotations = component.suggested_annotations;
  var appComponent_rating_styling_rules_id = component.rating_styling_rules_id;
  var appComponent_require_image_conditions = Prelude.$$Array.first(Prelude.$$Array.keepSome([
            component.require_image_conditions,
            section.require_image_conditions
          ]));
  var appComponent = {
    id: appComponent_id,
    vessel_id: appComponent_vessel_id,
    section_id: appComponent_section_id,
    name: appComponent_name,
    number: appComponent_number,
    order: appComponent_order,
    type: appComponent_type,
    safety_type: appComponent_safety_type,
    suggested_annotations: appComponent_suggested_annotations,
    rating_styling_rules_id: appComponent_rating_styling_rules_id,
    require_image_conditions: appComponent_require_image_conditions
  };
  AppSchema.Components.update(state, appComponent);
  return Curry._3(Prelude.$$Array.fold, component.inspection_procedures, state, (function (extra, extra$1) {
                return convertProcedure(vessel, section, appComponent, extra, extra$1);
              }));
}

function convertSection(vessel, state, section) {
  var appSection_id = section.id;
  var appSection_vessel_id = section.vessel_id;
  var appSection_name = section.name;
  var appSection_type = section.type;
  var appSection_image = section.image;
  var appSection_picture_prompt_every_n_inspections = section.picture_prompt_every_n_inspections;
  var appSection_rating_styling_rules_id = section.rating_styling_rules_id;
  var appSection_require_image_conditions = section.require_image_conditions;
  var appSection_guidance_document = section.guidance_document;
  var appSection_archived = section.archived;
  var appSection = {
    id: appSection_id,
    vessel_id: appSection_vessel_id,
    name: appSection_name,
    type: appSection_type,
    image: appSection_image,
    picture_prompt_every_n_inspections: appSection_picture_prompt_every_n_inspections,
    rating_styling_rules_id: appSection_rating_styling_rules_id,
    require_image_conditions: appSection_require_image_conditions,
    guidance_document: appSection_guidance_document,
    archived: appSection_archived
  };
  AppSchema.Sections.update(state, appSection);
  return Curry._3(Prelude.$$Array.fold, section.components, state, (function (extra, extra$1) {
                return convertComponent(vessel, appSection, extra, extra$1);
              }));
}

function convertVessel(state, vessel) {
  var appVessel_id = vessel.id;
  var appVessel_name = vessel.name;
  var appVessel_max_pic_resolution = vessel.max_pic_resolution;
  var appVessel_picture_prompt_every_n_inspections = vessel.picture_prompt_every_n_inspections;
  var appVessel_company_logo = vessel.company_logo;
  var appVessel_migrated = vessel.migrated;
  var appVessel_mandatory_findings_deadline = vessel.mandatory_findings_deadline;
  var appVessel_mandatory_findings_observations = vessel.mandatory_findings_observations;
  var appVessel_hide_observations = vessel.hide_observations;
  var appVessel_use_embedded_camera = vessel.use_embedded_camera;
  var appVessel = {
    id: appVessel_id,
    name: appVessel_name,
    max_pic_resolution: appVessel_max_pic_resolution,
    picture_prompt_every_n_inspections: appVessel_picture_prompt_every_n_inspections,
    company_logo: appVessel_company_logo,
    migrated: appVessel_migrated,
    mandatory_findings_deadline: appVessel_mandatory_findings_deadline,
    mandatory_findings_observations: appVessel_mandatory_findings_observations,
    hide_observations: appVessel_hide_observations,
    use_embedded_camera: appVessel_use_embedded_camera
  };
  var state$1 = Curry._3(Prelude.$$Array.fold, vessel.sections, state, (function (extra, extra$1) {
          return convertSection(appVessel, extra, extra$1);
        }));
  AppSchema.Vessels.update(state$1, appVessel);
  return Curry._3(Prelude.$$Array.fold, vessel.sections, state$1, (function (extra, extra$1) {
                return convertSection(appVessel, extra, extra$1);
              }));
}

function convertPermission(state, permission) {
  return convertTour(convertVessel(state, permission.vessel), permission.tour);
}

function convertPermissions(state, permissions) {
  return Curry._3(Prelude.$$Array.fold, permissions, state, convertPermission);
}

function $$do$1(state) {
  var $$final = state.final;
  var initial = state.initial;
  var vessels = state.vessels;
  var user = state.user;
  return Prelude.PromisedResult.map(Prelude.PromisedResult.map(PermissionAPI.getAll(undefined, {
                      vessel__id__in: vessels.join(",")
                    }, user), (function (extra) {
                    return convertPermissions($$final, extra);
                  })), (function ($$final) {
                return {
                        user: user,
                        vessels: vessels,
                        initial: initial,
                        final: $$final
                      };
              }));
}

var ReadServerState = {
  convertInspection: convertInspection,
  convertTour: convertTour,
  convertProcedure: convertProcedure,
  convertComponent: convertComponent,
  convertSection: convertSection,
  convertVessel: convertVessel,
  convertPermission: convertPermission,
  convertPermissions: convertPermissions,
  $$do: $$do$1
};

function $$do$2(state) {
  var $$final = state.final;
  var initial = state.initial;
  var keptProcedures = ProcedureId.$$Set.fromArray(AppSchema.Procedures.all($$final).map(function (p) {
            return p.id;
          }));
  Curry._2(Prelude.$$Array.forEach, Curry._2(Prelude.$$Array.keep, AppSchema.DraftInspections.all(initial), (function (i) {
              return ProcedureId.$$Set.has(keptProcedures, i.procedure_id);
            })), (function (extra) {
          return AppSchema.DraftInspections.update($$final, extra);
        }));
  var newInspections = AppSchema.NewInspections.all(initial);
  Curry._2(Prelude.$$Array.forEach, Curry._2(Prelude.$$Array.keep, newInspections, (function (i) {
              return ProcedureId.$$Set.has(keptProcedures, i.procedure_id);
            })), (function (extra) {
          return AppSchema.NewInspections.update($$final, extra);
        }));
  var updates = AppSchema.ProcedureUpdates.all(initial);
  var $$final$1 = AppSchema.Procedures.updateMany($$final, ProcedureUpdate.apply(updates, AppSchema.Procedures.all($$final)));
  var allInspections = Curry._2(Prelude.$$Array.concat, AppSchema.NewInspections.all($$final$1), AppSchema.Inspections.all($$final$1));
  var procedures = AppSchema.Procedures.all($$final$1).map(function (procedure) {
        return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Procedure.lastInspection(procedure, allInspections), (function (inspection) {
                          var recurrence = RRule.toSet(procedure.recurrence);
                          var due_date = Prelude.default(RRule.$$Set.after(recurrence, inspection.timestamp, undefined), inspection.timestamp);
                          var overdue_date = Prelude.default(RRule.$$Set.after(recurrence, due_date, undefined), due_date);
                          var newrecord = Caml_obj.obj_dup(procedure);
                          newrecord.overdue_date = overdue_date;
                          newrecord.due_date = due_date;
                          return newrecord;
                        })), procedure);
      });
  Curry._2(Prelude.$$Array.forEach, procedures, (function (extra) {
          return AppSchema.Procedures.update($$final$1, extra);
        }));
  var planned = AppSchema.PlannedProcedures.map(initial);
  Curry._2(Prelude.$$Array.forEach, Curry._2(Prelude.$$Array.keep, procedures, (function (p) {
              return ProcedureId.$$Map.has(planned, p.id);
            })), (function (extra) {
          return AppSchema.PlannedProcedures.update($$final$1, extra);
        }));
  return Promise.resolve({
              TAG: "Ok",
              _0: {
                user: state.user,
                vessels: state.vessels,
                initial: initial,
                final: $$final$1
              }
            });
}

var ApplyLocallyKnownState = {
  $$do: $$do$2
};

function $$do$3(state) {
  var $$final = state.final;
  var initial = state.initial;
  var newrecord = Caml_obj.obj_dup(Query.makeWrite());
  var newrecord$1 = Caml_obj.obj_dup(Query.makeWrite());
  var actions = [
    (newrecord.components = AppSchema.Components.getActions(initial, $$final), newrecord.sections = AppSchema.Sections.getActions(initial, $$final), newrecord),
    (newrecord$1.newInspections = AppSchema.NewInspections.getActions(initial, $$final), newrecord$1.draftInspections = AppSchema.DraftInspections.getActions(initial, $$final), newrecord$1.plannedProcedures = AppSchema.PlannedProcedures.getActions(initial, $$final), newrecord$1.procedures = AppSchema.Procedures.getActions(initial, $$final), newrecord$1)
  ];
  Curry._2(Prelude.$$Array.forEach, actions, (function (extra) {
          return Query.ActionLogging.info("ServerSyncPermissions" + ": db actions: ", extra);
        }));
  return Prelude.catchResolve(Prelude.thenResolve(Query.doEach(actions.map(function (write) {
                          return {
                                  TAG: "Write",
                                  _0: (function (param) {
                                      return write;
                                    })
                                };
                        })), (function (param) {
                    return {
                            TAG: "Ok",
                            _0: state
                          };
                  })), (function (error) {
                return {
                        TAG: "Error",
                        _0: error
                      };
              }));
}

var UpdateDB = {
  $$do: $$do$3
};

var views = [
  AppSchema.Vessels,
  AppSchema.Sections,
  AppSchema.Components,
  AppSchema.Procedures,
  AppSchema.Inspections
];

var CollectFiles = ServerSyncPipeline.MakeFilesCollector({
      views: views
    });

var views$1 = [
  AppSchema.Vessels,
  AppSchema.Components
];

var CleanUpState = ServerSyncPipeline.MakeStandardCleanup({
      views: views$1
    });

var include = ServerSyncPipeline.MakeStandardAPIPipeline({
      name: "ServerSyncPermissions",
      ReadServerState: {
        $$do: $$do$1
      },
      FillInitialStatus: FillInitialStatus,
      ApplyLocallyKnownState: ApplyLocallyKnownState,
      UpdateDB: UpdateDB,
      CollectFiles: CollectFiles,
      CleanUpState: CleanUpState
    });

var $$do$4 = include.$$do;

export {
  FillInitialStatus ,
  ReadServerState ,
  ApplyLocallyKnownState ,
  UpdateDB ,
  $$do$4 as $$do,
}
/* include Not a pure module */
