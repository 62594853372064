// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as RRule from "../../libs/RRule.js";
import * as Luxon from "luxon";
import * as LevelId from "./LevelId.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as VesselId from "../vessel/VesselId.js";
import * as ReIndexed from "@kaiko.io/rescript-reindexed/lib/es6/src/ReIndexed.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as LevelTypeId from "./LevelTypeId.js";
import * as LevelSubmission from "./LevelSubmission.js";
import * as CompletionStatus from "../../libs/CompletionStatus.js";
import * as MarkdownDocumentId from "../markdown/MarkdownDocumentId.js";

var _map = {"low":"low","medium":"medium","high":"high"};

function riskStatusToJs(param) {
  return param;
}

function riskStatusFromJs(param) {
  return _map[param];
}

var Definition = {};

var null_parent_id = Caml_option.some(LevelId.$$null);

var null_guidance_document_id = Caml_option.some(MarkdownDocumentId.$$null);

var $$null = {
  id: LevelId.$$null,
  parent_id: null_parent_id,
  vessel_id: VesselId.$$null,
  guidance_document_id: null_guidance_document_id,
  name: "",
  path: "",
  number: undefined,
  depth: -1,
  recurrence: undefined,
  due_date: undefined,
  overdue_date: undefined,
  ignore_overdue_status: true,
  kind: LevelTypeId.$$null,
  risk_status: undefined
};

var Defaults = {
  $$null: $$null
};

var include = ReIndexed.MakeIdModel(Definition, {});

function ofVessel(vesselId) {
  return {
          TAG: "Is",
          _0: "vessel_id",
          _1: vesselId
        };
}

function childrenOf(parent) {
  return {
          TAG: "Is",
          _0: "parent_id",
          _1: parent
        };
}

function root(vesselId) {
  return {
          TAG: "And",
          _0: {
            TAG: "Is",
            _0: "depth",
            _1: 1
          },
          _1: {
            TAG: "Is",
            _0: "vessel_id",
            _1: vesselId
          }
        };
}

function ofKinds(kindIds) {
  return {
          TAG: "AnyOf",
          _0: "kind",
          _1: kindIds
        };
}

function branch(path) {
  var paths = Curry._3(Prelude.$$Array.rangeBy, 4, path.length, 4).map(function (length) {
        return path.substr(0, length);
      });
  return {
          TAG: "AnyOf",
          _0: "path",
          _1: paths
        };
}

function chapters(vesselId) {
  return {
          TAG: "And",
          _0: {
            TAG: "Is",
            _0: "depth",
            _1: 2
          },
          _1: {
            TAG: "Is",
            _0: "vessel_id",
            _1: vesselId
          }
        };
}

function getOverdueText(level, questions) {
  var today = new Date();
  var overdue_dates = Curry._2(Prelude.$$Array.keepMap, Curry._2(Prelude.$$Array.keep, Curry._1(Prelude.$$Array.concatMany, questions.map(function (q) {
                    return q.levels;
                  })), (function (l) {
              return l.level_path.startsWith(level.path);
            })), (function (l) {
          return l.overdue_date;
        }));
  var minDate = Prelude.default(Prelude.$$Array.first(overdue_dates), today);
  var overdue_date = overdue_dates.reduce((function (a, b) {
          if (Caml_obj.lessthan(a, b)) {
            return a;
          } else {
            return b;
          }
        }), minDate);
  var due_dates = Curry._2(Prelude.$$Array.keepMap, Curry._2(Prelude.$$Array.keep, Curry._1(Prelude.$$Array.concatMany, questions.map(function (q) {
                    return q.levels;
                  })), (function (l) {
              return l.level_path.startsWith(level.path);
            })), (function (l) {
          return l.due_date;
        }));
  var minDate$1 = Prelude.default(Prelude.$$Array.first(due_dates), today);
  var due_date = due_dates.reduce((function (a, b) {
          if (Caml_obj.lessthan(a, b)) {
            return a;
          } else {
            return b;
          }
        }), minDate$1);
  return CompletionStatus.getDueTextFromDates(level.ignore_overdue_status, Prelude.default(level.due_date, due_date), Prelude.default(level.overdue_date, overdue_date));
}

function byName(level) {
  return level.name;
}

var _map$1 = {"recurrent":"recurrent","oneOff":"oneOff"};

function type_ToJs(param) {
  return param;
}

function type_FromJs(param) {
  return _map$1[param];
}

function getType(level) {
  if (Curry._1(Prelude.OptionExported.$$Option.isSome, level.recurrence)) {
    return "recurrent";
  } else {
    return "oneOff";
  }
}

function expectedSubmissions(level, submissions, draftSubmissionIds) {
  var recurrence = level.recurrence;
  if (recurrence === undefined) {
    return [];
  }
  var recurrence$1 = Caml_option.valFromOption(recurrence);
  var rset = RRule.toSet(recurrence$1);
  var today = new Date();
  var submissions$1 = Prelude.$$Array.sort(submissions, LevelSubmission.byTargetDate, undefined);
  var harmonizationDate = RRule.$$Set.harmonizationDate(rset);
  var oldestSubmission = Prelude.$$Array.first(submissions$1);
  var begining;
  if (oldestSubmission !== undefined) {
    begining = oldestSubmission.target_date;
  } else {
    var today$1 = Luxon.DateTime.fromJSDate(today);
    var interval = Luxon.Interval.fromDateTimes(Luxon.DateTime.fromJSDate(harmonizationDate), today$1);
    begining = interval.length("years") >= 1.0 ? today$1.minus({
              years: 1
            }).toJSDate() : harmonizationDate;
  }
  var begining$1 = Prelude.default(RRule.$$Set.before(rset, begining, true), begining);
  var end = Prelude.default(RRule.$$Set.after(rset, today, undefined), today);
  var occurrences = rset.between(begining$1, end, true);
  var number = {
    contents: 0
  };
  return Curry._1(Prelude.$$Array.concatMany, Curry._2(Prelude.$$Array.zip, occurrences, Curry._2(Prelude.$$Array.sliceToEnd, occurrences, 1)).map(function (param) {
                  var until = param[1];
                  var since = param[0];
                  var submissions$2 = Curry._2(Prelude.$$Array.keep, submissions$1, (function (submission) {
                          if (Caml_obj.lessequal(since, submission.target_date)) {
                            return Caml_obj.lessthan(submission.target_date, until);
                          } else {
                            return false;
                          }
                        }));
                  if (submissions$2.length !== 0) {
                    return submissions$2.map(function (submission) {
                                number.contents = number.contents + 1 | 0;
                                return {
                                        id: number.contents,
                                        start_date: since,
                                        end_date: until,
                                        recurrence: recurrence$1,
                                        submission: submission,
                                        finished: !Prelude.$$Array.includes(draftSubmissionIds, submission.id)
                                      };
                              });
                  } else {
                    number.contents = number.contents + 1 | 0;
                    return [{
                              id: number.contents,
                              start_date: since,
                              end_date: until,
                              recurrence: recurrence$1,
                              submission: undefined,
                              finished: false
                            }];
                  }
                }));
}

function isKindOf(level, kind, levelTypes) {
  var kindByLevelType = LevelTypeId.$$Map.fromArray(levelTypes.map(function (i) {
            return [
                    i.id,
                    i.kind
                  ];
          }));
  return LevelTypeId.$$Map.get(kindByLevelType, level.kind) === kind;
}

var Identifier;

var steplen = 4;

var IdComparator = include.IdComparator;

var Id = include.Id;

export {
  Identifier ,
  steplen ,
  riskStatusToJs ,
  riskStatusFromJs ,
  Definition ,
  Defaults ,
  IdComparator ,
  Id ,
  ofVessel ,
  childrenOf ,
  root ,
  ofKinds ,
  branch ,
  chapters ,
  getOverdueText ,
  byName ,
  type_ToJs ,
  type_FromJs ,
  getType ,
  expectedSubmissions ,
  isKindOf ,
}
/* include Not a pure module */
