// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../URLs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Luxon from "luxon";
import * as Feather from "../../common/views/Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Procedure from "../../survey/Procedure.js";
import * as Deficiency from "../Deficiency.js";
import * as Inspection from "../../survey/Inspection.js";
import * as ListLayout from "../../common/views/ListLayout.js";
import * as LocalImage from "../../../libs/LocalImage.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as CommentList from "../../comments/views/CommentList.js";
import * as DeficiencyId from "../DeficiencyId.js";
import * as DeadlineLabel from "./DeadlineLabel.js";
import * as SurveyCommonViews from "../../survey/views/SurveyCommonViews.js";

function title(vnode) {
  var match = vnode.state.data;
  if (typeof match !== "object") {
    return "";
  } else {
    return Prelude.default(Prelude.$$Array.firstUnsafe(match.inspections).code, "");
  }
}

function populate(vnode) {
  var deficiencyId = DeficiencyId.Route.param("deficiencyId");
  Prelude.thenDo(Query.doEach([
            {
              TAG: "Read",
              _0: (function (param) {
                  var newrecord = Caml_obj.obj_dup(Query.makeRead());
                  newrecord.deficiencies = {
                    TAG: "Get",
                    _0: deficiencyId
                  };
                  newrecord.inspections = Inspection.ofDeficiency(deficiencyId);
                  return newrecord;
                })
            },
            {
              TAG: "Read",
              _0: (function (param) {
                  var newrecord = Caml_obj.obj_dup(Query.makeRead());
                  newrecord.procedures = Procedure.ofInspection(Prelude.$$Array.first(param.inspections));
                  return newrecord;
                })
            }
          ]), (function (param) {
          var deficiencies = param.deficiencies;
          var inspections = param.inspections;
          var procedures = param.procedures;
          if (inspections.length !== 0) {
            if (procedures.length !== 0) {
              var len = deficiencies.length;
              if (len !== 1) {
                window.history.back();
                return ;
              }
              var deficiency = deficiencies[0];
              if (procedures.length !== 1) {
                window.history.back();
                return ;
              }
              var procedure = procedures[0];
              return Mithril.setState(vnode, {
                          TAG: "Loaded",
                          deficiency: deficiency,
                          procedure: procedure,
                          inspections: Prelude.$$Array.sort(inspections, Inspection.byTimestamp, undefined),
                          currentInspection: 0
                        });
            }
            window.history.back();
            return ;
          }
          window.history.back();
        }));
}

function changeSlide(vnode, $$event) {
  var value = $$event.target.value;
  var match = vnode.state.data;
  if (typeof match !== "object" || value === undefined) {
    return ;
  } else {
    vnode.state.data = {
      TAG: "Loaded",
      deficiency: match.deficiency,
      procedure: match.procedure,
      inspections: match.inspections,
      currentInspection: Caml_option.valFromOption(value)
    };
    return ;
  }
}

function make(_vnode) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), populate), (function (vnode) {
                var match = vnode.state.data;
                var tmp;
                if (typeof match !== "object") {
                  tmp = Mithril.empty;
                } else {
                  var inspections = match.inspections;
                  var deficiency = match.deficiency;
                  var inspection = inspections[match.currentInspection];
                  var additional_annotation = inspection.additional_annotation.trim();
                  var annotations = (
                      additional_annotation !== "" ? Prelude.$$Array.append(inspection.annotations, inspection.additional_annotation) : inspection.annotations
                    ).join(", ");
                  tmp = ReactDOM.jsxs("div", {
                        children: [
                          match.procedure.question_type === "WeightDeltaQuestion" ? Mithril.empty : ReactDOM.jsxs("div", {
                                  children: [
                                    inspections.map(function (i, index) {
                                          return ReactDOM.jsx("input", {
                                                      className: "carousel-locator",
                                                      hidden: true,
                                                      id: "slide-" + String(index),
                                                      checked: Caml_obj.equal(i.id, inspection.id),
                                                      name: "carousel-radio",
                                                      type: "radio",
                                                      value: String(index),
                                                      onChange: (function (extra) {
                                                          return changeSlide(vnode, extra);
                                                        })
                                                    });
                                        }),
                                    ReactDOM.jsx("div", {
                                          children: inspections.map(function (inspection, index) {
                                                return ReactDOM.jsxs("figure", {
                                                            children: [
                                                              0 <= (index - 1 | 0) ? ReactDOM.jsx("label", {
                                                                      children: React.jsx(Feather.make, {
                                                                            icon: "chevron_left"
                                                                          }),
                                                                      className: "item-prev btn btn-action s-circle btn-sm",
                                                                      htmlFor: "slide-" + String(index - 1 | 0)
                                                                    }) : Mithril.empty,
                                                              (index + 1 | 0) < inspections.length ? ReactDOM.jsx("label", {
                                                                      children: React.jsx(Feather.make, {
                                                                            icon: "chevron_right"
                                                                          }),
                                                                      className: "item-next btn btn-action s-circle btn-sm",
                                                                      htmlFor: "slide-" + String(index + 1 | 0)
                                                                    }) : Mithril.empty,
                                                              React.jsx(SurveyCommonViews.ProcedureValueLabel.make, {
                                                                    inspection: inspection,
                                                                    className: "text-small",
                                                                    floatRight: true
                                                                  }),
                                                              Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Inspection.getMainImage(inspection), (function (resource) {
                                                                          return ReactDOM.jsx("div", {
                                                                                      children: React.jsx(LocalImage.Resource.make, {
                                                                                            resource: resource,
                                                                                            id: inspection.id
                                                                                          })
                                                                                    });
                                                                        })), Mithril.empty)
                                                            ],
                                                            className: "carousel-item"
                                                          });
                                              }),
                                          className: "carousel-container"
                                        }),
                                    ReactDOM.jsx("div", {
                                          children: inspections.map(function (_inspection, index) {
                                                return ReactDOM.jsx("label", {
                                                            children: index,
                                                            className: "nav-item text-hide c-hand",
                                                            htmlFor: "slide-" + String(index)
                                                          });
                                              }),
                                          className: "carousel-nav"
                                        })
                                  ],
                                  className: "carousel"
                                }),
                          ReactDOM.jsx("small", {
                                children: Luxon.DateTime.fromJSDate(inspection.timestamp).setZone("UTC").toLocaleString(Luxon.DateTime.DATETIME_FULL)
                              }),
                          ReactDOM.jsx("div", {
                                className: "divider blank"
                              }),
                          ReactDOM.jsxs("div", {
                                children: [
                                  ReactDOM.jsx("strong", {
                                        children: "Deadline: "
                                      }),
                                  React.jsx(DeadlineLabel.make, {
                                        status: deficiency.status,
                                        deadline: deficiency.deadline,
                                        className: "text-tiny"
                                      })
                                ]
                              }),
                          React.jsx(SurveyCommonViews.InspectionLocation.make, {
                                inspection: inspection,
                                small: false
                              }),
                          annotations.length > 0 ? ReactDOM.jsxs("div", {
                                  children: [
                                    ReactDOM.jsx("strong", {
                                          children: "Observations: "
                                        }),
                                    ReactDOM.jsx("small", {
                                          children: annotations
                                        })
                                  ]
                                }) : Mithril.empty,
                          React.jsx(CommentList.make, {
                                vessel_id: inspection.vessel_id,
                                object_app: Deficiency.appName,
                                object_type: "deficiency",
                                object_id: deficiency.id,
                                comments: deficiency.comments
                              })
                        ]
                      });
                }
                var match$1 = vnode.state.data;
                var tmp$1;
                if (typeof match$1 !== "object" || match$1.deficiency.status === "rectified") {
                  tmp$1 = Mithril.empty;
                } else {
                  var procedure = match$1.procedure;
                  var match$2 = procedure.question_type;
                  tmp$1 = match$2 === "WeightDeltaQuestion" ? React.jsxs(Mithril.Link.make, {
                          href: URLs.startWeightMeasurementInspectionProcedure(procedure.id, undefined, -3, undefined, undefined, undefined),
                          className: "btn btn-block btn-success btn-shadow",
                          children: [
                            "Rectify finding",
                            React.jsx(Feather.make, {
                                  icon: "arrow_right",
                                  style: "margin-left: 0.2rem;"
                                })
                          ]
                        }) : React.jsxs(Mithril.Link.make, {
                          href: URLs.startInspectionProcedure(procedure.id, undefined, undefined, -3, true, undefined, undefined),
                          className: "btn btn-block btn-success btn-shadow",
                          children: [
                            "Rectify finding",
                            React.jsx(Feather.make, {
                                  icon: "arrow_right",
                                  style: "margin-left: 0.2rem;"
                                })
                          ]
                        });
                }
                return React.jsx(ListLayout.make, {
                            children: tmp,
                            title: title(vnode),
                            overNavbar: Caml_option.some(tmp$1),
                            overNavbarHeight: "2rem",
                            showSettings: true,
                            section: "deficiencies"
                          });
              }));
}

export {
  title ,
  populate ,
  changeSlide ,
  make ,
}
/* URLs Not a pure module */
