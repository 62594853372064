// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../../URLs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Forms from "../../../common/Forms.js";
import * as Query from "../../../Query.js";
import * as React from "../../../../libs/rescript-mithril/src/React.js";
import * as Vessel from "../../../vessel/Vessel.js";
import * as Feather from "../../../common/views/Feather.js";
import * as Mithril from "../../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril$1 from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../../libs/rescript-mithril/src/ReactDOM.js";
import * as LocalImage from "../../../../libs/LocalImage.js";
import * as QuestionId from "../../QuestionId.js";
import * as Hyphenated from "hyphenated";
import * as AnswerValue from "../../AnswerValue.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Conditional from "../../../../libs/Conditional.js";
import * as TakePicture from "../../../common/views/TakePicture.js";
import MarkdownIt from "markdown-it";
import * as DetailImages from "../../../common/views/DetailImages.js";
import * as DialogNewEdit from "../../../../libs/DialogNewEdit.js";
import * as GestureWrapper from "../../../../libs/GestureWrapper.js";
import * as KeepScrollLink from "../../../../libs/KeepScrollLink.js";
import * as Mithril__Route from "../../../../libs/rescript-mithril/src/Mithril__Route.js";
import * as QuestionWidget from "./QuestionWidget.js";
import * as State__Answers from "../../../state/State__Answers.js";
import * as NavigationPills from "../../../common/views/NavigationPills.js";
import * as ConditionMatcher from "../../../common/ConditionMatcher.js";
import * as MarkdownDocumentId from "../../../markdown/MarkdownDocumentId.js";
import * as DialogNewEditAnswer from "./DialogNewEditAnswer.js";
import * as NotApplicableDialog from "../../../vessel/views/NotApplicableDialog.js";
import * as NotApplicableStatus from "../../../survey/NotApplicableStatus.js";
import * as MarkdownDocumentPage from "../../../markdown/views/MarkdownDocumentPage.js";
import * as FutureAttachmentManager from "../../../attachment_chunks/FutureAttachmentManager.js";

function processAttachment(vnode, _image, attachment, param) {
  var match = vnode.state.data;
  if (typeof match !== "object") {
    return ;
  } else {
    return Mithril.setState(vnode, {
                TAG: "Loaded",
                vessel: match.vessel,
                lastNewAnswer: match.lastNewAnswer,
                lastAnswer: match.lastAnswer,
                question: match.question,
                document: match.document,
                level: match.level,
                value: match.value,
                step: "reviewPicture",
                remarks: match.remarks,
                lastRemark: match.lastRemark,
                pictures: Curry._2(Prelude.$$Array.concat, match.pictures, [attachment]),
                picture_prompted: match.picture_prompted
              });
  }
}

function allowSave(vnode) {
  var match = vnode.state.data;
  if (typeof match !== "object") {
    return false;
  }
  var value = match.value;
  var match$1 = match.question.kind;
  var correctAnswer = typeof match$1 === "object" && match$1.NAME === "YesNoQuestion" ? match$1.VAL.correct_answer : true;
  if (value === correctAnswer) {
    return true;
  } else if (value !== correctAnswer) {
    return match.remarks !== "";
  } else {
    return false;
  }
}

function getValue(vnode, param) {
  var match = vnode.state.data;
  if (typeof match !== "object") {
    return {
            attachments: []
          };
  } else {
    return {
            value: {
              NAME: "Value",
              VAL: {
                NAME: "YesNo",
                VAL: match.value
              }
            },
            attachments: match.pictures,
            remarks: match.remarks
          };
  }
}

function YesNoQuestionWidget$Editor(props) {
  return Mithril.view(Mithril.onupdate(Mithril.oncreate(Mithril.component("NotReady"), Forms.Input.focus), Forms.Input.focus), (function (vnode) {
                var match = vnode.state.data;
                var tmp;
                if (typeof match !== "object") {
                  tmp = Mithril.empty;
                } else {
                  var picture_prompted = match.picture_prompted;
                  var pictures = match.pictures;
                  var lastRemark = match.lastRemark;
                  var remarks = match.remarks;
                  var step = match.step;
                  var value = match.value;
                  var level = match.level;
                  var $$document = match.document;
                  var question = match.question;
                  var lastAnswer = match.lastAnswer;
                  var lastNewAnswer = match.lastNewAnswer;
                  var vessel = match.vessel;
                  var match$1 = question.kind;
                  var correctAnswer = typeof match$1 === "object" && match$1.NAME === "YesNoQuestion" ? match$1.VAL.correct_answer : true;
                  if (step === "reviewPicture") {
                    var attachment = Prelude.$$Array.first(pictures);
                    tmp = [
                      React.jsx(NavigationPills.make, {
                            currentStep: 1,
                            stepCount: 2
                          }),
                      ReactDOM.jsx("p", {
                            children: question.name
                          }),
                      attachment !== undefined ? React.jsx(LocalImage.Resource.make, {
                              resource: attachment.id
                            }) : Mithril.empty,
                      ReactDOM.jsx("div", {
                            className: "divider xl blank"
                          }),
                      React.jsx(DetailImages.make, {
                            deleteAttachment: (async function (v1, param) {
                                console.log(v1);
                              }),
                            attachments: Curry._2(Prelude.$$Array.sliceToEnd, pictures, 1)
                          })
                    ];
                  } else if (step === "evalAnswer") {
                    tmp = [ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsxs("div", {
                                    children: [
                                      ReactDOM.jsx("div", {
                                            children: question.name,
                                            className: "tile-content text-small"
                                          }),
                                      ReactDOM.jsx("br", {}),
                                      ReactDOM.jsxs("div", {
                                            children: [
                                              ReactDOM.jsx("div", {
                                                    children: ReactDOM.jsxs("button", {
                                                          children: [
                                                            React.jsx(Feather.make, {
                                                                  icon: "check"
                                                                }),
                                                            "Yes"
                                                          ],
                                                          className: "btn full-width " + (
                                                            value ? "btn-success" : ""
                                                          ),
                                                          onClick: (function (param) {
                                                              Mithril.setState(vnode, {
                                                                    TAG: "Loaded",
                                                                    vessel: vessel,
                                                                    lastNewAnswer: lastNewAnswer,
                                                                    lastAnswer: lastAnswer,
                                                                    question: question,
                                                                    document: $$document,
                                                                    level: level,
                                                                    value: true,
                                                                    step: step,
                                                                    remarks: remarks,
                                                                    lastRemark: lastRemark,
                                                                    pictures: pictures,
                                                                    picture_prompted: picture_prompted
                                                                  });
                                                            })
                                                        }),
                                                    className: "column col-6"
                                                  }),
                                              ReactDOM.jsx("div", {
                                                    children: ReactDOM.jsxs("button", {
                                                          children: [
                                                            React.jsx(Feather.make, {
                                                                  icon: "x"
                                                                }),
                                                            "No"
                                                          ],
                                                          className: "btn full-width " + (
                                                            value ? "" : "btn-error"
                                                          ),
                                                          onClick: (function (param) {
                                                              Mithril.setState(vnode, {
                                                                    TAG: "Loaded",
                                                                    vessel: vessel,
                                                                    lastNewAnswer: lastNewAnswer,
                                                                    lastAnswer: lastAnswer,
                                                                    question: question,
                                                                    document: $$document,
                                                                    level: level,
                                                                    value: false,
                                                                    step: step,
                                                                    remarks: remarks,
                                                                    lastRemark: lastRemark,
                                                                    pictures: pictures,
                                                                    picture_prompted: picture_prompted
                                                                  });
                                                            })
                                                        }),
                                                    className: "column col-6"
                                                  })
                                            ],
                                            className: "columns"
                                          })
                                    ],
                                    className: "text-center"
                                  }),
                              ReactDOM.jsx("div", {
                                    className: "divider blank xl"
                                  }),
                              ReactDOM.jsxs("div", {
                                    children: [
                                      ReactDOM.jsx("label", {
                                            children: "Remarks",
                                            className: "form-label text-small text-bold",
                                            htmlFor: question.id
                                          }),
                                      ReactDOM.jsx("textarea", {
                                            className: "form-input",
                                            id: question.id,
                                            style: {
                                              height: "60%",
                                              width: "100%"
                                            },
                                            name: question.id,
                                            required: value !== correctAnswer,
                                            rows: 10,
                                            value: remarks,
                                            onKeyUp: (function ($$event) {
                                                Mithril.setState(vnode, {
                                                      TAG: "Loaded",
                                                      vessel: vessel,
                                                      lastNewAnswer: lastNewAnswer,
                                                      lastAnswer: lastAnswer,
                                                      question: question,
                                                      document: $$document,
                                                      level: level,
                                                      value: value,
                                                      step: step,
                                                      remarks: $$event.target.value,
                                                      lastRemark: lastRemark,
                                                      pictures: pictures,
                                                      picture_prompted: picture_prompted
                                                    });
                                              })
                                          }),
                                      value !== correctAnswer ? ReactDOM.jsx("p", {
                                              children: "The remarks field is mandatory",
                                              className: "text-tiny text-gray text-center"
                                            }) : Mithril.empty,
                                      ReactDOM.jsx("div", {
                                            className: "divider blank"
                                          }),
                                      lastRemark !== "" ? ReactDOM.jsxs("div", {
                                              children: [
                                                ReactDOM.jsx("strong", {
                                                      children: "Previous remarks:",
                                                      className: "text-tiny mr-2"
                                                    }),
                                                ReactDOM.jsx("p", {
                                                      children: lastRemark,
                                                      className: "text-tiny text-gray text-italic"
                                                    })
                                              ]
                                            }) : Mithril.empty
                                    ],
                                    className: "form-group"
                                  })
                            ]
                          })];
                  } else {
                    var last = Curry._2(Prelude.OptionExported.$$Option.orElse, lastNewAnswer, lastAnswer);
                    var tmp$1;
                    if (last !== undefined) {
                      var attachment$1 = Prelude.$$Array.first(last.evidences);
                      tmp$1 = ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsx("h4", {
                                    children: "Example",
                                    className: "text-center"
                                  }),
                              ReactDOM.jsx("div", {
                                    className: "divider blank"
                                  }),
                              ReactDOM.jsxs("figure", {
                                    children: [
                                      attachment$1 !== undefined ? React.jsx(LocalImage.Resource.make, {
                                              resource: attachment$1.id
                                            }) : Mithril.empty,
                                      ReactDOM.jsx("div", {
                                            className: "divider blank xl"
                                          }),
                                      ReactDOM.jsx("figcaption", {
                                            children: "The photo should look like this",
                                            className: "figure-caption text-center"
                                          })
                                    ],
                                    className: "figure"
                                  }),
                              ReactDOM.jsx("div", {
                                    className: "divider blank xl"
                                  })
                            ]
                          });
                    } else {
                      tmp$1 = Mithril.empty;
                    }
                    tmp = [
                      React.jsx(NavigationPills.make, {
                            currentStep: 0,
                            stepCount: 2
                          }),
                      $$document !== undefined ? React.jsx(MarkdownDocumentPage.MarkdownDocumentView.make, {
                              document: $$document
                            }) : Mithril.empty,
                      tmp$1
                    ];
                  }
                }
                return React.jsx(QuestionWidget.Editor.make, {
                            getValue: (function (extra) {
                                return getValue(vnode, extra);
                              }),
                            isDirty: (function (extra) {
                                var createNew = Prelude.default(Mithril__Route.paramBool("createNew"), true);
                                var match = vnode.state.data;
                                if (typeof match !== "object") {
                                  return false;
                                }
                                var pictures = match.pictures;
                                var remarks = match.remarks;
                                if (createNew) {
                                  if (Caml_obj.notequal(pictures, [])) {
                                    return true;
                                  } else {
                                    return remarks !== "";
                                  }
                                }
                                var lastNewAnswer = match.lastNewAnswer;
                                if (lastNewAnswer === undefined) {
                                  return false;
                                }
                                var match$1 = lastNewAnswer.value;
                                if (match$1 === undefined) {
                                  return false;
                                }
                                if (typeof match$1 !== "object") {
                                  return false;
                                }
                                if (match$1.NAME !== "Value") {
                                  return false;
                                }
                                var match$2 = match$1.VAL;
                                if (typeof match$2 === "object" && match$2.NAME === "YesNo") {
                                  if (Caml_obj.notequal(pictures, lastNewAnswer.evidences) || match.value !== match$2.VAL) {
                                    return true;
                                  } else {
                                    return remarks !== lastNewAnswer.remarks;
                                  }
                                } else {
                                  return false;
                                }
                              }),
                            updateChildrenState: (function (extra) {
                                var createNew = Prelude.default(Mithril__Route.paramBool("createNew"), true);
                                var value = Prelude.default(Mithril__Route.paramBool("value"), true);
                                if (typeof extra !== "object") {
                                  return Mithril.setState(vnode, "NotReady");
                                }
                                var vessel = extra.vessel;
                                var level = extra.level;
                                var question = extra.question;
                                var lastNewAnswer = extra.lastNewAnswer;
                                var lastAnswer = extra.lastAnswer;
                                var lastValue = lastNewAnswer !== undefined ? Caml_option.some(lastNewAnswer.value) : (
                                    lastAnswer !== undefined ? Caml_option.some(lastAnswer.value) : undefined
                                  );
                                return Prelude.thenDo(ConditionMatcher.Question.match(question, {
                                                NAME: "Value",
                                                VAL: {
                                                  NAME: "YesNo",
                                                  VAL: value
                                                }
                                              }, lastValue), (function (param) {
                                              var picture_prompted = param[1];
                                              var require_picture = param[0];
                                              var record = vnode.state.data;
                                              if (typeof record === "object") {
                                                return Mithril.setState(vnode, {
                                                            TAG: "Loaded",
                                                            vessel: vessel,
                                                            lastNewAnswer: lastNewAnswer,
                                                            lastAnswer: lastAnswer,
                                                            question: question,
                                                            document: record.document,
                                                            level: level,
                                                            value: record.value,
                                                            step: record.step,
                                                            remarks: record.remarks,
                                                            lastRemark: record.lastRemark,
                                                            pictures: record.pictures,
                                                            picture_prompted: record.picture_prompted
                                                          });
                                              }
                                              var newrecord = Caml_obj.obj_dup(Query.makeRead());
                                              Prelude.thenDo(Query.read((newrecord.documents = {
                                                          TAG: "Get",
                                                          _0: Prelude.default(question.guidance_document_id, MarkdownDocumentId.$$null)
                                                        }, newrecord)), (function (param) {
                                                      var $$document = Prelude.$$Array.first(param.documents);
                                                      var match;
                                                      if (createNew) {
                                                        match = [
                                                          [],
                                                          "",
                                                          value
                                                        ];
                                                      } else if (lastNewAnswer !== undefined) {
                                                        var match$1 = lastNewAnswer.value;
                                                        if (match$1 !== undefined) {
                                                          if (typeof match$1 === "object") {
                                                            if (match$1.NAME === "Value") {
                                                              var match$2 = match$1.VAL;
                                                              match = typeof match$2 === "object" ? (
                                                                  match$2.NAME === "YesNo" ? [
                                                                      lastNewAnswer.evidences,
                                                                      lastNewAnswer.remarks,
                                                                      value
                                                                    ] : [
                                                                      [],
                                                                      "",
                                                                      value
                                                                    ]
                                                                ) : [
                                                                  [],
                                                                  "",
                                                                  value
                                                                ];
                                                            } else {
                                                              match = [
                                                                [],
                                                                "",
                                                                value
                                                              ];
                                                            }
                                                          } else {
                                                            match = [
                                                              [],
                                                              "",
                                                              value
                                                            ];
                                                          }
                                                        } else {
                                                          match = [
                                                            [],
                                                            "",
                                                            value
                                                          ];
                                                        }
                                                      } else {
                                                        match = [
                                                          [],
                                                          "",
                                                          value
                                                        ];
                                                      }
                                                      var lastRemark = createNew ? Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Curry._2(Prelude.OptionExported.$$Option.orElse, lastNewAnswer, lastAnswer), (function (a) {
                                                                    return a.remarks;
                                                                  })), "") : "";
                                                      var step = require_picture ? "takePicture" : "evalAnswer";
                                                      Mithril.setState(vnode, {
                                                            TAG: "Loaded",
                                                            vessel: vessel,
                                                            lastNewAnswer: lastNewAnswer,
                                                            lastAnswer: lastAnswer,
                                                            question: question,
                                                            document: $$document,
                                                            level: level,
                                                            value: match[2],
                                                            step: step,
                                                            remarks: match[1],
                                                            lastRemark: lastRemark,
                                                            pictures: match[0],
                                                            picture_prompted: picture_prompted
                                                          });
                                                    }));
                                            }));
                              }),
                            bottom: (function (extra) {
                                var match = vnode.state.data;
                                if (typeof match !== "object") {
                                  return Mithril.empty;
                                }
                                var step = match.step;
                                var question = match.question;
                                var vessel = match.vessel;
                                if (step === "reviewPicture") {
                                  return ReactDOM.jsxs("div", {
                                              children: [
                                                React.jsxs(TakePicture.make, {
                                                      role: "EVIDENCE",
                                                      requireLandscape: question.require_landscape_pictures,
                                                      style: {
                                                        height: "3rem",
                                                        marginRight: "0.66666667%",
                                                        width: "66%"
                                                      },
                                                      children: [
                                                        React.jsx(Feather.make, {
                                                              icon: "camera"
                                                            }),
                                                        "Add detail pictures"
                                                      ],
                                                      onCapture: (function (extra, extra$1, extra$2) {
                                                          return processAttachment(vnode, extra, extra$1, extra$2);
                                                        }),
                                                      useEmbeddedCamera: Vessel.getCameraConf(vessel),
                                                      resolution: vessel.max_pic_resolution
                                                    }),
                                                ReactDOM.jsxs("button", {
                                                      children: [
                                                        React.jsx(Feather.make, {
                                                              icon: "save"
                                                            }),
                                                        "Continue"
                                                      ],
                                                      className: "btn btn-primary col-4",
                                                      style: {
                                                        height: "3rem"
                                                      },
                                                      onClick: (function (extra) {
                                                          var match = vnode.state.data;
                                                          if (typeof match !== "object") {
                                                            return ;
                                                          } else {
                                                            return Mithril.setState(vnode, {
                                                                        TAG: "Loaded",
                                                                        vessel: match.vessel,
                                                                        lastNewAnswer: match.lastNewAnswer,
                                                                        lastAnswer: match.lastAnswer,
                                                                        question: match.question,
                                                                        document: match.document,
                                                                        level: match.level,
                                                                        value: match.value,
                                                                        step: "evalAnswer",
                                                                        remarks: match.remarks,
                                                                        lastRemark: match.lastRemark,
                                                                        pictures: match.pictures,
                                                                        picture_prompted: match.picture_prompted
                                                                      });
                                                          }
                                                        })
                                                    })
                                              ],
                                              className: "columns m-0"
                                            });
                                } else if (step === "evalAnswer") {
                                  return ReactDOM.jsx("button", {
                                              children: "Save",
                                              className: Mithril.$$class([
                                                    [
                                                      "btn btn-primary btn-block btn-lg ",
                                                      true
                                                    ],
                                                    [
                                                      "disabled",
                                                      !allowSave(vnode)
                                                    ]
                                                  ], undefined),
                                              onClick: (function (extra) {
                                                  return QuestionWidget.Editor.save(vnode, (function (extra) {
                                                                return getValue(vnode, extra);
                                                              }), false, extra);
                                                })
                                            });
                                } else {
                                  return React.jsxs(TakePicture.make, {
                                              role: "MAIN_EVIDENCE",
                                              requireLandscape: question.require_landscape_pictures,
                                              className: "btn btn-primary btn-block btn-lg t-continue",
                                              children: [
                                                "Take picture",
                                                React.jsx(Feather.make, {
                                                      icon: "camera",
                                                      className: "ml-2"
                                                    })
                                              ],
                                              onCapture: (function (extra, extra$1, extra$2) {
                                                  return processAttachment(vnode, extra, extra$1, extra$2);
                                                }),
                                              onCameraFailure: (function (extra) {
                                                  console.log(vnode);
                                                }),
                                              useEmbeddedCamera: Vessel.getCameraConf(vessel),
                                              resolution: vessel.max_pic_resolution
                                            });
                                }
                              }),
                            children: tmp
                          });
              }));
}

function yesButtonClasses(vnode, lastNewAnswer, lastAnswer, answeredAsNA) {
  var draftSubmission = vnode.attrs.draftSubmission;
  var newAnswerValue = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, lastNewAnswer, (function (a) {
              return a.value;
            })), undefined);
  var answerValue = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, lastAnswer, (function (a) {
              return a.value;
            })), undefined);
  if (answeredAsNA) {
    return "";
  }
  if (newAnswerValue !== undefined) {
    if (typeof newAnswerValue !== "object") {
      return "";
    }
    if (newAnswerValue.NAME !== "Value") {
      return "";
    }
    var match = newAnswerValue.VAL;
    if (typeof match === "object" && match.NAME === "YesNo" && match.VAL) {
      return "active";
    } else {
      return "";
    }
  }
  if (answerValue === undefined) {
    return "";
  }
  if (typeof answerValue !== "object") {
    return "";
  }
  if (answerValue.NAME !== "Value") {
    return "";
  }
  var match$1 = answerValue.VAL;
  if (typeof match$1 === "object" && match$1.NAME === "YesNo" && match$1.VAL) {
    if (Curry._1(Prelude.OptionExported.$$Option.isSome, draftSubmission)) {
      return "bg-success";
    } else {
      return "bg-gray";
    }
  } else {
    return "";
  }
}

function noButtonClasses(vnode, lastNewAnswer, lastAnswer, answeredAsNA) {
  var draftSubmission = vnode.attrs.draftSubmission;
  var newAnswerValue = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, lastNewAnswer, (function (a) {
              return a.value;
            })), undefined);
  var answerValue = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, lastAnswer, (function (a) {
              return a.value;
            })), undefined);
  if (answeredAsNA) {
    return "";
  }
  if (newAnswerValue !== undefined) {
    if (typeof newAnswerValue !== "object") {
      return "";
    }
    if (newAnswerValue.NAME !== "Value") {
      return "";
    }
    var match = newAnswerValue.VAL;
    if (typeof match === "object" && match.NAME === "YesNo" && !match.VAL) {
      return "active";
    } else {
      return "";
    }
  }
  if (answerValue === undefined) {
    return "";
  }
  if (typeof answerValue !== "object") {
    return "";
  }
  if (answerValue.NAME !== "Value") {
    return "";
  }
  var match$1 = answerValue.VAL;
  if (typeof match$1 === "object" && match$1.NAME === "YesNo" && !match$1.VAL) {
    if (Curry._1(Prelude.OptionExported.$$Option.isSome, draftSubmission)) {
      return "bg-success";
    } else {
      return "bg-gray";
    }
  } else {
    return "";
  }
}

function onDialogCallback(vnode, value, action) {
  var vessel = vnode.attrs.vessel;
  var levelId = vnode.attrs.level.id;
  var questionId = vnode.attrs.question.id;
  var draftSubmission = vnode.attrs.draftSubmission;
  Mithril$1.route.set(URLs.yesNoQuestionWidgetEditor(action !== "Edit", undefined, Caml_option.some(value), Caml_option.some(Curry._2(Prelude.OptionExported.$$Option.map, draftSubmission, (function (s) {
                      return s.id;
                    }))), undefined, vessel.id, levelId, questionId));
}

function startInspection(vnode, dialogId, value, isAlreadyAnswered, question, _event) {
  KeepScrollLink.saveScroll();
  var state = vnode.state.data;
  vnode.state.data = {
    isBusy: true,
    currentAnswer: state.currentAnswer
  };
  var answer = vnode.attrs.lastNewAnswer;
  var lastValue;
  if (answer !== undefined) {
    lastValue = Caml_option.some(answer.value);
  } else {
    var answer$1 = vnode.attrs.lastAnswer;
    lastValue = answer$1 !== undefined ? Caml_option.some(answer$1.value) : undefined;
  }
  var match = question.kind;
  var correctAnswer = typeof match === "object" && match.NAME === "YesNoQuestion" ? match.VAL.correct_answer : true;
  Prelude.thenDo(ConditionMatcher.Question.match(vnode.attrs.question, {
            NAME: "Value",
            VAL: {
              NAME: "YesNo",
              VAL: value
            }
          }, lastValue), (function (param) {
          var draftSubmission = vnode.attrs.draftSubmission;
          if (isAlreadyAnswered) {
            vnode.state.data = {
              isBusy: false,
              currentAnswer: state.currentAnswer
            };
            return DialogNewEdit.openDialog(vnode, dialogId);
          } else if (!param[0] && value === correctAnswer) {
            var closeDialogOpt;
            var picture_prompted = param[1];
            var closeDialog = closeDialogOpt !== undefined ? Caml_option.valFromOption(closeDialogOpt) : undefined;
            var vessel = vnode.attrs.vessel;
            var level = vnode.attrs.level;
            var question = vnode.attrs.question;
            var value$1 = {
              NAME: "Value",
              VAL: {
                NAME: "YesNo",
                VAL: value
              }
            };
            return Prelude.thenDo(State__Answers.make(vessel.id, level.id, level.path, question.id, question.kind, value$1, "", [], picture_prompted), (function (param) {
                          Mithril.setState(vnode, {
                                isBusy: false,
                                currentAnswer: Prelude.$$Array.first(param.newAnswers)
                              });
                          if (Caml_obj.equal(closeDialog, true)) {
                            window.history.back();
                            return ;
                          }
                          
                        }));
          } else {
            Mithril$1.route.set(URLs.yesNoQuestionWidgetEditor(undefined, undefined, Caml_option.some(value), Caml_option.some(Curry._2(Prelude.OptionExported.$$Option.map, draftSubmission, (function (s) {
                                return s.id;
                              }))), undefined, vnode.attrs.vessel.id, vnode.attrs.level.id, vnode.attrs.question.id));
            return ;
          }
        }));
}

function make(param) {
  return Mithril.view(Mithril.oninit(Mithril.component({
                      isBusy: false,
                      currentAnswer: undefined
                    }), (function (vnode) {
                    var state = vnode.state.data;
                    Mithril.setState(vnode, {
                          isBusy: state.isBusy,
                          currentAnswer: vnode.attrs.lastNewAnswer
                        });
                  })), (function (vnode) {
                var question = vnode.attrs.question;
                var questionId = question.id;
                var lastAnswer = vnode.attrs.lastAnswer;
                var questionTitle = new MarkdownIt().render(Hyphenated.hyphenated(vnode.attrs.question.name));
                var match = vnode.state.data;
                var currentAnswer = match.currentAnswer;
                var isBusy = match.isBusy;
                var isAlreadyAnswered = Curry._1(Prelude.OptionExported.$$Option.isSome, currentAnswer);
                var newAnswerValue = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, currentAnswer, (function (a) {
                            return a.value;
                          })), undefined);
                var answerValue = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, lastAnswer, (function (a) {
                            return a.value;
                          })), undefined);
                var questionNAModalId = question.id + "-na";
                var answeredAsNA = newAnswerValue !== undefined ? (
                    typeof newAnswerValue === "object" ? false : true
                  ) : (
                    answerValue !== undefined && typeof answerValue !== "object" ? true : false
                  );
                var match$1 = question.area;
                return React.jsxs(GestureWrapper.make, {
                            children: [
                              ReactDOM.jsxs("div", {
                                    children: [
                                      ReactDOM.jsxKeyed("div", {
                                            children: [
                                              match$1 !== undefined ? (
                                                  match$1 === "Hardware" ? React.jsx(Feather.make, {
                                                          icon: "hardware",
                                                          className: "question-icon col-1",
                                                          size: 24
                                                        }) : (
                                                      match$1 === "Process" ? React.jsx(Feather.make, {
                                                              icon: "process",
                                                              className: "question-icon col-1",
                                                              size: 24
                                                            }) : React.jsx(Feather.make, {
                                                              icon: "hr",
                                                              className: "question-icon col-1",
                                                              size: 24
                                                            })
                                                    )
                                                ) : Mithril.empty,
                                              ReactDOM.jsx("div", {
                                                    children: Mithril$1.trust(questionTitle),
                                                    className: "col-11 col-sm-12 px-1"
                                                  })
                                            ],
                                            className: "text-small question-name col col-10 col-sm-12 d-flex"
                                          }, QuestionId.toHTMLID(question.id) + "title", undefined),
                                      ReactDOM.jsxsKeyed("div", {
                                            children: [
                                              ReactDOM.jsxs("div", {
                                                    children: [
                                                      React.jsx(Conditional.make, {
                                                            cond: isAlreadyAnswered,
                                                            children: React.jsx(DialogNewEditAnswer.make, {
                                                                  id: questionId + "yes",
                                                                  onCallback: (function (extra) {
                                                                      return onDialogCallback(vnode, true, extra);
                                                                    })
                                                                })
                                                          }),
                                                      ReactDOM.jsx("button", {
                                                            children: "Yes",
                                                            className: Mithril.$$class([
                                                                  [
                                                                    "t-yes-answer btn btn-action s-circle text-tiny " + yesButtonClasses(vnode, currentAnswer, lastAnswer, answeredAsNA),
                                                                    true
                                                                  ],
                                                                  [
                                                                    "disabled loading",
                                                                    isBusy
                                                                  ]
                                                                ], undefined),
                                                            onClick: (function (extra) {
                                                                return startInspection(vnode, questionId + "yes", true, isAlreadyAnswered, question, extra);
                                                              })
                                                          })
                                                    ],
                                                    className: "mr-1"
                                                  }),
                                              ReactDOM.jsxs("div", {
                                                    children: [
                                                      React.jsx(Conditional.make, {
                                                            cond: isAlreadyAnswered,
                                                            children: React.jsx(DialogNewEditAnswer.make, {
                                                                  id: questionId + "no",
                                                                  onCallback: (function (extra) {
                                                                      return onDialogCallback(vnode, false, extra);
                                                                    })
                                                                })
                                                          }),
                                                      ReactDOM.jsx("button", {
                                                            children: "No",
                                                            className: Mithril.$$class([
                                                                  [
                                                                    "t-no-answer btn btn-action s-circle text-tiny " + noButtonClasses(vnode, currentAnswer, lastAnswer, answeredAsNA),
                                                                    true
                                                                  ],
                                                                  [
                                                                    "disabled loading",
                                                                    isBusy
                                                                  ]
                                                                ], undefined),
                                                            onClick: (function (extra) {
                                                                return startInspection(vnode, questionId + "no", false, isAlreadyAnswered, question, extra);
                                                              })
                                                          })
                                                    ]
                                                  }),
                                              React.jsx(Conditional.make, {
                                                    cond: answeredAsNA,
                                                    children: ReactDOM.jsx("div", {
                                                          children: "Not Applicable",
                                                          className: "label label-rounded label-primary text-uppercase not-applicable-label"
                                                        })
                                                  })
                                            ],
                                            className: "tile-action col col-2 col-sm-12 question-answers"
                                          }, QuestionId.toHTMLID(question.id) + "btns", undefined)
                                    ],
                                    className: "columns tile tile-shadow tile-centered question t-question p-2 mx-0 my-2"
                                  }),
                              React.jsx(NotApplicableDialog.make, {
                                    id: questionNAModalId,
                                    header: "Question options",
                                    bodyText: "Do you want to answer this questions as N/A? This action will replace the current answer.",
                                    actions: NotApplicableStatus.confirmationChoices,
                                    onCallback: (function (extra) {
                                        if (extra !== "yes") {
                                          return ;
                                        }
                                        var vessel = vnode.attrs.vessel;
                                        var level = vnode.attrs.level;
                                        var question = vnode.attrs.question;
                                        var value = "NotApplicable";
                                        var match = vnode.state.data;
                                        var currentAnswer = match.currentAnswer;
                                        if (currentAnswer === undefined) {
                                          return Prelude.thenDo(State__Answers.make(vessel.id, level.id, level.path, question.id, question.kind, value, "", [], false), (function (param) {
                                                        Mithril.setState(vnode, {
                                                              isBusy: false,
                                                              currentAnswer: Prelude.$$Array.first(param.newAnswers)
                                                            });
                                                      }));
                                        }
                                        var files = currentAnswer.evidences.map(function (param) {
                                              return param.id;
                                            });
                                        var newrecord = Caml_obj.obj_dup(currentAnswer);
                                        newrecord.evidences = [];
                                        newrecord.remarks = "Not Applicable";
                                        newrecord.value = value;
                                        return Prelude.thenDo(Prelude.then(State__Answers.update(newrecord), (function (param) {
                                                          return FutureAttachmentManager.$$delete(files);
                                                        })), (function () {
                                                      Mithril.setState(vnode, {
                                                            isBusy: false,
                                                            currentAnswer: newrecord
                                                          });
                                                    }));
                                      })
                                  })
                            ],
                            onSwipeLeft: (function (extra) {
                                return NotApplicableDialog.openDialog(vnode, questionNAModalId);
                              }),
                            rightContent: Caml_option.some(ReactDOM.jsx("span", {
                                      children: "N/A"
                                    })),
                            className: "answered-as-na",
                            enabled: !answeredAsNA
                          });
              }));
}

var Editor = {
  make: YesNoQuestionWidget$Editor
};

export {
  Editor ,
  make ,
}
/* URLs Not a pure module */
