// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../URLs.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Feather from "../../common/views/Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as UserProfile from "../UserProfile.js";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.js";
import * as ProfileAvatar from "./ProfileAvatar.js";
import * as KeepScrollLink from "../../../libs/KeepScrollLink.js";

function toDefault(status) {
  if (status === "active" || status === "archived") {
    return {
            cardData: "tw-opacity-100",
            icon: "tw-opacity-100"
          };
  } else {
    return {
            cardData: "tw-opacity-65",
            icon: "tw-opacity-90"
          };
  }
}

function toStyle(theme, status) {
  if (status === "archived") {
    return Core__Option.getOr(theme.archived, toDefault("archived"));
  } else if (status === "active") {
    return Core__Option.getOr(theme.active, toDefault("active"));
  } else {
    return Core__Option.getOr(theme.inactive, toDefault("inactive"));
  }
}

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var match = vnode.attrs;
                var profile = match.profile;
                var selected = Core__Option.getOr(vnode.attrs.selected, false);
                var theme = Core__Option.getOr(vnode.attrs.theme, {});
                var style = toStyle(theme, profile.state);
                var match$1 = profile.state;
                var onClick = match$1 === "active" ? Core__Option.map(vnode.attrs.onClick, (function (f) {
                          return function (_event) {
                            f(profile);
                          };
                        })) : undefined;
                return ReactDOM.jsxsKeyed("div", {
                            children: [
                              ReactDOM.jsxs("div", {
                                    children: [
                                      ReactDOM.jsx("div", {
                                            children: React.jsx(ProfileAvatar.make, {
                                                  style: "profileCard",
                                                  profile: profile
                                                }),
                                            className: "tw-flex tw-items-center tw-justify-center tw-w-[50px] tw-h-[50px]"
                                          }),
                                      ReactDOM.jsxs("div", {
                                            children: [
                                              ReactDOM.jsx("div", {
                                                    children: UserProfile.name(profile),
                                                    className: "tw-font-medium"
                                                  }),
                                              ReactDOM.jsx("div", {
                                                    children: UserProfile.Rank.toString(profile.rank)
                                                  })
                                            ],
                                            className: "tw-flex tw-flex-col tw-p-1"
                                          })
                                    ],
                                    className: Mithril.classList([
                                          style.cardData,
                                          "tw-flex tw-flex-grow tw-flex-row tw-items-center tw-gap-2.5 tw-justify-start"
                                        ], undefined)
                                  }),
                              React.jsx(KeepScrollLink.make, {
                                    href: URLs.editProfile(profile.vessel, profile.id),
                                    className: Mithril.classList([
                                          "tw-flex tw-items-center tw-justify-center tw-text-primary",
                                          "tw-w-[50px] tw-h-[50px] t-btn-edit"
                                        ], undefined),
                                    children: Caml_option.some(React.jsx(Feather.make, {
                                              icon: "edit_3",
                                              className: style.icon,
                                              size: 28
                                            }))
                                  })
                            ],
                            className: Mithril.classList([
                                  selected ? "tw-border-solid tw-border-primary" : "",
                                  "tw-flex tw-flex-grow tw-flex-row tw-items-center tw-justify-between",
                                  "tw-shadow-kaiko-card tw-border-[1.5px] tw-rounded tw-my-2 tw-px-1"
                                ], undefined),
                            onClick: onClick
                          }, profile.id, undefined);
              }));
}

var Style = {};

var Theme = {};

export {
  Style ,
  Theme ,
  make ,
}
/* URLs Not a pure module */
