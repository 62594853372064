// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Component from "../Component.js";
import * as Procedure from "../../survey/Procedure.js";
import * as Inspection from "../../survey/Inspection.js";
import * as ComponentId from "../ComponentId.js";
import * as ProcedureId from "../../survey/ProcedureId.js";
import * as NewInspection from "../../survey/NewInspection.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as CompletionStatus from "../../../libs/CompletionStatus.js";
import * as InspectionCompletion from "../../survey/views/InspectionCompletion.js";
import * as InlineProcedureWidget from "./InlineProcedureWidget.js";

function toggleContent(vnode) {
  var state = vnode.state.data;
  if (typeof state !== "object") {
    return ;
  } else if (state.TAG === "SameQuestions") {
    return Mithril.setState(vnode, {
                TAG: "SameQuestions",
                procedures: state.procedures,
                questionText: state.questionText,
                componentInfo: state.componentInfo,
                oldInspectionsOfProcedure: state.oldInspectionsOfProcedure,
                newInspectionsOfProcedure: state.newInspectionsOfProcedure,
                proceduresOfComponent: state.proceduresOfComponent,
                showContent: !state.showContent
              });
  } else {
    return Mithril.setState(vnode, {
                TAG: "DifferentQuestionsOrSingleComponent",
                procedures: state.procedures,
                componentInfo: state.componentInfo,
                oldInspectionsOfProcedure: state.oldInspectionsOfProcedure,
                newInspectionsOfProcedure: state.newInspectionsOfProcedure,
                proceduresOfComponent: state.proceduresOfComponent,
                showContent: !state.showContent
              });
  }
}

function refresh(vnode, showContent) {
  var procedureIds = Procedure.ids(vnode.attrs.procedures);
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  Prelude.thenDo(Query.read((newrecord.procedureUpdates = {
              TAG: "In",
              _0: procedureIds
            }, newrecord.newInspections = NewInspection.ofProcedures(procedureIds), newrecord.inspections = Inspection.ofProcedures(procedureIds), newrecord.procedures = {
              TAG: "In",
              _0: procedureIds
            }, newrecord)), (function (param) {
          var procedureUpdates = param.procedureUpdates;
          var newInspections = param.newInspections;
          var inspections = param.inspections;
          var procedures = param.procedures.map(function (procedure) {
                var procedureUpdate = Prelude.$$Array.first(Curry._2(Prelude.$$Array.keep, procedureUpdates, (function (p) {
                            return Caml_obj.equal(p.id, procedure.id);
                          })));
                if (procedureUpdate === undefined) {
                  return procedure;
                }
                var newrecord = Caml_obj.obj_dup(procedure);
                newrecord.not_applicable_status = procedureUpdate.not_applicable_status;
                return newrecord;
              });
          var componentInfo_completion = CompletionStatus.reduce(vnode.attrs.components.map(function (__x) {
                    return Component.getCompletion(__x, Curry._2(Prelude.$$Array.keep, procedures, (function (p) {
                                      return p.not_applicable_status === undefined;
                                    })));
                  }));
          var componentInfo_dueStatusText = Procedure.getGreatestOverdueText(undefined, procedures);
          var componentInfo = {
            completion: componentInfo_completion,
            dueStatusText: componentInfo_dueStatusText
          };
          var allProceduresHaveTheSameName = vnode.attrs.components.length > 1 && Belt_SetString.size(Belt_SetString.fromArray(procedures.map(Procedure.byName))) === 1;
          var newState = allProceduresHaveTheSameName ? ({
                TAG: "SameQuestions",
                procedures: procedures,
                questionText: Prelude.$$Array.firstUnsafe(procedures).name,
                componentInfo: componentInfo,
                oldInspectionsOfProcedure: ProcedureId.$$Array.getter(inspections, Inspection.byProcedure),
                newInspectionsOfProcedure: ProcedureId.$$Array.getter(newInspections, Inspection.byProcedure),
                proceduresOfComponent: ComponentId.$$Array.getter(Prelude.$$Array.sort(procedures, Procedure.byOrder, undefined), Procedure.byComponent),
                showContent: showContent
              }) : ({
                TAG: "DifferentQuestionsOrSingleComponent",
                procedures: procedures,
                componentInfo: componentInfo,
                oldInspectionsOfProcedure: ProcedureId.$$Array.getter(inspections, Inspection.byProcedure),
                newInspectionsOfProcedure: ProcedureId.$$Array.getter(newInspections, Inspection.byProcedure),
                proceduresOfComponent: ComponentId.$$Array.getter(Prelude.$$Array.sort(procedures, Procedure.byOrder, undefined), Procedure.byComponent),
                showContent: showContent
              });
          Mithril.setState(vnode, newState);
        }));
}

function componentDetails(vnode, counterOverlayClassName) {
  var component = Prelude.$$Array.firstUnsafe(vnode.attrs.components);
  var match = vnode.state.data;
  var tmp;
  tmp = typeof match !== "object" ? "Loading..." : (
      match.TAG === "SameQuestions" ? match.componentInfo.dueStatusText : match.componentInfo.dueStatusText
    );
  var match$1 = vnode.state.data;
  var tmp$1;
  var exit = 0;
  var completion;
  if (typeof match$1 !== "object") {
    tmp$1 = Mithril.empty;
  } else if (match$1.TAG === "SameQuestions") {
    completion = match$1.componentInfo.completion;
    exit = 1;
  } else {
    completion = match$1.componentInfo.completion;
    exit = 1;
  }
  if (exit === 1) {
    tmp$1 = React.jsx(InspectionCompletion.make, {
          completion: completion,
          counterOverlayClassName: counterOverlayClassName
        });
  }
  return ReactDOM.jsx("div", {
              children: ReactDOM.jsxs("div", {
                    children: [
                      ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsx("div", {
                                    children: component.name,
                                    className: "h5"
                                  }),
                              ReactDOM.jsx("small", {
                                    children: tmp,
                                    className: "tile-subtitle text-gray"
                                  })
                            ],
                            className: "tile-content",
                            onClick: (function (param) {
                                toggleContent(vnode);
                              })
                          }),
                      tmp$1
                    ],
                    className: "tile tile-centered t-component"
                  }),
              className: "card-title d-block"
            });
}

function make(param) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), (function (vnode) {
                    refresh(vnode, false);
                  })), (function (vnode) {
                var component = Prelude.$$Array.firstUnsafe(vnode.attrs.components);
                var counterOverlayClassName = vnode.attrs.counterOverlayClassName;
                var match = vnode.state.data;
                var tmp;
                tmp = typeof match !== "object" ? componentDetails(vnode, undefined) : (
                    match.TAG === "SameQuestions" ? (
                        match.showContent ? componentDetails(vnode, undefined) : componentDetails(vnode, vnode.attrs.counterOverlayClassName)
                      ) : (
                        match.showContent ? componentDetails(vnode, undefined) : componentDetails(vnode, vnode.attrs.counterOverlayClassName)
                      )
                  );
                var match$1 = vnode.state.data;
                var tmp$1;
                if (typeof match$1 !== "object") {
                  tmp$1 = Mithril.empty;
                } else if (match$1.TAG === "SameQuestions") {
                  var showContent = match$1.showContent;
                  var proceduresOfComponent = match$1.proceduresOfComponent;
                  var newInspectionsOfProcedure = match$1.newInspectionsOfProcedure;
                  var oldInspectionsOfProcedure = match$1.oldInspectionsOfProcedure;
                  tmp$1 = [ReactDOM.jsx("div", {
                          children: Curry._2(Prelude.$$Array.concat, [ReactDOM.jsxKeyed("div", {
                                      children: match$1.questionText,
                                      className: "h6 text-center pt-2"
                                    }, "question", undefined)], Prelude.$$Array.flatMap(vnode.attrs.components, (function (component) {
                                      return proceduresOfComponent(component.id).map(function (procedure) {
                                                  return React.jsxKeyed(InlineProcedureWidget.make, {
                                                              procedure: procedure,
                                                              oldInspectionsOfProcedure: oldInspectionsOfProcedure,
                                                              newInspectionsOfProcedure: newInspectionsOfProcedure,
                                                              number: component.number,
                                                              onInspectionCreated: (function (param, param$1) {
                                                                  refresh(vnode, showContent);
                                                                }),
                                                              onChangeProcedure: (function (param) {
                                                                  refresh(vnode, showContent);
                                                                }),
                                                              counterOverlayClassName: counterOverlayClassName
                                                            }, procedure.id, undefined);
                                                });
                                    }))),
                          className: Mithril.$$class([
                                [
                                  "card-body p-0",
                                  true
                                ],
                                [
                                  "hidden",
                                  !showContent
                                ]
                              ], undefined),
                          id: ComponentId.toHTMLID(component.id)
                        })];
                } else {
                  var showContent$1 = match$1.showContent;
                  var proceduresOfComponent$1 = match$1.proceduresOfComponent;
                  var newInspectionsOfProcedure$1 = match$1.newInspectionsOfProcedure;
                  var oldInspectionsOfProcedure$1 = match$1.oldInspectionsOfProcedure;
                  tmp$1 = [ReactDOM.jsx("div", {
                          children: Prelude.$$Array.flatMap(vnode.attrs.components, (function (component) {
                                  return Curry._2(Prelude.$$Array.concat, [component.number === 0 ? ReactDOM.jsxKeyed("div", {}, component.id, undefined) : ReactDOM.jsxKeyed("div", {
                                                      children: "Number " + String(component.number),
                                                      className: "h6 text-center pt-2"
                                                    }, component.id, undefined)], proceduresOfComponent$1(component.id).map(function (procedure) {
                                                  return React.jsxKeyed(InlineProcedureWidget.make, {
                                                              procedure: procedure,
                                                              oldInspectionsOfProcedure: oldInspectionsOfProcedure$1,
                                                              newInspectionsOfProcedure: newInspectionsOfProcedure$1,
                                                              onInspectionCreated: (function (param, param$1) {
                                                                  refresh(vnode, showContent$1);
                                                                }),
                                                              onChangeProcedure: (function (param) {
                                                                  refresh(vnode, showContent$1);
                                                                }),
                                                              counterOverlayClassName: counterOverlayClassName
                                                            }, procedure.id, undefined);
                                                }));
                                })),
                          className: Mithril.$$class([
                                [
                                  "card-body p-0",
                                  true
                                ],
                                [
                                  "hidden",
                                  !showContent$1
                                ]
                              ], undefined),
                          id: ComponentId.toHTMLID(component.id)
                        })];
                }
                return ReactDOM.jsxs("div", {
                            children: [
                              tmp,
                              tmp$1
                            ],
                            className: "card card-shadow my-2 t-xc-xq",
                            style: {
                              cursor: "pointer"
                            }
                          });
              }));
}

export {
  toggleContent ,
  refresh ,
  componentDetails ,
  make ,
}
/* Query Not a pure module */
