// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ReIndexed from "@kaiko.io/rescript-reindexed/lib/es6/src/ReIndexed.js";
import * as Inspection from "./Inspection.js";
import * as InspectionId from "./InspectionId.js";

var Definition = {};

var include = ReIndexed.MakeIdModel(Definition, {});

function withImages(param) {
  return !Prelude.$$Array.isEmpty(param.attachments);
}

function getImages(extra) {
  return Inspection._getImages((function (i) {
                return {
                        TAG: "Device",
                        _0: i
                      };
              }), extra);
}

var IdComparator = include.IdComparator;

var Id = include.Id;

export {
  Definition ,
  IdComparator ,
  Id ,
  withImages ,
  getImages ,
}
/* include Not a pure module */
