// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../URLs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Feather from "../../common/views/Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril$1 from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as TileLink from "../../common/views/TileLink.js";
import * as Procedure from "../../survey/Procedure.js";
import * as Inspection from "../../survey/Inspection.js";
import * as ListLayout from "../../common/views/ListLayout.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ComponentId from "../ComponentId.js";
import * as ProcedureId from "../../survey/ProcedureId.js";
import * as State__User from "../../state/State__User.js";
import * as StepCounter from "../../runtime/StepCounter.js";
import * as InspectionId from "../../survey/InspectionId.js";
import * as DialogNewEdit from "../../../libs/DialogNewEdit.js";
import * as NewInspection from "../../survey/NewInspection.js";
import * as KeepScrollLink from "../../../libs/KeepScrollLink.js";
import * as Mithril__Route from "../../../libs/rescript-mithril/src/Mithril__Route.js";
import * as CompletionStatus from "../../../libs/CompletionStatus.js";
import * as DialogNewEditInspection from "./DialogNewEditInspection.js";

function title(state) {
  if (typeof state !== "object") {
    return "";
  } else {
    return state.component.name;
  }
}

function onDialogCallback(vnode, procedure, action) {
  var match = vnode.state.data;
  if (typeof match !== "object") {
    return ;
  }
  var inspectionId = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Prelude.default(ProcedureId.$$Map.get(match.proceduresInformation, procedure.id), {
                completed: Procedure.isFullyInspected(procedure),
                lastNewInspection: undefined
              }).lastNewInspection, (function (i) {
              return i.id;
            })), InspectionId.$$null);
  if (action === "Edit") {
    Mithril$1.route.set(URLs.editInspection(inspectionId));
  } else {
    Mithril$1.route.set(URLs.startInspectionProcedure(procedure.id, undefined, undefined, undefined, undefined, undefined, undefined));
  }
}

function startInspection(vnode, procedure, $$event) {
  $$event.preventDefault();
  $$event.stopPropagation();
  Prelude.then(State__User.getCurrent(), StepCounter.start);
  var match = vnode.state.data;
  if (typeof match !== "object") {
    return ;
  }
  var isAlreadyInspected = Curry._1(Prelude.OptionExported.$$Option.isSome, Prelude.default(ProcedureId.$$Map.get(match.proceduresInformation, procedure.id), {
            completed: Procedure.isFullyInspected(procedure),
            lastNewInspection: undefined
          }).lastNewInspection);
  var dialogId = "dialog-" + procedure.id;
  KeepScrollLink.saveScroll();
  if (isAlreadyInspected) {
    return DialogNewEdit.openDialog(vnode, dialogId);
  } else {
    Mithril$1.route.set(URLs.startInspectionProcedure(procedure.id, undefined, undefined, undefined, undefined, undefined, undefined));
    return ;
  }
}

function make() {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), (function (vnode) {
                    var componentId = ComponentId.Route.param("componentId");
                    var asPlanned = Prelude.default(Mithril__Route.paramBool("asPlanned"), false);
                    var tmp;
                    if (asPlanned) {
                      tmp = Query.$$do([
                            {
                              TAG: "Read",
                              _0: (function (param) {
                                  var newrecord = Caml_obj.obj_dup(Query.makeRead());
                                  newrecord.plannedProcedures = {
                                    TAG: "Is",
                                    _0: "component_id",
                                    _1: componentId
                                  };
                                  return newrecord;
                                })
                            },
                            {
                              TAG: "Read",
                              _0: (function (param) {
                                  var plannedProcedures = param.plannedProcedures;
                                  var procedureIds = plannedProcedures.map(function (p) {
                                        return p.id;
                                      });
                                  var componentId = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Prelude.$$Array.first(plannedProcedures.map(function (p) {
                                                    return p.component_id;
                                                  })), (function (c) {
                                              return c;
                                            })), "");
                                  var newrecord = Caml_obj.obj_dup(Query.makeRead());
                                  newrecord.newInspections = NewInspection.ofProcedures(procedureIds);
                                  newrecord.inspections = Inspection.ofProcedures(procedureIds);
                                  newrecord.procedures = {
                                    TAG: "In",
                                    _0: procedureIds
                                  };
                                  newrecord.components = {
                                    TAG: "Get",
                                    _0: componentId
                                  };
                                  return newrecord;
                                })
                            }
                          ]);
                    } else {
                      var newrecord = Caml_obj.obj_dup(Query.makeRead());
                      tmp = Query.read((newrecord.newInspections = NewInspection.ofComponents([componentId]), newrecord.inspections = Inspection.ofComponents([componentId]), newrecord.procedures = Procedure.ofComponents([componentId]), newrecord.components = {
                              TAG: "Get",
                              _0: componentId
                            }, newrecord));
                    }
                    Prelude.thenDo(tmp, (function (param) {
                            var procedures = param.procedures;
                            var component = Prelude.$$Array.first(param.components);
                            if (component !== undefined) {
                              var newInspectionsOfProcedure = ProcedureId.$$Array.getter(param.newInspections, Inspection.byProcedure);
                              var proceduresInformation = ProcedureId.$$Map.fromArray(procedures.map(function (procedure) {
                                        return [
                                                procedure.id,
                                                {
                                                  completed: Procedure.isFullyInspected(procedure),
                                                  lastNewInspection: Prelude.$$Array.last(Prelude.$$Array.sort(newInspectionsOfProcedure(procedure.id), Inspection.byTimestamp, undefined))
                                                }
                                              ];
                                      }));
                              return Mithril.setState(vnode, {
                                          TAG: "Loaded",
                                          component: component,
                                          procedures: Prelude.$$Array.sort(procedures, Procedure.byOrder, undefined),
                                          proceduresInformation: proceduresInformation
                                        });
                            }
                            window.history.back();
                          }));
                  })), (function (vnode) {
                var loaded = vnode.state.data;
                var tmp;
                tmp = typeof loaded !== "object" ? ReactDOM.jsx("div", {}) : loaded.procedures.map(function (procedure) {
                        var status = Procedure.getCurrentCompletionStatus(procedure);
                        return ReactDOM.jsxs("div", {
                                    children: [
                                      React.jsxKeyed(DialogNewEditInspection.make, {
                                            id: "dialog-" + procedure.id,
                                            onCallback: (function (extra) {
                                                return onDialogCallback(vnode, procedure, extra);
                                              })
                                          }, "dialog-" + procedure.id, undefined),
                                      React.jsxKeyed(TileLink.make, {
                                            title: procedure.name,
                                            subtitle: Procedure.getCurrentDueText(procedure),
                                            action: Caml_option.some(status === "overdue" || status === "due" ? ReactDOM.jsx("div", {
                                                        children: React.jsx(Feather.make, {
                                                              icon: "circle",
                                                              className: "text-" + CompletionStatus.getStatusColor(status) + " circle"
                                                            }),
                                                        className: "circle"
                                                      }) : ReactDOM.jsx("div", {
                                                        children: React.jsx(Feather.make, {
                                                              icon: "check_circle",
                                                              className: "text-success circle"
                                                            }),
                                                        className: "circle"
                                                      })),
                                            onClick: (function (extra) {
                                                return startInspection(vnode, procedure, extra);
                                              })
                                          }, procedure.id, undefined)
                                    ]
                                  });
                      });
                return React.jsx(ListLayout.make, {
                            children: tmp,
                            title: title(vnode.state.data)
                          });
              }));
}

export {
  title ,
  onDialogCallback ,
  startInspection ,
  make ,
}
/* URLs Not a pure module */
