// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ReIndexed from "@kaiko.io/rescript-reindexed/lib/es6/src/ReIndexed.js";
import * as NotificationEntryId from "./NotificationEntryId.js";

var Definition = {};

var include = ReIndexed.MakeIdModel(Definition, {});

function ofVessel(vesselId) {
  return {
          TAG: "Is",
          _0: "vessel_id",
          _1: vesselId
        };
}

function make(notification) {
  return {
          id: notification.id,
          vessel_id: notification.vessel_id
        };
}

var Identifier;

var IdComparator = include.IdComparator;

var Id = include.Id;

export {
  Identifier ,
  Definition ,
  IdComparator ,
  Id ,
  ofVessel ,
  make ,
}
/* include Not a pure module */
