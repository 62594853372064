// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReIndexed from "@kaiko.io/rescript-reindexed/lib/es6/src/ReIndexed.js";
import * as Inspection from "./Inspection.js";
import * as InspectionId from "./InspectionId.js";
import * as QuestionType from "./QuestionType.js";

function description(ratingStatus) {
  if (ratingStatus === "Incomplete") {
    return {
            text: "Incomplete",
            color: "error"
          };
  } else {
    return {
            text: "Completed",
            color: "success"
          };
  }
}

function fromRating(rating) {
  var isRated = rating !== 0;
  if (isRated) {
    return "Completed";
  } else {
    return "Incomplete";
  }
}

var RatingStatus = {
  description: description,
  fromRating: fromRating
};

var Definition = {};

var include = ReIndexed.MakeIdModel(Definition, {});

function ofVessel(vesselId) {
  return {
          TAG: "Is",
          _0: "vessel_id",
          _1: vesselId
        };
}

function ofComponents(componentIds) {
  return {
          TAG: "AnyOf",
          _0: "component_id",
          _1: componentIds
        };
}

function ofProcedures(procedureIds) {
  return {
          TAG: "AnyOf",
          _0: "procedure_id",
          _1: procedureIds.map(function (prim) {
                return prim;
              })
        };
}

function ofProcedure(procedureId) {
  return {
          TAG: "Is",
          _0: "procedure_id",
          _1: procedureId
        };
}

function withImages(param) {
  return !Prelude.$$Array.isEmpty(param.attachments);
}

function getImages(extra) {
  return Inspection._getImages((function (i) {
                return {
                        TAG: "Device",
                        _0: i
                      };
              }), extra);
}

function byId(inspection) {
  return inspection.id;
}

function byRatingStatus(inspection) {
  return description(fromRating(QuestionType.Value.rate(inspection.value))).text;
}

function isRated(inspection, procedure) {
  if (Caml_obj.equal(inspection.procedure_id, procedure.id) && QuestionType.Value.matches(inspection.value, procedure.question_type)) {
    return QuestionType.Value.rate(inspection.value) !== 0;
  } else {
    return false;
  }
}

function isDeficient(inspection) {
  return QuestionType.Value.rate(inspection.value) < 3;
}

var IdComparator = include.IdComparator;

var Id = include.Id;

var bySection = Inspection.bySection;

var byComponent = Inspection.byComponent;

var byProcedure = Inspection.byProcedure;

var size = Inspection.size;

var getPhoto = Inspection.getPhoto;

var getMainImage = Inspection.getMainImage;

var getSignature = Inspection.getSignature;

var getDetailedPhotos = Inspection.getDetailedPhotos;

export {
  RatingStatus ,
  Definition ,
  IdComparator ,
  Id ,
  ofVessel ,
  ofComponents ,
  ofProcedures ,
  ofProcedure ,
  withImages ,
  getImages ,
  byId ,
  bySection ,
  byComponent ,
  byProcedure ,
  byRatingStatus ,
  isRated ,
  size ,
  getPhoto ,
  getMainImage ,
  getSignature ,
  getDetailedPhotos ,
  isDeficient ,
}
/* include Not a pure module */
