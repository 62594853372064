// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ReIndexed from "@kaiko.io/rescript-reindexed/lib/es6/src/ReIndexed.js";
import * as Sire2VettingPeriodId from "./Sire2VettingPeriodId.js";

var Definition = {};

var include = ReIndexed.MakeIdModel(Definition, {});

var Identifier;

var IdComparator = include.IdComparator;

var Id = include.Id;

export {
  Identifier ,
  Definition ,
  IdComparator ,
  Id ,
}
/* include Not a pure module */
