// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Rating from "../../survey/Rating.js";
import * as Feather from "../../common/views/Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Procedure from "../../survey/Procedure.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ComponentId from "../../vessel/ComponentId.js";
import * as ProcedureId from "../../survey/ProcedureId.js";
import * as NewInspection from "../../survey/NewInspection.js";
import * as ReviewUpload__InspectionOverviewCard from "./ReviewUpload__InspectionOverviewCard.js";

function toggleState(vnode, _event) {
  var match = vnode.state.data;
  var tmp;
  if (typeof match !== "object") {
    var inspections = vnode.attrs.inspections;
    tmp = Prelude.thenResolve(Query.doEach([
              {
                TAG: "Read",
                _0: (function (param) {
                    var newrecord = Caml_obj.obj_dup(Query.makeRead());
                    newrecord.procedures = {
                      TAG: "In",
                      _0: inspections.map(NewInspection.byProcedure)
                    };
                    return newrecord;
                  })
              },
              {
                TAG: "Read",
                _0: (function (param) {
                    var newrecord = Caml_obj.obj_dup(Query.makeRead());
                    newrecord.ratingStylingRules = Procedure.getManyRatingStyleRules(param.procedures);
                    return newrecord;
                  })
              }
            ]), (function (param) {
            var procedures = param.procedures;
            var rules = Rating.StylingRule.Identifier.$$Map.fromArray(param.ratingStylingRules.map(function (r) {
                      return [
                              r.id,
                              r.rules
                            ];
                    }));
            return {
                    TAG: "Open",
                    proceduresOfComponent: ComponentId.$$Array.getter(Prelude.$$Array.sort(procedures, Procedure.byOrder, undefined), Procedure.byComponent),
                    inspectionsOfProcedure: ProcedureId.$$Array.getter(inspections, NewInspection.byProcedure),
                    stylingRules: ProcedureId.$$Map.fromArray(procedures.map(function (p) {
                              var id = p.rating_styling_rules_id;
                              if (id !== undefined) {
                                return [
                                        p.id,
                                        Prelude.default(Rating.StylingRule.Identifier.$$Map.get(rules, Caml_option.valFromOption(id)), Rating.StylingRule.defaults)
                                      ];
                              } else {
                                return [
                                        p.id,
                                        Rating.StylingRule.defaults
                                      ];
                              }
                            }))
                  };
          }));
  } else {
    tmp = Promise.resolve("Closed");
  }
  Prelude.thenDo(tmp, (function (extra) {
          return Mithril.setState(vnode, extra);
        }));
}

function make(param) {
  return Mithril.view(Mithril.component("Closed"), (function (vnode) {
                var components = vnode.attrs.components;
                var match = vnode.state.data;
                var tmp;
                if (typeof match !== "object") {
                  tmp = Mithril.empty;
                } else {
                  var stylingRules = match.stylingRules;
                  var inspectionsOfProcedure = match.inspectionsOfProcedure;
                  var proceduresOfComponent = match.proceduresOfComponent;
                  tmp = Prelude.$$Array.flatMap(components, (function (component) {
                          return Curry._2(Prelude.$$Array.concat, component.number === 0 ? [] : [ReactDOM.jsxKeyed("div", {
                                              children: "Number: " + String(component.number),
                                              className: "h6 text-center pt-2"
                                            }, vnode.attrs.id + "_" + component.id, undefined)], Prelude.$$Array.flatMap(proceduresOfComponent(component.id), (function (procedure) {
                                            return inspectionsOfProcedure(procedure.id).map(function (inspection) {
                                                        return React.jsxKeyed(ReviewUpload__InspectionOverviewCard.make, {
                                                                    inspection: inspection,
                                                                    procedure: procedure,
                                                                    ratingStylingRules: Prelude.default(ProcedureId.$$Map.get(stylingRules, procedure.id), Rating.StylingRule.defaults)
                                                                  }, inspection.id, undefined);
                                                      });
                                          })));
                        }));
                }
                return ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsx("input", {
                                    hidden: true,
                                    id: vnode.attrs.id,
                                    name: "accordion-checkbox",
                                    type: "checkbox",
                                    onChange: (function (extra) {
                                        return toggleState(vnode, extra);
                                      })
                                  }),
                              ReactDOM.jsxs("label", {
                                    children: [
                                      React.jsx(Feather.make, {
                                            icon: "chevron_right",
                                            className: "icon",
                                            style: "top: 0.25rem; position: relative; float: right; margin-right: 0.2rem;",
                                            size: 18
                                          }),
                                      Prelude.$$Array.firstUnsafe(components).name
                                    ],
                                    className: "accordion-header text-primary h6",
                                    style: {
                                      paddingLeft: "0.6rem"
                                    },
                                    htmlFor: vnode.attrs.id
                                  }),
                              ReactDOM.jsx("div", {
                                    children: tmp,
                                    className: "accordion-body"
                                  })
                            ],
                            className: "accordion"
                          });
              }));
}

var InspectionOverviewCard;

export {
  InspectionOverviewCard ,
  toggleState ,
  make ,
}
/* Query Not a pure module */
