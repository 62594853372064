// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.js";
import * as KeepScrollLink from "../../../libs/KeepScrollLink.js";

function toClassName(style) {
  if (style === "hidden") {
    return "tw-hidden";
  } else if (style === "primary") {
    return "tw-text-white tw-bg-primary tw-border-primary";
  } else if (style === "default") {
    return "tw-text-primary tw-bg-white tw-border-primary";
  } else {
    return "tw-text-white tw-bg-gray-400 tw-border-gray-400";
  }
}

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var disabled = Core__Option.getOr(vnode.attrs.disabled, false);
                var href = disabled ? "" : vnode.attrs.href;
                var style = Core__Option.getOr(vnode.attrs.style, "default");
                return React.jsx(KeepScrollLink.make, {
                            href: href,
                            className: Mithril.classList([
                                  toClassName(style),
                                  "tw-inline-flex tw-gap-1 tw-items-center tw-justify-center",
                                  "tw-appearance-none tw-h-7 tw-py-1 tw-px-4 tw-border",
                                  "tw-border-solid tw-rounded-sm tw-inline-block tw-text-sm tw-text-center",
                                  "tw-align-middle tw-no-underline tw-outline-none tw-select-none",
                                  "tw-whitespace-nowrap tw-transition tw-duration-200",
                                  Mithril.$$class([
                                        [
                                          "tw-cursor-pointer",
                                          !disabled
                                        ],
                                        [
                                          "tw-cursor-default tw-pointer-events-none tw-opacity-65",
                                          disabled
                                        ]
                                      ], undefined)
                                ], undefined),
                            children: Caml_option.some(vnode.children)
                          });
              }));
}

var ButtonStyle = {};

export {
  ButtonStyle ,
  make ,
}
/* React Not a pure module */
