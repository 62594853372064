// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../URLs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Level from "../Level.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Answer from "../Answer.js";
import * as Feather from "../../common/views/Feather.js";
import * as LevelId from "../LevelId.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril$1 from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Question from "../Question.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as TileLink from "../../common/views/TileLink.js";
import * as QuestionId from "../QuestionId.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as State__Memory from "../../state/State__Memory.js";

function dataFromUrl() {
  var levelId = LevelId.Route.param("levelId");
  var areaFilters = Curry._2(Prelude.OptionExported.$$Option.map, Mithril$1.route.param("areas"), (function (p) {
          return Prelude.$$Array.keepSome(p.split(",").map(function (x) {
                          return Question.QuestionArea.fromJs(x);
                        }));
        }));
  var riskFilters = Curry._2(Prelude.OptionExported.$$Option.map, Mithril$1.route.param("risks"), (function (p) {
          return Prelude.$$Array.keepSome(p.split(",").map(Level.riskStatusFromJs));
        }));
  var answeredFilters = Curry._2(Prelude.OptionExported.$$Option.map, Mithril$1.route.param("answered"), (function (p) {
          return Prelude.$$Array.keepSome(p.split(",").map(function (x) {
                          return Question.Answered.fromJs(x);
                        }));
        }));
  return {
          levelId: levelId,
          areaFilters: areaFilters,
          riskFilters: riskFilters,
          answeredFilters: answeredFilters
        };
}

function getLastOfMap(question, getter, profiles, currentProfile) {
  return Prelude.$$Array.last(Curry._2(Prelude.$$Array.keep, Prelude.$$Array.sort(Prelude.default(QuestionId.$$Map.get(getter, question.id), []), (function (a) {
                        return a.timestamp;
                      }), undefined), (function (a) {
                    return Answer.questionVisibilityGetter(question)(currentProfile, Prelude.$$Array.first(Curry._2(Prelude.$$Array.keep, profiles, (function (p) {
                                          return Caml_obj.equal(Caml_option.some(p.id), a.profile_id);
                                        }))));
                  })));
}

function getCurrentLevelId(levels, levelId) {
  var root = Prelude.$$Array.first(levels);
  var rootId = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, root, (function (level) {
              return level.id;
            })), LevelId.$$null);
  if (Caml_obj.notequal(levelId, LevelId.$$null)) {
    return levelId;
  } else {
    return rootId;
  }
}

function getCurrentLevel(levels, levelId) {
  var currentLevelId = getCurrentLevelId(levels, levelId);
  return Prelude.default(Prelude.$$Array.first(Curry._2(Prelude.$$Array.keep, levels, (function (l) {
                        return Caml_obj.equal(l.id, currentLevelId);
                      }))), Level.Defaults.$$null);
}

function make(param) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), (function (vnode) {
                    var currentProfile = State__Memory.Current.get().profile;
                    var areaFilters = Curry._2(Prelude.OptionExported.$$Option.map, Mithril$1.route.param("areas"), (function (p) {
                            return Prelude.$$Array.keepSome(p.split(",").map(function (x) {
                                            return Question.QuestionArea.fromJs(x);
                                          }));
                          }));
                    var answeredFilters = Curry._2(Prelude.OptionExported.$$Option.map, Mithril$1.route.param("answered"), (function (p) {
                            return Prelude.$$Array.keepSome(p.split(",").map(function (x) {
                                            return Question.Answered.fromJs(x);
                                          }));
                          }));
                    var path = vnode.attrs.level.path;
                    var newrecord = Caml_obj.obj_dup(Query.makeRead());
                    Prelude.thenDo(Query.read((newrecord.newAnswers = Answer.insideLevel(path), newrecord.answers = Answer.insideLevel(path), newrecord.questions = Question.insideLevel(path), newrecord.newUserProfiles = "All", newrecord.userProfiles = "All", newrecord)), (function (param) {
                            var newAnswers = param.newAnswers;
                            var answers = param.answers;
                            var newUserProfiles = param.newUserProfiles;
                            var userProfiles = param.userProfiles;
                            var profiles = Curry._2(Prelude.$$Array.concat, userProfiles, newUserProfiles);
                            var questions = Curry._2(Prelude.$$Array.keep, param.questions, (function (q) {
                                    if (Question.filterByQuestionArea(q, areaFilters)) {
                                      return Question.filterByQuestionRank(q, currentProfile);
                                    } else {
                                      return false;
                                    }
                                  }));
                            var answersByQuestion = QuestionId.$$Array.group(answers, (function (i) {
                                    return i.question_id;
                                  }));
                            var newAnswersByQuestion = QuestionId.$$Array.group(newAnswers, (function (i) {
                                    return i.question_id;
                                  }));
                            var currentAnswers = Curry._2(Prelude.$$Array.keep, questions.map(function (q) {
                                      var lastAnswer = getLastOfMap(q, answersByQuestion, profiles, currentProfile);
                                      var lastNewAnswer = getLastOfMap(q, newAnswersByQuestion, profiles, currentProfile);
                                      if (Curry._1(Prelude.OptionExported.$$Option.isSome, lastNewAnswer)) {
                                        return lastNewAnswer;
                                      } else {
                                        return lastAnswer;
                                      }
                                    }), Prelude.OptionExported.$$Option.isSome);
                            var questions$1;
                            if (Prelude.$$Array.isNotEmpty(currentAnswers)) {
                              var answeredQuestionIds = currentAnswers.map(function (a) {
                                    return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, a, (function (a) {
                                                      return a.question_id;
                                                    })), QuestionId.$$null);
                                  });
                              questions$1 = Curry._2(Prelude.$$Array.keep, questions, (function (q) {
                                      return Question.filterByAnswered(q, answeredFilters, answeredQuestionIds);
                                    }));
                            } else {
                              questions$1 = questions;
                            }
                            var profiles$1 = Curry._2(Prelude.$$Array.concat, userProfiles, newUserProfiles);
                            var questionById = QuestionId.$$Map.fromArray(questions$1.map(function (q) {
                                      return [
                                              q.id,
                                              q
                                            ];
                                    }));
                            var isAnswerVisible = function (answer) {
                              var question = QuestionId.$$Map.get(questionById, answer.question_id);
                              if (question !== undefined) {
                                return Answer.questionVisibilityGetter(question)(currentProfile, Prelude.$$Array.first(Curry._2(Prelude.$$Array.keep, profiles$1, (function (p) {
                                                      return Caml_obj.equal(Caml_option.some(p.id), answer.profile_id);
                                                    }))));
                              } else {
                                return false;
                              }
                            };
                            var allAnswers = Curry._2(Prelude.$$Array.keep, Curry._2(Prelude.$$Array.keep, Curry._2(Prelude.$$Array.concat, answers, newAnswers), (function (a) {
                                        return Curry._1(Prelude.OptionExported.$$Option.isSome, QuestionId.$$Map.get(questionById, a.question_id));
                                      })), isAnswerVisible);
                            var questionsCount = QuestionId.$$Set.toArray(QuestionId.$$Set.fromArray(questions$1.map(function (a) {
                                          return a.id;
                                        }))).length;
                            var answersCount = QuestionId.$$Set.toArray(QuestionId.$$Set.fromArray(allAnswers.map(function (a) {
                                          return a.question_id;
                                        }))).length;
                            Mithril.setState(vnode, {
                                  TAG: "Loaded",
                                  questionsCount: questionsCount,
                                  answersCount: answersCount
                                });
                          }));
                  })), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                }
                var answersCount = match.answersCount;
                var questionsCount = match.questionsCount;
                return ReactDOM.jsx("div", {
                            children: questionsCount !== answersCount ? ReactDOM.jsx("div", {
                                    children: String(answersCount) + "/" + String(questionsCount)
                                  }) : React.jsx(Feather.make, {
                                    icon: "check_circle",
                                    className: "text-success",
                                    size: 22
                                  }),
                            className: "text-center my-2"
                          });
              }));
}

function load(vnode) {
  var system = State__Memory.Current.get();
  var currentProfile = system.profile;
  var vesselId = vnode.attrs.vesselId;
  var match = dataFromUrl();
  var answeredFilters = match.answeredFilters;
  var riskFilters = match.riskFilters;
  var areaFilters = match.areaFilters;
  var levelId = match.levelId;
  Prelude.thenDo(Query.doEach([
            {
              TAG: "Read",
              _0: (function (param) {
                  var newrecord = Caml_obj.obj_dup(Query.makeRead());
                  newrecord.levels = Level.root(vesselId);
                  newrecord.newUserProfiles = "All";
                  newrecord.userProfiles = "All";
                  return newrecord;
                })
            },
            {
              TAG: "Read",
              _0: (function (param) {
                  var currentLevelId = getCurrentLevelId(param.levels, levelId);
                  var newrecord = Caml_obj.obj_dup(Query.makeRead());
                  newrecord.levels = {
                    TAG: "Or",
                    _0: Level.childrenOf(currentLevelId),
                    _1: {
                      TAG: "Or",
                      _0: Level.chapters(vesselId),
                      _1: {
                        TAG: "Get",
                        _0: currentLevelId
                      }
                    }
                  };
                  return newrecord;
                })
            },
            {
              TAG: "Read",
              _0: (function (param) {
                  var currentLevel = getCurrentLevel(param.levels, levelId);
                  var newrecord = Caml_obj.obj_dup(Query.makeRead());
                  newrecord.questions = Question.insideLevel(currentLevel.path);
                  return newrecord;
                })
            },
            {
              TAG: "Read",
              _0: (function (param) {
                  var questions = param.questions;
                  var questionsIds = Question.ids(questions);
                  var newrecord = Caml_obj.obj_dup(Query.makeRead());
                  newrecord.newAnswers = {
                    TAG: "And",
                    _0: Answer.ofQuestions(questionsIds),
                    _1: Answer.ofLevel(levelId)
                  };
                  newrecord.answers = Answer.ofQuestions(questions.map(function (q) {
                            return q.id;
                          }));
                  return newrecord;
                })
            }
          ]), (function (param) {
          var levels = param.levels;
          var profiles = Curry._2(Prelude.$$Array.concat, param.userProfiles, param.newUserProfiles);
          var currentLevelId = getCurrentLevelId(levels, levelId);
          var currentLevel = getCurrentLevel(levels, levelId);
          var questions = Curry._2(Prelude.$$Array.keep, param.questions, (function (q) {
                  if (Question.filterByQuestionArea(q, areaFilters)) {
                    return Question.filterByQuestionRank(q, currentProfile);
                  } else {
                    return false;
                  }
                }));
          var answersByQuestion = QuestionId.$$Array.group(param.answers, (function (i) {
                  return i.question_id;
                }));
          var newAnswersByQuestion = QuestionId.$$Array.group(param.newAnswers, (function (i) {
                  return i.question_id;
                }));
          var currentAnswers = Curry._2(Prelude.$$Array.keep, questions.map(function (q) {
                    var lastAnswer = getLastOfMap(q, answersByQuestion, profiles, currentProfile);
                    var lastNewAnswer = getLastOfMap(q, newAnswersByQuestion, profiles, currentProfile);
                    if (Curry._1(Prelude.OptionExported.$$Option.isSome, lastNewAnswer)) {
                      return lastNewAnswer;
                    } else {
                      return lastAnswer;
                    }
                  }), Prelude.OptionExported.$$Option.isSome);
          var questions$1;
          if (Prelude.$$Array.isNotEmpty(currentAnswers)) {
            var answeredQuestionIds = currentAnswers.map(function (a) {
                  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, a, (function (a) {
                                    return a.question_id;
                                  })), QuestionId.$$null);
                });
            questions$1 = Curry._2(Prelude.$$Array.keep, questions, (function (q) {
                    return Question.filterByAnswered(q, answeredFilters, answeredQuestionIds);
                  }));
          } else {
            questions$1 = questions;
          }
          var match = Curry._3(Prelude.$$Array.fold, Curry._1(Prelude.$$Array.concatMany, questions$1.map(function (q) {
                        return Curry._2(Prelude.$$Array.keep, q.level_paths, (function (i) {
                                      return i.startsWith(currentLevel.path);
                                    }));
                      })), [
                [],
                levels
              ], (function (param, path) {
                  var match = Curry._2(Prelude.$$Array.partition, param[1], (function (extra) {
                          if (extra.path === path) {
                            return true;
                          } else {
                            return path.startsWith(extra.path);
                          }
                        }));
                  var branches = Curry._2(Prelude.$$Array.concat, param[0], match[0]);
                  return [
                          branches,
                          match[1]
                        ];
                }));
          var children = Prelude.$$Array.sort(Curry._2(Prelude.$$Array.keep, match[0], (function (l) {
                      if (Caml_obj.equal(l.parent_id, Caml_option.some(currentLevelId))) {
                        if (riskFilters !== undefined) {
                          if (riskFilters.length !== 0) {
                            if (Curry._1(Prelude.OptionExported.$$Option.isSome, l.risk_status)) {
                              return Prelude.$$Array.includes(riskFilters, Curry._1(Prelude.OptionExported.$$Option.getExn, l.risk_status));
                            } else {
                              return false;
                            }
                          } else if (Caml_obj.equal(Mithril$1.route.param("risks"), "")) {
                            return true;
                          } else {
                            return Curry._1(Prelude.OptionExported.$$Option.isNone, Mithril$1.route.param("risks"));
                          }
                        } else {
                          return true;
                        }
                      } else {
                        return false;
                      }
                    })), (function (l) {
                  return l.number;
                }), undefined);
          Mithril.setState(vnode, {
                TAG: "Loaded",
                children: children,
                levelId: levelId,
                currentLevel: currentLevel,
                questions: questions$1,
                areaFilters: areaFilters,
                riskFilters: riskFilters,
                answeredFilters: answeredFilters
              });
        }));
}

function make$1(param) {
  return Mithril.view(Mithril.onupdate(Mithril.oninit(Mithril.component("NotReady"), load), (function (vnode) {
                    var urlData = dataFromUrl();
                    var curAreaFilters = urlData.areaFilters;
                    var curRiskFilters = urlData.riskFilters;
                    var curAnsweredFilters = urlData.answeredFilters;
                    var curLevelId = urlData.levelId;
                    var match = vnode.state.data;
                    if (typeof match !== "object" || !(Caml_obj.notequal(curLevelId, match.levelId) || Caml_obj.notequal(curAreaFilters, match.areaFilters) || Caml_obj.notequal(curRiskFilters, match.riskFilters) || Caml_obj.notequal(curAnsweredFilters, match.answeredFilters))) {
                      return ;
                    } else {
                      return load(vnode);
                    }
                  })), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                }
                var questions = match.questions;
                var vesselId = vnode.attrs.vesselId;
                return match.children.map(function (child) {
                            var areas = Mithril$1.route.param("areas");
                            var risks = Mithril$1.route.param("risks");
                            var answered = Mithril$1.route.param("answered");
                            var url = URLs.vesselLevelByKind(vesselId, child.id, "sire_chapter", areas, risks, answered);
                            if (Prelude.$$Array.isEmpty(Curry._2(Prelude.$$Array.keep, questions, (function (q) {
                                          return !Prelude.$$Array.isEmpty(Curry._2(Prelude.$$Array.keep, q.level_paths, (function (path) {
                                                            return path.startsWith(child.path);
                                                          })));
                                        })))) {
                              return ReactDOM.jsxKeyed("div", {}, child.id, undefined);
                            } else {
                              return React.jsxKeyed(TileLink.make, {
                                          title: child.name,
                                          href: url,
                                          action: Caml_option.some(React.jsx(make, {
                                                    level: child
                                                  }))
                                        }, url, undefined);
                            }
                          });
              }));
}

export {
  make$1 as make,
}
/* URLs Not a pure module */
