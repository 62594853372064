// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../URLs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Level from "../Level.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Answer from "../Answer.js";
import * as Feather from "../../common/views/Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril$1 from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Question from "../Question.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as TileLink from "../../common/views/TileLink.js";
import * as VesselId from "../../vessel/VesselId.js";
import * as LevelType from "../LevelType.js";
import * as ListLayout from "../../common/views/ListLayout.js";
import * as QuestionId from "../QuestionId.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as State__Memory from "../../state/State__Memory.js";
import * as LevelSubmissionId from "../LevelSubmissionId.js";

function make(param) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), (function (vnode) {
                    var currentProfile = State__Memory.Current.get().profile;
                    var path = vnode.attrs.level.path;
                    var newrecord = Caml_obj.obj_dup(Query.makeRead());
                    Prelude.thenDo(Query.read((newrecord.newAnswers = Answer.insideLevel(path), newrecord.answers = Answer.insideLevel(path), newrecord.questions = Question.insideLevel(path), newrecord)), (function (param) {
                            var questions = Curry._2(Prelude.$$Array.keep, param.questions, (function (__x) {
                                    return Question.filterByQuestionRank(__x, currentProfile);
                                  }));
                            var questionById = QuestionId.$$Map.fromArray(questions.map(function (q) {
                                      return [
                                              q.id,
                                              q
                                            ];
                                    }));
                            var today = new Date();
                            var allAnswers = Curry._2(Prelude.$$Array.keep, Curry._2(Prelude.$$Array.concat, param.answers, param.newAnswers), (function (a) {
                                    var question = QuestionId.$$Map.get(questionById, a.question_id);
                                    if (question !== undefined) {
                                      return Prelude.$$Array.isNotEmpty(Curry._2(Prelude.$$Array.keep, question.levels, (function (link) {
                                                        if (Caml_obj.equal(a.level_id, link.level_id)) {
                                                          if (Caml_obj.lessequal(a.timestamp, Prelude.default(link.due_date, today)) && Caml_obj.lessequal(today, Prelude.default(link.due_date, today))) {
                                                            return true;
                                                          } else {
                                                            return Caml_obj.lessequal(Prelude.default(link.due_date, today), a.timestamp);
                                                          }
                                                        } else {
                                                          return false;
                                                        }
                                                      })));
                                    } else {
                                      return false;
                                    }
                                  }));
                            Mithril.setState(vnode, {
                                  TAG: "Loaded",
                                  questions: questions,
                                  answers: allAnswers
                                });
                          }));
                  })), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                }
                var qCount = match.questions.length;
                var aCount = QuestionId.$$Set.toArray(QuestionId.$$Set.fromArray(match.answers.map(function (a) {
                              return a.question_id;
                            }))).length;
                return ReactDOM.jsx("div", {
                            children: qCount !== aCount ? ReactDOM.jsx("div", {
                                    children: String(aCount) + "/" + String(qCount)
                                  }) : React.jsx(Feather.make, {
                                    icon: "check_circle",
                                    className: "text-success",
                                    size: 22
                                  }),
                            className: "text-center my-2"
                          });
              }));
}

var Completion = {
  make: make
};

function load(vnode) {
  var vesselId = VesselId.Route.param("vesselId");
  var kind = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.flatMap, Mithril$1.route.param("kind"), LevelType.kindFromJs), "section");
  var parentKind = kind === "checklist_part" ? "checklist" : (
      kind === "company_form" ? "company_form" : (
          kind === "safety_component" || kind === "structural_component" ? "section" : "vessel"
        )
    );
  Prelude.thenDo(Query.doEach([
            {
              TAG: "Read",
              _0: (function (param) {
                  var newrecord = Caml_obj.obj_dup(Query.makeRead());
                  newrecord.levelTypes = LevelType.anyOfKind([
                        kind,
                        parentKind
                      ]);
                  return newrecord;
                })
            },
            {
              TAG: "Read",
              _0: (function (param) {
                  var levelTypesIds = Curry._2(Prelude.$$Array.keep, param.levelTypes, (function (i) {
                            return i.kind === kind;
                          })).map(function (i) {
                        return i.id;
                      });
                  var newrecord = Caml_obj.obj_dup(Query.makeRead());
                  newrecord.levels = {
                    TAG: "And",
                    _0: Level.ofVessel(vesselId),
                    _1: {
                      TAG: "AnyOf",
                      _0: "kind",
                      _1: levelTypesIds
                    }
                  };
                  return newrecord;
                })
            },
            {
              TAG: "Read",
              _0: (function (param) {
                  var mapFx = kind === "safety_component" || kind === "structural_component" || kind === "checklist_part" ? (function (i) {
                        return i.parent_id;
                      }) : (function (i) {
                        return Caml_option.some(i.id);
                      });
                  var levelParentIds = Prelude.$$Array.keepSome(param.levels.map(Curry.__1(mapFx)));
                  var parentLevelTypeIds = Curry._2(Prelude.$$Array.keep, param.levelTypes, (function (i) {
                            return i.kind === parentKind;
                          })).map(function (i) {
                        return i.id;
                      });
                  var newrecord = Caml_obj.obj_dup(Query.makeRead());
                  newrecord.questions = Question.ofVessel(vesselId);
                  newrecord.levels = {
                    TAG: "And",
                    _0: {
                      TAG: "In",
                      _0: levelParentIds
                    },
                    _1: {
                      TAG: "AnyOf",
                      _0: "kind",
                      _1: parentLevelTypeIds
                    }
                  };
                  return newrecord;
                })
            }
          ]), (function (param) {
          var levels = param.levels;
          var draftSubmissionId = LevelSubmissionId.Route.param("draftSubmissionId");
          var questions = Curry._2(Prelude.$$Array.keep, param.questions, (function (q) {
                  return Prelude.$$Array.isNotEmpty(Curry._2(Prelude.$$Array.keep, q.level_paths, (function (path) {
                                    return Prelude.$$Array.isNotEmpty(Curry._2(Prelude.$$Array.keep, levels, (function (l) {
                                                      return path.startsWith(l.path);
                                                    })));
                                  })));
                }));
          var newrecord = Caml_obj.obj_dup(Query.makeRead());
          Prelude.thenDo(Prelude.thenResolve(Query.read((newrecord.draftLevelSubmissions = {
                          TAG: "Get",
                          _0: draftSubmissionId
                        }, newrecord)), (function (param) {
                      return [
                              levels,
                              param.draftLevelSubmissions,
                              questions
                            ];
                    })), (function (param) {
                  var submission = Prelude.$$Array.first(param[1]);
                  Mithril.setState(vnode, {
                        TAG: "Loaded",
                        levels: param[0],
                        vesselId: vesselId,
                        submission: submission,
                        questions: param[2]
                      });
                }));
        }));
}

function make$1(param) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), (function (vnode) {
                    load(vnode);
                  })), (function (vnode) {
                var match = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.flatMap, Mithril$1.route.param("kind"), LevelType.kindFromJs), "vessel");
                var title = match === "checklist_part" ? "Checklists" : (
                    match === "sire" ? "SIRE 2.0" : (
                        match === "company_form" ? "Company Forms" : (
                            match === "structural_component" ? "Hull & Equipment" : (
                                match === "vessel" ? "Vessel" : (
                                    match === "safety_component" ? "Safety" : ""
                                  )
                              )
                          )
                      )
                  );
                var match$1 = vnode.state.data;
                if (typeof match$1 !== "object") {
                  return React.jsx(ListLayout.make, {
                              children: Mithril.empty,
                              title: title,
                              showBackButton: true,
                              showSettings: false
                            });
                }
                var questions = match$1.questions;
                var vesselId = match$1.vesselId;
                var submissionId = Mithril$1.route.param("draftSubmissionId");
                var targetDate = new Date(Prelude.default(Mithril$1.route.param("targetDate"), new Date().toString()));
                return React.jsx(ListLayout.make, {
                            children: match$1.levels.map(function (child) {
                                  var subtitle = Level.getOverdueText(child, questions);
                                  var url = submissionId !== undefined ? URLs.levelSubmission(vesselId, child.id, Caml_option.some(Caml_option.some(submissionId)), Caml_option.some(Caml_option.some(targetDate)), undefined) : URLs.vesselLevel(vesselId, child.id);
                                  return React.jsxKeyed(TileLink.make, {
                                              title: child.name,
                                              href: url,
                                              subtitle: subtitle,
                                              action: Caml_option.some(React.jsx(make, {
                                                        level: child
                                                      }))
                                            }, child.id, undefined);
                                }),
                            title: title,
                            showBackButton: true
                          });
              }));
}

export {
  Completion ,
  load ,
  make$1 as make,
}
/* URLs Not a pure module */
