// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Procedure from "../survey/Procedure.js";
import * as ReIndexed from "@kaiko.io/rescript-reindexed/lib/es6/src/ReIndexed.js";
import * as SectionId from "./SectionId.js";
import * as ComponentId from "./ComponentId.js";

var ComponentDef = {};

var include = ReIndexed.MakeIdModel(ComponentDef, {});

function cmp(c1, c2) {
  return Caml_obj.compare(c1.id, c2.id);
}

var Comparator = Belt_Id.MakeComparableU({
      cmp: cmp
    });

function ofVessel(vesselId) {
  return {
          TAG: "Is",
          _0: "vessel_id",
          _1: vesselId
        };
}

function ofSections(sectionIds) {
  return {
          TAG: "AnyOf",
          _0: "section_id",
          _1: sectionIds
        };
}

function ofType(type_) {
  return {
          TAG: "Is",
          _0: "type_",
          _1: type_
        };
}

function ids(__x) {
  return __x.map(function (component) {
              return component.id;
            });
}

function sort(components) {
  return Prelude.$$Array.sort(components, (function (component) {
                return component.name;
              }), undefined);
}

function byName(component) {
  return component.name;
}

function bySection(component) {
  return component.section_id;
}

function byNumber(component) {
  return component.number;
}

function byOrder(component) {
  return component.order;
}

function sectionIds(components) {
  return SectionId.MutableSet.fromArray(components.map(bySection));
}

function groupByNameAndNumber(components, aditionalCheckOpt, param) {
  var aditionalCheck = aditionalCheckOpt !== undefined ? aditionalCheckOpt : (function (param) {
        return true;
      });
  return Prelude.$$Array.sort(Belt_List.toArray(Belt_List.map(Curry._3(Prelude.$$Array.fold, Prelude.$$Array.sort(components, byName, undefined), /* [] */0, (function (groups, component) {
                            if (!groups) {
                              return {
                                      hd: [component],
                                      tl: /* [] */0
                                    };
                            }
                            var group = groups.hd;
                            var lastAddedComponent = Prelude.$$Array.lastUnsafe(group);
                            var addToLastGroup = lastAddedComponent.name === component.name && lastAddedComponent.number > 0 && component.number > 0 && aditionalCheck(component);
                            if (addToLastGroup) {
                              return Belt_List.add(groups.tl, Curry._2(Prelude.$$Array.concat, group, [component]));
                            } else {
                              return Belt_List.add(groups, [component]);
                            }
                          })), (function (components) {
                        return Prelude.$$Array.sort(components, byNumber, undefined);
                      }))), (function (components) {
                return Prelude.$$Array.firstUnsafe(components).order;
              }), undefined);
}

function nameWithNumber(component) {
  if (component.number === 0) {
    return component.name;
  } else {
    return "#" + String(component.number) + ": " + component.name;
  }
}

function getCompletion(component, procedures) {
  var procedures$1 = Curry._2(Prelude.$$Array.keep, procedures, (function (param) {
          return Caml_obj.equal(param.component_id, component.id);
        }));
  var fullyInspectedProcedures = Curry._2(Prelude.$$Array.keep, procedures$1, (function (procedure) {
          return Procedure.isFullyInspected(procedure);
        }));
  return {
          totalNested: procedures$1.length,
          totalInspected: fullyInspectedProcedures.length,
          isOverdue: Procedure.areOverdue(procedures$1)
        };
}

function matchComponentsFromProcedures(components, procedures) {
  return Curry._2(Prelude.$$Array.some, procedures, (function (procedure) {
                return Curry._2(Prelude.$$Array.some, components, (function (c) {
                              return Caml_obj.equal(c.id, procedure.component_id);
                            }));
              }));
}

function describeComponentType(ctype) {
  if (ctype === "structural") {
    return "Hull & Equipment";
  } else if (ctype === "checklist") {
    return "Checklists";
  } else {
    return "Safety";
  }
}

var Identifier;

var IdComparator = include.IdComparator;

var Id = include.Id;

export {
  Identifier ,
  ComponentDef ,
  IdComparator ,
  Id ,
  Comparator ,
  ofVessel ,
  ofSections ,
  ofType ,
  ids ,
  sort ,
  byName ,
  bySection ,
  byNumber ,
  byOrder ,
  sectionIds ,
  groupByNameAndNumber ,
  nameWithNumber ,
  getCompletion ,
  matchComponentsFromProcedures ,
  describeComponentType ,
}
/* include Not a pure module */
