// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as KeepScrollLink from "../../../libs/KeepScrollLink.js";

function make(param) {
  return Mithril.view(Mithril.component(undefined), (function (vnode) {
                var href = Prelude.default(vnode.attrs.href, "");
                var title = Prelude.default(vnode.attrs.title, "");
                var subtitle = vnode.attrs.subtitle;
                var action = Prelude.default(vnode.attrs.action, Mithril.empty);
                var disabled = Prelude.default(vnode.attrs.disabled, false);
                var titleClassName = Prelude.default(vnode.attrs.titleClassName, "");
                var titleStyle = Prelude.default(vnode.attrs.titleStyle, {});
                if (disabled) {
                  return ReactDOM.jsxs("div", {
                              children: [
                                ReactDOM.jsxs("div", {
                                      children: [
                                        ReactDOM.jsx("p", {
                                              children: title,
                                              className: Mithril.$$class([
                                                    [
                                                      "tile-title h5",
                                                      true
                                                    ],
                                                    [
                                                      titleClassName,
                                                      titleClassName !== ""
                                                    ]
                                                  ], undefined),
                                              style: titleStyle
                                            }),
                                        subtitle !== undefined ? ReactDOM.jsx("small", {
                                                children: subtitle,
                                                className: "tile-subtitle text-gray"
                                              }) : Mithril.empty
                                      ],
                                      className: "tile-content"
                                    }),
                                action
                              ],
                              className: "tile tile-centered tile-shadow round t-section my-2",
                              style: {
                                opacity: "0.7"
                              }
                            });
                } else {
                  return React.jsx(KeepScrollLink.make, {
                              href: href,
                              className: "my-2 d-block",
                              children: Caml_option.some(ReactDOM.jsxs("div", {
                                        children: [
                                          ReactDOM.jsxs("div", {
                                                children: [
                                                  ReactDOM.jsx("p", {
                                                        children: title,
                                                        className: Mithril.$$class([
                                                              [
                                                                "tile-title h5",
                                                                true
                                                              ],
                                                              [
                                                                titleClassName,
                                                                titleClassName !== ""
                                                              ]
                                                            ], undefined),
                                                        style: titleStyle
                                                      }),
                                                  subtitle !== undefined ? ReactDOM.jsx("small", {
                                                          children: subtitle,
                                                          className: "tile-subtitle text-gray"
                                                        }) : Mithril.empty
                                                ],
                                                className: "tile-content"
                                              }),
                                          action
                                        ],
                                        className: "tile tile-centered tile-shadow round t-section"
                                      })),
                              onClick: Prelude.default(vnode.attrs.onClick, (function (param) {
                                      
                                    }))
                            });
                }
              }));
}

export {
  make ,
}
/* React Not a pure module */
