// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Utils from "../../libs/Utils.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Settings from "../Settings.js";
import * as LocalFile from "../../libs/LocalFile.js";
import * as ReIndexed from "@kaiko.io/rescript-reindexed/lib/es6/src/ReIndexed.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as AttachmentId from "./AttachmentId.js";
import * as WebCryptoAPI from "../../libs/WebCryptoAPI.js";
import * as FutureAttachmentChunkId from "./FutureAttachmentChunkId.js";

var include = Utils.MakeInt({});

var map = include.map;

var toString = include.toString;

var $$default = map(include.$$default, (function (z) {
        return z + 1 | 0;
      }));

var Sequence_zero = include.zero;

var Sequence_null = include.$$null;

var Sequence_fmap = include.fmap;

var Sequence_Math = include.$$Math;

var Sequence = {
  zero: Sequence_zero,
  $$null: Sequence_null,
  fmap: Sequence_fmap,
  map: map,
  toString: toString,
  $$Math: Sequence_Math,
  $$default: $$default
};

var Offset = Utils.MakeInt({});

function make(id, sequence, size) {
  return {
          id: id,
          sequence: sequence,
          size: size
        };
}

var ChunkPayload = {
  make: make
};

var Definition = {};

var include$1 = ReIndexed.MakeIdModel(Definition, {});

var IdComparator = include$1.IdComparator;

async function _storeChunk(chunk, index, checksum, checksum_algorithm) {
  var id = FutureAttachmentChunkId.make();
  var size = chunk.size;
  var url = "chunk::" + id + "::" + toString(index);
  await LocalFile.store(Caml_option.some(id), Caml_option.some(url), true, chunk);
  var newrecord = Caml_obj.obj_dup(make(id, index, size));
  newrecord.checksum_algorithm = checksum_algorithm;
  newrecord.checksum = Caml_option.some(checksum);
  return newrecord;
}

async function _splitBlob(blob, maxChunkSize) {
  var chunks = [];
  var blobSize = blob.size | 0;
  var buffer = await blob.arrayBuffer();
  var split = async function (sequence, offset) {
    if (offset >= blobSize) {
      return ;
    }
    var end = Math.min(Offset.map(offset, (function (o) {
                return o + maxChunkSize | 0;
              })), blobSize);
    var slice = buffer.slice(offset, end);
    var chunk = new Blob([slice]);
    var checksum = await WebCryptoAPI.HexDigest.fromArrayBuffer(slice, "SHA-256");
    chunks.push(await _storeChunk(chunk, sequence, checksum, "SHA-256"));
    return await split(map(sequence, (function (s) {
                      return s + 1 | 0;
                    })), end);
  };
  await split($$default, Offset.zero);
  return chunks;
}

async function fromInspectionAttachmentBlob(inspection, attachment_id, role, blob) {
  var size = blob.size;
  var checksum = await WebCryptoAPI.HexDigest.fromBlob(blob, "SHA-256");
  var chunks = await _splitBlob(blob, Settings.Upload.maxBlobChunkSizeInBytes);
  var mimetype = blob.type;
  return {
          id: attachment_id,
          vessel_id: inspection.vessel_id,
          allChunks: chunks,
          localChunks: chunks,
          sentChunks: [],
          checksum: checksum,
          checksum_algorithm: "SHA-256",
          size: size,
          payload: {
            NAME: "inspection",
            VAL: [
              inspection.id,
              role
            ]
          },
          mimetype: mimetype,
          created_at: new Date()
        };
}

async function fromAnswerAttachmentBlob(answer, attachment_id, role, blob) {
  var size = blob.size;
  var checksum = await WebCryptoAPI.HexDigest.fromBlob(blob, "SHA-256");
  var chunks = await _splitBlob(blob, Settings.Upload.maxBlobChunkSizeInBytes);
  var mimetype = blob.type;
  return {
          id: attachment_id,
          vessel_id: answer.vessel_id,
          allChunks: chunks,
          localChunks: chunks,
          sentChunks: [],
          checksum: checksum,
          checksum_algorithm: "SHA-256",
          size: size,
          payload: {
            NAME: "answer",
            VAL: [
              answer.id,
              role
            ]
          },
          mimetype: mimetype,
          created_at: new Date()
        };
}

async function fromUserProfileBlob(profile, attachment_id, blob) {
  var size = blob.size;
  var checksum = await WebCryptoAPI.HexDigest.fromBlob(blob, "SHA-256");
  var chunks = await _splitBlob(blob, Settings.Upload.maxBlobChunkSizeInBytes);
  var mimetype = blob.type;
  return {
          id: attachment_id,
          vessel_id: profile.vessel,
          allChunks: chunks,
          localChunks: chunks,
          sentChunks: [],
          checksum: checksum,
          checksum_algorithm: "SHA-256",
          size: size,
          payload: {
            NAME: "profile",
            VAL: profile.id
          },
          mimetype: mimetype,
          created_at: new Date()
        };
}

function _getProcImag(id) {
  return {
          id: id,
          file: undefined,
          thumbnail: undefined
        };
}

function getImages(css) {
  return Prelude.$$Array.flatMap(css, (function (cs) {
                      return cs.localChunks;
                    })).map(function (param) {
                  return param.id;
                }).map(_getProcImag).map(function (p) {
              return {
                      TAG: "Device",
                      _0: p
                    };
            });
}

function ofVessel(vesselId) {
  return {
          TAG: "Is",
          _0: "vessel_id",
          _1: vesselId
        };
}

function deduplicate(futures) {
  return Belt_Set.toArray(Belt_Set.mergeMany(Belt_Set.make(IdComparator), futures));
}

function pendingSize(future) {
  return future.localChunks.reduce((function (result, chunk) {
                return result + chunk.size;
              }), 0.0);
}

var Id = include$1.Id;

var Comparator;

export {
  Sequence ,
  Offset ,
  ChunkPayload ,
  Definition ,
  IdComparator ,
  Id ,
  _storeChunk ,
  _splitBlob ,
  fromInspectionAttachmentBlob ,
  fromAnswerAttachmentBlob ,
  fromUserProfileBlob ,
  _getProcImag ,
  getImages ,
  ofVessel ,
  Comparator ,
  deduplicate ,
  pendingSize ,
}
/* include Not a pure module */
