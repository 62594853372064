// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Utils from "../../libs/Utils.js";
import * as ReIndexed from "@kaiko.io/rescript-reindexed/lib/es6/src/ReIndexed.js";

var include = Utils.MakeOpaqueIdentifier({});

var zero = include.zero;

function fromString(param) {
  return zero;
}

function manyFromString(ids) {
  return ids.map(function (param) {
              return zero;
            });
}

function make() {
  return zero;
}

var Identifier_toHTMLID = include.toHTMLID;

var Identifier_Option = include.$$Option;

var Identifier_Comparator = include.Comparator;

var Identifier_Hasher = include.Hasher;

var Identifier_Set = include.$$Set;

var Identifier_MutableSet = include.MutableSet;

var Identifier_Map = include.$$Map;

var Identifier_Route = include.Route;

var Identifier_Array = include.$$Array;

var Identifier_SortArray = include.SortArray;

var Identifier = {
  zero: zero,
  toHTMLID: Identifier_toHTMLID,
  $$Option: Identifier_Option,
  Comparator: Identifier_Comparator,
  Hasher: Identifier_Hasher,
  $$Set: Identifier_Set,
  MutableSet: Identifier_MutableSet,
  $$Map: Identifier_Map,
  Route: Identifier_Route,
  $$Array: Identifier_Array,
  SortArray: Identifier_SortArray,
  fromString: fromString,
  manyFromString: manyFromString,
  make: make,
  $$null: zero
};

var Model = {};

var include$1 = ReIndexed.MakeIdModel(Model, {});

function update(current_steps) {
  return [{
            TAG: "Save",
            _0: {
              id: zero,
              current_steps: current_steps
            }
          }];
}

var IdComparator = include$1.IdComparator;

var Id = include$1.Id;

export {
  Identifier ,
  Model ,
  IdComparator ,
  Id ,
  update ,
}
/* include Not a pure module */
