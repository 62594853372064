// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../URLs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril$1 from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Component from "../Component.js";
import * as Procedure from "../../survey/Procedure.js";
import * as SectionId from "../SectionId.js";
import * as ListLayout from "../../common/views/ListLayout.js";
import * as ComponentId from "../ComponentId.js";
import * as ProcedureId from "../../survey/ProcedureId.js";
import * as ComponentType from "../ComponentType.js";
import * as Mithril__Route from "../../../libs/rescript-mithril/src/Mithril__Route.js";
import * as PlannedProcedure from "../../survey/PlannedProcedure.js";
import * as Section_Components_DiverseQuestions from "./Section_Components_DiverseQuestions.js";
import * as Section_Components_WithJustPictures from "./Section_Components_WithJustPictures.js";

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var components = vnode.attrs.components;
                var procedures = vnode.attrs.procedures;
                var counterOverlayClassName = vnode.attrs.counterOverlayClassName;
                var asPlanned = Prelude.default(Mithril__Route.paramBool("asPlanned"), false);
                if (asPlanned) {
                  return React.jsx(Section_Components_DiverseQuestions.make, {
                              components: components,
                              procedures: procedures,
                              counterOverlayClassName: counterOverlayClassName
                            });
                }
                var allProceduresArePictures = Curry._2(Prelude.$$Array.every, procedures, (function (p) {
                        return p.question_type === "RatingQuestion";
                      }));
                if (components.length === 1) {
                  var component = components[0];
                  if (allProceduresArePictures) {
                    return React.jsx(Section_Components_WithJustPictures.make, {
                                component: component,
                                procedures: procedures,
                                counterOverlayClassName: counterOverlayClassName
                              });
                  }
                  
                }
                return React.jsx(Section_Components_DiverseQuestions.make, {
                            components: components,
                            procedures: procedures,
                            counterOverlayClassName: counterOverlayClassName
                          });
              }));
}

var ComponentCard = {
  make: make
};

function title(state) {
  if (typeof state !== "object") {
    return "";
  } else {
    return state.section.name;
  }
}

function make$1(param) {
  var sectionId = SectionId.Route.param("sectionId");
  var componentType = Prelude.default(Prelude.maybe(Mithril$1.route.param("componentType"), ComponentType.tFromJs), "structural");
  var asPlanned = Prelude.default(Mithril__Route.paramBool("asPlanned"), false);
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), (function (vnode) {
                    Prelude.thenDo(Query.doEach([
                              {
                                TAG: "Read",
                                _0: (function (param) {
                                    var newrecord = Caml_obj.obj_dup(Query.makeRead());
                                    newrecord.components = {
                                      TAG: "And",
                                      _0: Component.ofSections([sectionId]),
                                      _1: Component.ofType(componentType)
                                    };
                                    newrecord.sections = {
                                      TAG: "Get",
                                      _0: sectionId
                                    };
                                    return newrecord;
                                  })
                              },
                              {
                                TAG: "Read",
                                _0: (function (param) {
                                    var section = Prelude.$$Array.first(param.sections);
                                    var componentIds = Component.ids(param.components);
                                    var newrecord = Caml_obj.obj_dup(Query.makeRead());
                                    newrecord.documents = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Curry._2(Prelude.OptionExported.$$Option.flatMap, section, (function (s) {
                                                    return s.guidance_document;
                                                  })), (function (id) {
                                                return {
                                                        TAG: "Get",
                                                        _0: id
                                                      };
                                              })), "NoOp");
                                    newrecord.plannedProcedures = asPlanned ? PlannedProcedure.ofComponents(componentIds) : "NoOp";
                                    newrecord.procedures = Procedure.ofComponents(componentIds);
                                    return newrecord;
                                  })
                              }
                            ]), (function (param) {
                            var procedures = param.procedures;
                            var section = Prelude.$$Array.first(param.sections);
                            if (section !== undefined) {
                              var procedures$1;
                              if (asPlanned) {
                                var plannedProcedureIds = ProcedureId.$$Set.fromArray(PlannedProcedure.ids(param.plannedProcedures));
                                procedures$1 = Curry._2(Prelude.$$Array.keep, procedures, (function (p) {
                                        return ProcedureId.$$Set.has(plannedProcedureIds, p.id);
                                      }));
                              } else {
                                procedures$1 = procedures;
                              }
                              var proceduresOfComponent = ComponentId.$$Array.getter(procedures$1, Procedure.byComponent);
                              var componentIds = Procedure.componentIds(procedures$1);
                              var componentGroups = Component.groupByNameAndNumber(Curry._2(Prelude.$$Array.keep, param.components, (function (c) {
                                          return ComponentId.MutableSet.has(componentIds, c.id);
                                        })), (function (component) {
                                      return !Curry._2(Prelude.$$Array.every, proceduresOfComponent(component.id), (function (p) {
                                                    return p.question_type === "RatingQuestion";
                                                  }));
                                    }), undefined);
                              return Mithril.setState(vnode, {
                                          TAG: "Loaded",
                                          section: section,
                                          document: Prelude.$$Array.first(param.documents),
                                          componentGroups: componentGroups,
                                          proceduresOfGroups: componentGroups.map(function (components) {
                                                return Prelude.$$Array.flatMap(components, (function (component) {
                                                              return proceduresOfComponent(component.id);
                                                            }));
                                              })
                                        });
                            }
                            window.history.back();
                          }));
                  })), (function (vnode) {
                var match = vnode.state.data;
                var tmp;
                tmp = typeof match !== "object" ? Mithril.empty : Curry._2(Prelude.$$Array.zip, match.componentGroups, match.proceduresOfGroups).map(function (param) {
                        var components = param[0];
                        return React.jsxKeyed(make, {
                                    components: components,
                                    procedures: param[1],
                                    counterOverlayClassName: "completion-group"
                                  }, Prelude.$$Array.firstUnsafe(components).id, undefined);
                      });
                var match$1 = vnode.state.data;
                var tmp$1;
                if (typeof match$1 !== "object") {
                  tmp$1 = [];
                } else {
                  var match$2 = match$1.section;
                  var $$document = match$1.document;
                  tmp$1 = $$document !== undefined ? [{
                        icon: "map",
                        effect: {
                          TAG: "Link",
                          _0: URLs.markdownDocument($$document.id)
                        }
                      }] : (
                      match$2.image !== undefined ? [{
                            icon: "map",
                            effect: {
                              TAG: "Link",
                              _0: URLs.sectionImage(match$2.id)
                            }
                          }] : []
                    );
                }
                return React.jsx(ListLayout.make, {
                            children: tmp,
                            title: title(vnode.state.data),
                            actions: tmp$1,
                            showSettings: true,
                            section: "survey"
                          });
              }));
}

export {
  ComponentCard ,
  title ,
  make$1 as make,
}
/* URLs Not a pure module */
