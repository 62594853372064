// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Mithril from "./rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import Mithril$1 from "mithril";
import * as Mithril$2 from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "./rescript-mithril/src/ReactDOM.js";
import QuickLru from "quick-lru";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Mithril__Route from "./rescript-mithril/src/Mithril__Route.js";

var scrolls = new QuickLru({
      maxSize: 500
    });

function mainScrollViewPort() {
  return Caml_option.nullable_to_opt(document.getElementById("main-scroll-viewport"));
}

function getElementById(id) {
  return Caml_option.nullable_to_opt(document.getElementById(id));
}

function saveScrollById(id) {
  var route = Mithril$2.route.get();
  if (route !== undefined) {
    scrolls.set(route, {
          top: undefined,
          id: id
        });
  } else {
    console.warn("Could not save the scroll position", Mithril$2.route.get(), Mithril__Route.title());
  }
}

function saveScroll() {
  var match = Mithril$2.route.get();
  var match$1 = Curry._2(Prelude.OptionExported.$$Option.map, Caml_option.nullable_to_opt(document.getElementById("main-scroll-viewport")), (function (prim) {
          return prim.scrollTop;
        }));
  if (match !== undefined && match$1 !== undefined) {
    scrolls.set(match, {
          top: match$1,
          id: undefined
        });
    return ;
  }
  console.warn("Could not save the scroll position", Mithril$2.route.get(), Mithril__Route.title());
}

function tryToRestoreScroll(prevHeightOpt, attempt, top, param) {
  var prevHeight = prevHeightOpt !== undefined ? prevHeightOpt : 0.0;
  var element = Caml_option.nullable_to_opt(document.getElementById("main-scroll-viewport"));
  if (element === undefined) {
    return ;
  }
  var element$1 = Caml_option.valFromOption(element);
  element$1.scrollTop = top;
  var scrollHeight = element$1.scrollHeight;
  var stillLoading = scrollHeight !== prevHeight;
  var notReachedTheDesiredPosition = element$1.scrollTop < top;
  if (attempt < 20 && (stillLoading || notReachedTheDesiredPosition)) {
    setTimeout((function (extra) {
            return tryToRestoreScroll(scrollHeight, attempt + 1 | 0, top, extra);
          }), 100);
    return ;
  }
  
}

function tryToRestoreScrollById(prevHeightOpt, attempt, id, param) {
  var prevHeight = prevHeightOpt !== undefined ? prevHeightOpt : 0.0;
  var element = Caml_option.nullable_to_opt(document.getElementById(id));
  if (element === undefined) {
    if (attempt < 20) {
      setTimeout((function (extra) {
              return tryToRestoreScrollById(prevHeight, attempt + 1 | 0, id, extra);
            }), 100);
      return ;
    } else {
      return ;
    }
  }
  var element$1 = Caml_option.valFromOption(element);
  console.log("KeepScrollLink", {
        id: id,
        el: element$1
      });
  var parent = element$1.parentElement;
  if (!(parent == null)) {
    parent.classList.remove("hidden");
  }
  element$1.classList.remove("hidden");
  element$1.scrollIntoView();
  var scrollHeight = element$1.scrollHeight;
  var stillLoading = scrollHeight !== prevHeight;
  if (attempt < 20 && stillLoading) {
    setTimeout((function (extra) {
            return tryToRestoreScrollById(scrollHeight, attempt + 1 | 0, id, extra);
          }), 100);
    return ;
  }
  
}

function restoreScroll() {
  var scroll = Curry._2(Prelude.OptionExported.$$Option.flatMap, Mithril$2.route.get(), (function (extra) {
          return scrolls.get(extra);
        }));
  if (scroll === undefined) {
    return ;
  }
  var top = scroll.top;
  if (top !== undefined) {
    tryToRestoreScroll(undefined, 1, top, undefined);
  }
  var id = scroll.id;
  if (id !== undefined) {
    return tryToRestoreScrollById(undefined, 1, id, undefined);
  }
  
}

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var onClick = vnode.attrs.onClick;
                var newrecord = Caml_obj.obj_dup(vnode.attrs);
                newrecord.onClick = (function ($$event) {
                    saveScroll();
                    Curry._2(Prelude.OptionExported.$$Option.forEach, onClick, (function (onClick) {
                            onClick($$event);
                          }));
                  });
                var match = ReactDOM.processProps(newrecord);
                return Mithril$1(Mithril.Link.make, match[0], vnode.children);
              }));
}

export {
  mainScrollViewPort ,
  getElementById ,
  saveScrollById ,
  saveScroll ,
  tryToRestoreScroll ,
  tryToRestoreScrollById ,
  restoreScroll ,
  make ,
}
/* scrolls Not a pure module */
