// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Luxon from "luxon";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as LocalFile from "../../libs/LocalFile.js";
import * as ReIndexed from "@kaiko.io/rescript-reindexed/lib/es6/src/ReIndexed.js";
import * as InspectionId from "./InspectionId.js";

var Definition = {};

var include = ReIndexed.MakeIdModel(Definition, {});

function cmp(c1, c2) {
  return Caml_obj.compare(c1.id, c2.id);
}

var Comparator = Belt_Id.MakeComparableU({
      cmp: cmp
    });

function ofComponents(componentIds) {
  return {
          TAG: "AnyOf",
          _0: "component_id",
          _1: componentIds.map(function (prim) {
                return prim;
              })
        };
}

function ofVessel(vesselId) {
  return {
          TAG: "Is",
          _0: "vessel_id",
          _1: vesselId
        };
}

function ofProcedures(procedureIds) {
  return {
          TAG: "AnyOf",
          _0: "procedure_id",
          _1: procedureIds
        };
}

function ofProcedure(procedureId) {
  return {
          TAG: "Is",
          _0: "procedure_id",
          _1: procedureId
        };
}

function ofDeficiency(deficiencyId) {
  return {
          TAG: "Is",
          _0: "deficiency_id",
          _1: deficiencyId
        };
}

function withImages(param) {
  return !Prelude.$$Array.isEmpty(param.attachments);
}

function mainEvidence(inspection) {
  return Prelude.$$Array.first(Curry._2(Prelude.$$Array.keep, inspection.attachments, (function (param) {
                    return param.role === "MAIN_EVIDENCE";
                  })));
}

function signature(inspection) {
  return Prelude.$$Array.first(Curry._2(Prelude.$$Array.keep, inspection.attachments, (function (param) {
                    return param.role === "SIGNATURE";
                  })));
}

function getPhoto(inspection) {
  var match = mainEvidence(inspection);
  if (match !== undefined) {
    return Prelude.thenResolve(LocalFile.getResource(match.id), (function (resource) {
                  return Curry._2(Prelude.OptionExported.$$Option.map, resource, (function (param) {
                                return param.file;
                              }));
                }));
  } else {
    return Promise.resolve(undefined);
  }
}

function getMainImage(inspection) {
  return Curry._2(Prelude.OptionExported.$$Option.map, mainEvidence(inspection), (function (param) {
                return param.id;
              }));
}

function getSignature(inspection) {
  return Curry._2(Prelude.OptionExported.$$Option.map, signature(inspection), (function (param) {
                return param.id;
              }));
}

function getDetailedPhotos(inspection) {
  return Curry._2(Prelude.$$Array.keep, inspection.attachments, (function (attachment) {
                return attachment.role === "EVIDENCE";
              }));
}

function size(inspection) {
  var json = JSON.stringify(inspection);
  if (json !== undefined) {
    return json.length;
  } else {
    return 0;
  }
}

function byProcedure(inspection) {
  return inspection.procedure_id;
}

function byComponent(inspection) {
  return inspection.component_id;
}

function bySection(inspection) {
  return inspection.section_id;
}

function byDeficiency(inspection) {
  return inspection.deficiency_id;
}

function byTimestamp(inspection) {
  return inspection.timestamp;
}

function ids(__x) {
  return __x.map(function (inspection) {
              return inspection.id;
            });
}

function _getImages(wrapper, inspections) {
  return Prelude.$$Array.flatMap(inspections, (function (param) {
                return param.attachments.map(function (param) {
                            return wrapper({
                                        id: param.id,
                                        file: param.file,
                                        thumbnail: param.thumbnail
                                      });
                          });
              }));
}

function getImages(extra) {
  return _getImages((function (i) {
                return {
                        TAG: "Cloud",
                        _0: i
                      };
              }), extra);
}

function lastInspection(inspections) {
  return Prelude.$$Array.last(Prelude.$$Array.sort(inspections, byTimestamp, undefined));
}

function firstInspection(inspections) {
  return Prelude.$$Array.first(Prelude.$$Array.sort(inspections, byTimestamp, undefined));
}

function lastInspectionDate(inspections) {
  return Curry._2(Prelude.OptionExported.$$Option.map, lastInspection(inspections), (function (param) {
                return Luxon.DateTime.fromJSDate(param.timestamp);
              }));
}

function timeSinceLastInspection(inspections) {
  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, lastInspectionDate(inspections), (function (prim) {
                    return prim.toRelative();
                  })), "never");
}

var Identifier;

var IdComparator = include.IdComparator;

var Id = include.Id;

export {
  Identifier ,
  Definition ,
  IdComparator ,
  Id ,
  Comparator ,
  ofComponents ,
  ofVessel ,
  ofProcedures ,
  ofProcedure ,
  ofDeficiency ,
  withImages ,
  mainEvidence ,
  signature ,
  getPhoto ,
  getMainImage ,
  getSignature ,
  getDetailedPhotos ,
  size ,
  byProcedure ,
  byComponent ,
  bySection ,
  byDeficiency ,
  byTimestamp ,
  ids ,
  _getImages ,
  getImages ,
  lastInspection ,
  firstInspection ,
  lastInspectionDate ,
  timeSinceLastInspection ,
}
/* include Not a pure module */
