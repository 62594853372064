// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../Query.js";
import * as Browser from "../../libs/Browser.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Procedure from "../survey/Procedure.js";
import * as Inspection from "../survey/Inspection.js";
import * as ProcedureId from "../survey/ProcedureId.js";
import * as InspectionId from "../survey/InspectionId.js";
import * as NewInspection from "../survey/NewInspection.js";
import * as ProcedureUpdate from "../survey/ProcedureUpdate.js";
import * as CompanyFormSubmission from "./CompanyFormSubmission.js";
import * as CompanyFormSubmissionId from "./CompanyFormSubmissionId.js";

function _keepInspectionsNotUsedInSubmissions(submittedInspectionIds, inspection) {
  return Curry._2(Prelude.OptionExported.$$Option.keep, inspection, (function (inspection) {
                return !InspectionId.$$Set.has(submittedInspectionIds, inspection.id);
              }));
}

async function getInspectionMapsThatCanBeIncludedInSubmission(form) {
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  var match = await Query.read((newrecord.procedures = {
          TAG: "In",
          _0: form.related_procedures.map(function (param) {
                return param.procedure_id;
              })
        }, newrecord));
  var completedProcedureIds = Procedure.ids(Curry._2(Prelude.$$Array.keep, match.procedures, Procedure.isFullyInspected));
  var newrecord$1 = Caml_obj.obj_dup(Query.makeRead());
  var match$1 = await Query.read((newrecord$1.draftCompanyFormSubmissions = CompanyFormSubmission.ofForm(form.id), newrecord$1.newCompanyFormSubmissions = CompanyFormSubmission.ofForm(form.id), newrecord$1.companyFormSubmissions = CompanyFormSubmission.ofForm(form.id), newrecord$1.newInspections = NewInspection.ofProcedures(completedProcedureIds), newrecord$1.inspections = Inspection.ofProcedures(completedProcedureIds), newrecord$1));
  var draftCompanyFormSubmissions = match$1.draftCompanyFormSubmissions;
  var newCompanyFormSubmissions = match$1.newCompanyFormSubmissions;
  var companyFormSubmissions = match$1.companyFormSubmissions;
  var newInspections = ProcedureId.$$Array.getter(Prelude.$$Array.sort(match$1.newInspections, Inspection.byTimestamp, undefined), Inspection.byProcedure);
  var inspections = ProcedureId.$$Array.getter(Prelude.$$Array.sort(match$1.inspections, Inspection.byTimestamp, undefined), Inspection.byProcedure);
  var keepInspectionsNotUsedInSubmissions = function (id) {
    return _keepInspectionsNotUsedInSubmissions(InspectionId.$$Set.fromArray(Curry._1(Prelude.$$Array.concatMany, Curry._2(Prelude.$$Array.concat, Curry._2(Prelude.$$Array.concat, companyFormSubmissions, newCompanyFormSubmissions), draftCompanyFormSubmissions).map(function (submission) {
                            return submission.inspections;
                          }))), id);
  };
  var match$2 = Curry._3(Prelude.$$Array.fold, form.related_procedures.map(function (param) {
            var procedure_id = param.procedure_id;
            var newInspection = Prelude.$$Array.last(newInspections(procedure_id));
            if (newInspection !== undefined) {
              return [
                      undefined,
                      keepInspectionsNotUsedInSubmissions(newInspection)
                    ];
            } else {
              return [
                      keepInspectionsNotUsedInSubmissions(Prelude.$$Array.last(inspections(procedure_id))),
                      undefined
                    ];
            }
          }), [
        [],
        []
      ], (function (param, param$1) {
          var newInspections = param[1];
          var oldInspections = param[0];
          Curry._2(Prelude.OptionExported.$$Option.forEach, param$1[0], (function (param) {
                  oldInspections.push(param);
                }));
          Curry._2(Prelude.OptionExported.$$Option.forEach, param$1[1], (function (param) {
                  newInspections.push(param);
                }));
          return [
                  oldInspections,
                  newInspections
                ];
        }));
  return [
          ProcedureId.$$Array.group(match$2[0], Inspection.byProcedure),
          ProcedureId.$$Array.group(match$2[1], Inspection.byProcedure)
        ];
}

function getInspections(map, procedureId) {
  return Prelude.default(ProcedureId.$$Map.get(map, procedureId), []);
}

function getLastInspection(map, procedureId) {
  return Prelude.$$Array.last(getInspections(map, procedureId));
}

function countProceduresWithValidInspections(procedureIds, oldInspectionsOfProcedure, newInspectionsOfProcedure) {
  return Curry._3(Prelude.$$Array.fold, procedureIds, 0, (function (count, id) {
                if (getInspections(newInspectionsOfProcedure, id).length !== 0 || getInspections(oldInspectionsOfProcedure, id).length !== 0) {
                  return count + 1 | 0;
                } else {
                  return count;
                }
              }));
}

async function getInspectionsThatCanBeIncludedInSubmission(inspectionMaps, form) {
  var match = inspectionMaps !== undefined ? inspectionMaps : await getInspectionMapsThatCanBeIncludedInSubmission(form);
  var newInspections = match[1];
  var oldInspections = match[0];
  var hasNewInspections = !Prelude.$$Array.isEmpty(ProcedureId.$$Map.toArray(newInspections));
  return [
          Curry._2(Prelude.$$Array.keepMap, form.related_procedures, (function (param) {
                  var procedure_id = param.procedure_id;
                  return Curry._2(Prelude.OptionExported.$$Option.orElse, getLastInspection(newInspections, procedure_id), getLastInspection(oldInspections, procedure_id));
                })),
          hasNewInspections
        ];
}

async function getSubmissionCompletion(submission) {
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  var match = await Query.read((newrecord.procedureUpdates = "All", newrecord.companyForms = {
          TAG: "Get",
          _0: submission.form_id
        }, newrecord.newInspections = {
          TAG: "In",
          _0: submission.inspections
        }, newrecord.inspections = {
          TAG: "In",
          _0: submission.inspections
        }, newrecord));
  var newrecord$1 = Caml_obj.obj_dup(Query.makeRead());
  var match$1 = await Query.read((newrecord$1.procedures = {
          TAG: "In",
          _0: Prelude.$$Array.flatMap(match.companyForms, (function (form) {
                    return form.related_procedures;
                  })).map(function (rel) {
                return rel.procedure_id;
              })
        }, newrecord$1));
  var requiredProcedureIds = Curry._2(Prelude.$$Array.keep, ProcedureUpdate.apply(match.procedureUpdates, match$1.procedures), (function (p) {
            return p.not_applicable_status === undefined;
          })).map(function (p) {
        return p.id;
      });
  var inspectedProcedureIds = Curry._2(Prelude.$$Array.concat, match.inspections.map(function (param) {
            return param.procedure_id;
          }), match.newInspections.map(function (param) {
            return param.procedure_id;
          }));
  return CompanyFormSubmission.getCompletion(inspectedProcedureIds, requiredProcedureIds);
}

async function getCompletion(companyForm) {
  var match = await Query.doEach([
        {
          TAG: "Read",
          _0: (function (param) {
              var newrecord = Caml_obj.obj_dup(Query.makeRead());
              newrecord.procedureUpdates = {
                TAG: "In",
                _0: companyForm.related_procedures.map(function (p) {
                      return p.procedure_id;
                    })
              };
              newrecord.draftCompanyFormSubmissions = CompanyFormSubmission.ofForm(companyForm.id);
              newrecord.procedures = {
                TAG: "In",
                _0: companyForm.related_procedures.map(function (p) {
                      return p.procedure_id;
                    })
              };
              return newrecord;
            })
        },
        {
          TAG: "Read",
          _0: (function (param) {
              var inspectionIds = Curry._1(Prelude.$$Array.concatMany, param.draftCompanyFormSubmissions.map(function (d) {
                        return d.inspections;
                      }));
              var newrecord = Caml_obj.obj_dup(Query.makeRead());
              newrecord.newInspections = {
                TAG: "In",
                _0: inspectionIds
              };
              newrecord.inspections = {
                TAG: "In",
                _0: inspectionIds
              };
              return newrecord;
            })
        }
      ]);
  var procedures = ProcedureUpdate.apply(match.procedureUpdates, match.procedures);
  var requiredProcedureIds = procedures.map(function (p) {
        return [
                p.id,
                p.not_applicable_status === undefined
              ];
      });
  var totalToInspect = requiredProcedureIds.length;
  var procedureIsRequiredMap = ProcedureId.$$Map.fromArray(requiredProcedureIds);
  var inspectionIsValidMap = InspectionId.$$Map.fromArray(Curry._2(Prelude.$$Array.concat, Curry._2(Prelude.$$Array.keep, match.inspections, (function (i) {
                    return Prelude.default(ProcedureId.$$Map.get(procedureIsRequiredMap, i.procedure_id), false);
                  })), Curry._2(Prelude.$$Array.keep, match.newInspections, (function (i) {
                    return Prelude.default(ProcedureId.$$Map.get(procedureIsRequiredMap, i.procedure_id), false);
                  }))).map(function (i) {
            return [
                    i.id,
                    true
                  ];
          }));
  var getCompletion$1 = function (draftSubmission) {
    return {
            totalNested: totalToInspect,
            totalInspected: Curry._2(Prelude.$$Array.keep, draftSubmission.inspections, (function (id) {
                    return Curry._2(Prelude.OptionExported.$$Option.getWithDefault, InspectionId.$$Map.get(inspectionIsValidMap, id), false);
                  })).length,
            isOverdue: false
          };
  };
  return Curry._3(Prelude.$$Array.fold, match.draftCompanyFormSubmissions, {
              totalNested: 0,
              totalInspected: 0,
              isOverdue: false
            }, (function (param, submission) {
                var completion = getCompletion$1(submission);
                return {
                        totalNested: param.totalNested + completion.totalNested | 0,
                        totalInspected: param.totalInspected + completion.totalInspected | 0,
                        isOverdue: param.isOverdue || completion.isOverdue
                      };
              }));
}

async function canCreateSubmission(inspectionMaps, companyForm) {
  var match = await getInspectionsThatCanBeIncludedInSubmission(inspectionMaps, companyForm);
  if (!match[1]) {
    return "Empty";
  }
  var inspections = match[0];
  var procedureIdsWithValues = ProcedureId.$$Set.fromArray(inspections.map(Inspection.byProcedure));
  if (ProcedureId.$$Set.size(procedureIdsWithValues) === 0) {
    return "Empty";
  } else if (ProcedureId.$$Set.eq(ProcedureId.$$Set.fromArray(companyForm.related_procedures.map(function (param) {
                  return param.procedure_id;
                })), procedureIdsWithValues)) {
    return {
            TAG: "CanCreateSubmission",
            _0: inspections
          };
  } else {
    return "Incomplete";
  }
}

async function createSubmission(inspectionMaps, targetDate, companyForm) {
  var status = await canCreateSubmission(inspectionMaps, companyForm);
  if (typeof status !== "object") {
    if (status === "Incomplete") {
      return "Incomplete";
    } else {
      return "Empty";
    }
  }
  var now = new Date();
  var submission_id = CompanyFormSubmissionId.make();
  var submission_name = Prelude.default(targetDate, now).toUTCString();
  var submission_vessel_id = companyForm.vessel_id;
  var submission_form_id = companyForm.id;
  var submission_target_date = Prelude.default(targetDate, now);
  var submission_inspections = Inspection.ids(status._0);
  var submission = {
    id: submission_id,
    name: submission_name,
    vessel_id: submission_vessel_id,
    form_id: submission_form_id,
    timestamp: now,
    target_date: submission_target_date,
    inspections: submission_inspections
  };
  var newrecord = Caml_obj.obj_dup(Query.makeWrite());
  await Query.write((newrecord.newCompanyFormSubmissions = [{
            TAG: "Save",
            _0: submission
          }], newrecord));
  return {
          TAG: "Created",
          _0: submission
        };
}

async function createDraftSubmission(targetDate, form) {
  var now = new Date();
  var draft_id = CompanyFormSubmissionId.make();
  var draft_name = now.toUTCString();
  var draft_vessel_id = form.vessel_id;
  var draft_form_id = form.id;
  var draft_target_date = Prelude.default(targetDate, now);
  var draft_inspections = [];
  var match = await getInspectionsThatCanBeIncludedInSubmission(undefined, form);
  var draft_id$1 = draft_id;
  var draft_name$1 = draft_name;
  var draft_vessel_id$1 = draft_vessel_id;
  var draft_form_id$1 = draft_form_id;
  var draft_target_date$1 = draft_target_date;
  var draft_inspections$1 = Inspection.ids(match[0]);
  var draft = {
    id: draft_id$1,
    name: draft_name$1,
    vessel_id: draft_vessel_id$1,
    form_id: draft_form_id$1,
    timestamp: now,
    target_date: draft_target_date$1,
    inspections: draft_inspections$1
  };
  var newrecord = Caml_obj.obj_dup(Query.makeWrite());
  await Query.write((newrecord.draftCompanyFormSubmissions = [{
            TAG: "Save",
            _0: draft
          }], newrecord));
  return draft;
}

async function convertDraftSubmissionToSubmission(draft, allowPartialSubmission) {
  var match = await getSubmissionCompletion(draft);
  var totalInspected = match.totalInspected;
  var totalNested = match.totalNested;
  var allowCreation = Caml_obj.equal(allowPartialSubmission, true) && totalInspected >= 1 && totalInspected < totalNested ? Browser.$$Window.confirm("Uploading an Incomplete Form. Proceed?") : totalInspected === totalNested;
  if (!allowCreation) {
    return false;
  }
  var newrecord = Caml_obj.obj_dup(Query.makeWrite());
  await Query.write((newrecord.draftCompanyFormSubmissions = [{
            TAG: "Delete",
            _0: draft.id
          }], newrecord.newCompanyFormSubmissions = [{
            TAG: "Save",
            _0: draft
          }], newrecord));
  return true;
}

async function deleteDraftSubmission(draft) {
  var newrecord = Caml_obj.obj_dup(Query.makeWrite());
  await Query.write((newrecord.draftCompanyFormSubmissions = [{
            TAG: "Delete",
            _0: draft.id
          }], newrecord.newInspections = draft.inspections.map(function (id) {
              return {
                      TAG: "Delete",
                      _0: id
                    };
            }), newrecord));
  return true;
}

export {
  _keepInspectionsNotUsedInSubmissions ,
  getInspectionMapsThatCanBeIncludedInSubmission ,
  getInspections ,
  getLastInspection ,
  countProceduresWithValidInspections ,
  getInspectionsThatCanBeIncludedInSubmission ,
  getSubmissionCompletion ,
  getCompletion ,
  canCreateSubmission ,
  createSubmission ,
  createDraftSubmission ,
  convertDraftSubmissionToSubmission ,
  deleteDraftSubmission ,
}
/* Query Not a pure module */
