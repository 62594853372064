// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReIndexed from "@kaiko.io/rescript-reindexed/lib/es6/src/ReIndexed.js";
import * as ProcedureId from "./ProcedureId.js";

var Definition = {};

var include = ReIndexed.MakeIdModel(Definition, {});

function ofVessel(vesselId) {
  return {
          TAG: "Is",
          _0: "vessel_id",
          _1: vesselId
        };
}

function make(vessel_id, procedure_id, not_applicable_status) {
  return {
          id: procedure_id,
          vessel_id: vessel_id,
          not_applicable_status: not_applicable_status
        };
}

function apply(updates, procedures) {
  var index = ProcedureId.$$Map.fromArray(updates.map(function (u) {
            return [
                    u.id,
                    u
                  ];
          }));
  return procedures.map(function (p) {
              var match = ProcedureId.$$Map.get(index, p.id);
              if (match === undefined) {
                return p;
              }
              var newrecord = Caml_obj.obj_dup(p);
              newrecord.not_applicable_status = match.not_applicable_status;
              return newrecord;
            });
}

var Identifier;

var IdComparator = include.IdComparator;

var Id = include.Id;

export {
  Identifier ,
  Definition ,
  IdComparator ,
  Id ,
  ofVessel ,
  make ,
  apply ,
}
/* include Not a pure module */
