// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as Rating from "../Rating.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Procedure from "../Procedure.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as QuestionType from "../QuestionType.js";
import * as ProcedureAnswer from "../ProcedureAnswer.js";

function setAdditionalAnnotation(vnode, _event) {
  var annotation = Prelude.default(Mithril.$$Element.value(Mithril.$$Element.select(vnode, "[name=additional_annotation]")), "");
  vnode.attrs.setAdditionalAnnotation(annotation);
}

function setDeadline(vnode, _event) {
  var deadline = Curry._2(Prelude.OptionExported.$$Option.flatMap, Mithril.$$Element.value(Mithril.$$Element.select(vnode, "[name=deadline]")), (function (d) {
          if (d === "") {
            return ;
          } else {
            return Caml_option.some(new Date(d));
          }
        }));
  vnode.attrs.setDeadline(deadline);
}

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var vessel = vnode.attrs.vessel;
                var inspection = vnode.attrs.inspection;
                var component = vnode.attrs.component;
                var inspectionRate = QuestionType.Value.rate(inspection.value);
                var hasLowRate = inspectionRate === 1 || inspectionRate === 2;
                if (inspectionRate === 0 || !(!vessel.hide_observations || vnode.attrs.isRectification)) {
                  return Mithril.empty;
                } else {
                  return ReactDOM.jsxs("div", {
                              children: [
                                ReactDOM.jsx("div", {
                                      className: "column col-1"
                                    }),
                                ReactDOM.jsxs("div", {
                                      children: [
                                        component.suggested_annotations.map(function (annotation) {
                                              return ReactDOM.jsxKeyed("div", {
                                                          children: ReactDOM.jsxs("label", {
                                                                children: [
                                                                  ReactDOM.jsx("input", {
                                                                        checked: Prelude.$$Array.includes(inspection.annotations, annotation),
                                                                        type: "checkbox",
                                                                        onChange: (function (param) {
                                                                            vnode.attrs.toggleAnnotation(annotation);
                                                                          })
                                                                      }),
                                                                  ReactDOM.jsx("i", {
                                                                        className: "form-icon"
                                                                      }),
                                                                  annotation
                                                                ],
                                                                className: "form-checkbox"
                                                              }),
                                                          className: "form-group"
                                                        }, annotation, undefined);
                                            }),
                                        vessel.mandatory_findings_deadline && hasLowRate ? ReactDOM.jsxs("div", {
                                                children: [
                                                  ReactDOM.jsxs("label", {
                                                        children: [
                                                          "What is the deadline for this finding?",
                                                          ReactDOM.jsx("span", {
                                                                children: "Required",
                                                                className: "text-error text-italic ml-2"
                                                              })
                                                        ],
                                                        className: "form-label",
                                                        htmlFor: "inspection-deadline"
                                                      }),
                                                  ReactDOM.jsx("input", {
                                                        className: "form-input",
                                                        id: "inspection-deadline",
                                                        name: "deadline",
                                                        type: "date",
                                                        onChange: (function (extra) {
                                                            return setDeadline(vnode, extra);
                                                          })
                                                      })
                                                ],
                                                className: "form-group mt-2"
                                              }) : Mithril.empty,
                                        ReactDOM.jsxs("div", {
                                              children: [
                                                ReactDOM.jsxs("label", {
                                                      children: [
                                                        "Observations",
                                                        vessel.mandatory_findings_observations ? ReactDOM.jsx("span", {
                                                                children: "Required",
                                                                className: "text-error text-italic ml-2"
                                                              }) : Mithril.empty
                                                      ],
                                                      className: "form-label",
                                                      htmlFor: "inspection-additional-annotation"
                                                    }),
                                                ReactDOM.jsx("textarea", {
                                                      className: "form-input",
                                                      id: "inspection-additional-annotation",
                                                      name: "additional_annotation",
                                                      placeholder: "Please enter your observations",
                                                      value: inspection.additional_annotation,
                                                      onInput: (function (extra) {
                                                          return setAdditionalAnnotation(vnode, extra);
                                                        })
                                                    })
                                              ],
                                              className: "form-group mt-2"
                                            })
                                      ],
                                      className: "column col-mx-auto"
                                    }),
                                ReactDOM.jsx("div", {
                                      className: "column col-1"
                                    })
                              ],
                              className: "columns t-rating-editor"
                            });
                }
              }));
}

var RatingReasonEditor = {
  setAdditionalAnnotation: setAdditionalAnnotation,
  setDeadline: setDeadline,
  make: make
};

function make$1(param) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), (function (vnode) {
                    var inspection = vnode.attrs.inspection;
                    var newrecord = Caml_obj.obj_dup(Query.makeRead());
                    Prelude.thenDo(Query.read((newrecord.procedures = {
                                TAG: "Get",
                                _0: inspection.procedure_id
                              }, newrecord.components = {
                                TAG: "Get",
                                _0: inspection.component_id
                              }, newrecord.sections = {
                                TAG: "Get",
                                _0: inspection.section_id
                              }, newrecord)), (function (param) {
                            var match = Prelude.$$Array.first(param.sections);
                            var match$1 = Prelude.$$Array.first(param.components);
                            var match$2 = Prelude.$$Array.first(param.procedures);
                            if (match !== undefined && match$1 !== undefined && match$2 !== undefined) {
                              return Mithril.setState(vnode, {
                                          TAG: "Location",
                                          _0: match,
                                          _1: match$1,
                                          _2: match$2
                                        });
                            }
                            
                          }));
                  })), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                }
                var procedure = match._2;
                var component = match._1;
                var section = match._0;
                if (Prelude.default(vnode.attrs.small, true)) {
                  return ReactDOM.jsxs("div", {
                              children: [
                                ReactDOM.jsx("div", {
                                      children: ReactDOM.jsxs("small", {
                                            children: [
                                              ReactDOM.jsx("strong", {
                                                    children: "Location: "
                                                  }),
                                              section.name + " / " + component.name
                                            ]
                                          })
                                    }),
                                ReactDOM.jsx("div", {
                                      children: ReactDOM.jsxs("small", {
                                            children: [
                                              ReactDOM.jsx("strong", {
                                                    children: "Procedure: "
                                                  }),
                                              procedure.name
                                            ]
                                          })
                                    })
                              ]
                            });
                } else {
                  return ReactDOM.jsxs("div", {
                              children: [
                                ReactDOM.jsxs("div", {
                                      children: [
                                        ReactDOM.jsx("strong", {
                                              children: "Location: "
                                            }),
                                        ReactDOM.jsx("small", {
                                              children: section.name + " / " + component.name
                                            })
                                      ]
                                    }),
                                ReactDOM.jsxs("div", {
                                      children: [
                                        ReactDOM.jsx("strong", {
                                              children: "Procedure: "
                                            }),
                                        ReactDOM.jsx("small", {
                                              children: procedure.name
                                            })
                                      ]
                                    })
                              ]
                            });
                }
              }));
}

var InspectionLocation = {
  make: make$1
};

var styleFloatRight = {
  left: "0.2rem",
  position: "absolute",
  top: "0.2rem"
};

var noStyle = {};

function make$2(param) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), (function (vnode) {
                    Prelude.thenDo(Prelude.thenResolve(Query.doEach([
                                  {
                                    TAG: "Read",
                                    _0: (function (param) {
                                        var newrecord = Caml_obj.obj_dup(Query.makeRead());
                                        newrecord.procedures = {
                                          TAG: "Get",
                                          _0: vnode.attrs.inspection.procedure_id
                                        };
                                        return newrecord;
                                      })
                                  },
                                  {
                                    TAG: "Read",
                                    _0: (function (param) {
                                        var newrecord = Caml_obj.obj_dup(Query.makeRead());
                                        newrecord.ratingStylingRules = Procedure.getRatingStyleRules(Prelude.$$Array.first(param.procedures));
                                        return newrecord;
                                      })
                                  }
                                ]), (function (param) {
                                var procedure = Prelude.$$Array.first(param.procedures);
                                if (procedure !== undefined) {
                                  return {
                                          TAG: "ProcedureType",
                                          _0: procedure.question_type,
                                          _1: Curry._2(Prelude.OptionExported.$$Option.map, Prelude.$$Array.first(param.ratingStylingRules), (function (param) {
                                                  return param.rules;
                                                }))
                                        };
                                } else {
                                  return "ProcedureNotFound";
                                }
                              })), (function (extra) {
                            return Mithril.setState(vnode, extra);
                          }));
                  })), (function (vnode) {
                var inspection = vnode.attrs.inspection;
                var className = Prelude.default(vnode.attrs.className, "");
                var style = Prelude.default(vnode.attrs.floatRight, false) ? styleFloatRight : noStyle;
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                }
                var match$1 = match._0;
                if (match$1 === "RatingQuestion") {
                  var desc = Rating.describe(QuestionType.Value.rate(inspection.value), match._1);
                  return ReactDOM.jsx("span", {
                              children: desc.name,
                              className: "label label-" + desc.color + " " + className,
                              style: style
                            });
                }
                if (match$1 === "SignatureQuestion" || match$1 === "PhotoAlbumQuestion" || match$1 === "DateQuestion" || match$1 === "TextQuestion" || match$1 === "YesNoQuestion") {
                  return Mithril.empty;
                }
                var desc$1 = ProcedureAnswer.description(QuestionType.Value.getAnswer(inspection.value));
                return ReactDOM.jsx("span", {
                            children: desc$1.text,
                            className: "label label-" + desc$1.color + " " + className,
                            style: style
                          });
              }));
}

var ProcedureValueLabel = {
  styleFloatRight: styleFloatRight,
  noStyle: noStyle,
  make: make$2
};

export {
  RatingReasonEditor ,
  InspectionLocation ,
  ProcedureValueLabel ,
}
/* Query Not a pure module */
