// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Level from "./Level.js";
import * as Query from "../Query.js";
import * as Answer from "./Answer.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Question from "./Question.js";
import * as QuestionId from "./QuestionId.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as LevelSubmission from "./LevelSubmission.js";
import * as LevelSubmissionId from "./LevelSubmissionId.js";

function getAnswers(map, questionId) {
  return Prelude.default(QuestionId.$$Map.get(map, questionId), []);
}

function getLastAnswer(map, questionId) {
  return Prelude.$$Array.last(getAnswers(map, questionId));
}

function getAnswersThatCanBeIncludedInSubmission(level, param) {
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  return Prelude.thenResolve(Query.read((newrecord.newAnswers = Answer.insideLevel(level.path), newrecord.answers = Answer.insideLevel(level.path), newrecord)), (function (param) {
                return Curry._2(Prelude.$$Array.keep, Curry._2(Prelude.$$Array.concat, param.answers, param.newAnswers), (function (answer) {
                              var due_date = level.due_date;
                              if (due_date !== undefined) {
                                return Caml_obj.lessequal(answer.timestamp, Caml_option.valFromOption(due_date));
                              } else {
                                return true;
                              }
                            }));
              }));
}

function isCompleted(targetDateOpt, submission, level) {
  var targetDate = targetDateOpt !== undefined ? Caml_option.valFromOption(targetDateOpt) : undefined;
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  return Prelude.thenResolve(Query.read((newrecord.newAnswers = Answer.insideLevel(level.path), newrecord.answers = Answer.insideLevel(level.path), newrecord.questions = Question.insideLevel(level.path), newrecord)), (function (param) {
                var submissionAnswers = Curry._2(Prelude.$$Array.keep, Curry._2(Prelude.$$Array.concat, param.answers, param.newAnswers), (function (a) {
                        return Prelude.$$Array.isEmpty(Curry._2(Prelude.$$Array.keep, submission.answers, (function (sa) {
                                          return Caml_obj.equal(sa, a.id);
                                        }))) === false;
                      }));
                var tmp;
                if (targetDate !== undefined) {
                  var date = Caml_option.valFromOption(targetDate);
                  tmp = Curry._2(Prelude.$$Array.keep, submissionAnswers, (function (a) {
                          return Caml_obj.lessequal(a.timestamp, date);
                        })).length;
                } else {
                  tmp = submissionAnswers.length;
                }
                return param.questions.length === tmp;
              }));
}

function getCompletion(level) {
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  return Prelude.thenResolve(Query.read((newrecord.newAnswers = Answer.insideLevel(level.path), newrecord.answers = Answer.insideLevel(level.path), newrecord.questions = Question.insideLevel(level.path), newrecord)), (function (param) {
                return {
                        totalNested: param.questions.length,
                        totalInspected: Curry._2(Prelude.$$Array.concat, param.answers, param.newAnswers).length,
                        isOverdue: false
                      };
              }));
}

function createDraftSubmission(level, targetDateOpt, param) {
  var targetDate = targetDateOpt !== undefined ? Caml_option.valFromOption(targetDateOpt) : undefined;
  var now = new Date();
  var draftSubmission_id = LevelSubmissionId.make();
  var draftSubmission_name = now.toUTCString();
  var draftSubmission_vessel_id = level.vessel_id;
  var draftSubmission_level_id = level.id;
  var draftSubmission_target_date = Prelude.default(targetDate, now);
  var draftSubmission_answers = [];
  var draftSubmission_recurrence = level.recurrence;
  var draftSubmission = {
    id: draftSubmission_id,
    name: draftSubmission_name,
    vessel_id: draftSubmission_vessel_id,
    level_id: draftSubmission_level_id,
    timestamp: now,
    target_date: draftSubmission_target_date,
    answers: draftSubmission_answers,
    recurrence: draftSubmission_recurrence,
    isCompleted: false
  };
  return Prelude.thenResolve(Prelude.thenResolve(getAnswersThatCanBeIncludedInSubmission(level, undefined), (function (answersReadyForSubmission) {
                    var newrecord = Caml_obj.obj_dup(Query.makeWrite());
                    return Query.write((newrecord.draftLevelSubmissions = [{
                                    TAG: "Save",
                                    _0: {
                                      id: draftSubmission_id,
                                      name: draftSubmission_name,
                                      vessel_id: draftSubmission_vessel_id,
                                      level_id: draftSubmission_level_id,
                                      timestamp: now,
                                      target_date: draftSubmission_target_date,
                                      answers: Answer.ids(answersReadyForSubmission),
                                      recurrence: draftSubmission_recurrence,
                                      isCompleted: false
                                    }
                                  }], newrecord));
                  })), (function (param) {
                return draftSubmission;
              }));
}

function deleteDraftSubmission(draftSubmission) {
  var newrecord = Caml_obj.obj_dup(Query.makeWrite());
  return Prelude.thenResolve(Query.write((newrecord.newAnswers = draftSubmission.answers.map(function (id) {
                        return {
                                TAG: "Delete",
                                _0: id
                              };
                      }), newrecord.draftLevelSubmissions = [{
                      TAG: "Delete",
                      _0: draftSubmission.id
                    }], newrecord)), (function (param) {
                return true;
              }));
}

function addAnswerToDraftSubmission(draftSubmissionId, level, answer) {
  var submissionId = Prelude.default(draftSubmissionId, "");
  var root_path = level.path.slice(0, Level.steplen);
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  Prelude.thenDo(Query.read((newrecord.questions = Question.insideLevel(root_path), newrecord.draftLevelSubmissions = {
              TAG: "Get",
              _0: submissionId
            }, newrecord)), (function (param) {
          var submission = Prelude.$$Array.first(param.draftLevelSubmissions);
          if (submission === undefined) {
            return ;
          }
          var answers = Curry._2(Prelude.$$Array.concat, submission.answers, [answer.id]);
          var newrecord = Caml_obj.obj_dup(Query.makeWrite());
          Prelude.thenDo(Query.write((newrecord.draftLevelSubmissions = [{
                        TAG: "Save",
                        _0: {
                          id: submission.id,
                          name: submission.name,
                          vessel_id: submission.vessel_id,
                          level_id: submission.level_id,
                          timestamp: submission.timestamp,
                          target_date: submission.target_date,
                          answers: answers,
                          recurrence: submission.recurrence,
                          isCompleted: param.questions.length <= answers.length
                        }
                      }], newrecord)), (function (x) {
                  console.log("LevelSubmissionManager", x);
                }));
        }));
}

function isAnswerSubmitted(levelId, answer) {
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  return Prelude.thenResolve(Query.read((newrecord.draftLevelSubmissions = LevelSubmission.ofLevel(levelId), newrecord.newLevelSubmissions = LevelSubmission.ofLevel(levelId), newrecord.levelSubmissions = LevelSubmission.ofLevel(levelId), newrecord)), (function (param) {
                return Prelude.$$Array.isEmpty(Curry._2(Prelude.$$Array.keep, Curry._2(Prelude.$$Array.concat, Curry._2(Prelude.$$Array.concat, param.draftLevelSubmissions, param.newLevelSubmissions), param.levelSubmissions), (function (submission) {
                                  return Prelude.$$Array.isEmpty(Curry._2(Prelude.$$Array.keep, submission.answers, (function (a) {
                                                    return Caml_obj.equal(a, answer.id);
                                                  })));
                                })));
              }));
}

export {
  getAnswers ,
  getLastAnswer ,
  getAnswersThatCanBeIncludedInSubmission ,
  isCompleted ,
  getCompletion ,
  createDraftSubmission ,
  deleteDraftSubmission ,
  addAnswerToDraftSubmission ,
  isAnswerSubmitted ,
}
/* Level Not a pure module */
