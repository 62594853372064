// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Feather from "./Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.js";
import * as KeepScrollLink from "../../../libs/KeepScrollLink.js";
import * as SessionSidebar from "../../accounts/components/SessionSidebar.js";
import FullLogoPng from "/images/full-logo.png";

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var className = Core__Option.getOr(vnode.attrs.className, "tw-text-primary tw-p-0 tw-flex tw-items-center tw-justify-center tw-min-w-[35px]");
                var disabled = vnode.attrs.disabled;
                var steps = Math.abs(Core__Option.getOr(vnode.attrs.steps, 1));
                return React.jsx(KeepScrollLink.make, {
                            className: className,
                            disabled: disabled,
                            children: Caml_option.some(React.jsx(Feather.make, {
                                      icon: "arrow_left"
                                    })),
                            onClick: (function ($$event) {
                                $$event.preventDefault();
                                if (!Core__Option.getOr(disabled, false)) {
                                  window.history.go(Math.imul(-1, steps));
                                  return ;
                                }
                                
                              })
                          });
              }));
}

function toElement(style) {
  if (typeof style === "object") {
    return style.VAL;
  } else if (style === "BackButton") {
    return React.jsx(make, {});
  } else {
    return Mithril.empty;
  }
}

function toElement$1(style) {
  if (typeof style === "object") {
    return style.VAL;
  } else if (style === "Avatar") {
    return React.jsx(SessionSidebar.make, {});
  } else {
    return Mithril.empty;
  }
}

function toElement$2(style) {
  if (typeof style === "object") {
    if (style.NAME === "Title") {
      return ReactDOM.jsx("h4", {
                  children: style.VAL,
                  className: Mithril.classList([
                        "tw-flex tw-flex-grow tw-items-center tw-justify-center tw-mb-0",
                        "tw-truncate tw-px-2"
                      ], undefined)
                });
    } else {
      return style.VAL;
    }
  } else if (style === "Logo") {
    return ReactDOM.jsx("img", {
                className: "tw-py-1 tw-max-h-[3.5rem] tw-min-h-[2.5rem] ",
                src: FullLogoPng
              });
  } else {
    return Mithril.empty;
  }
}

function make$1(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var className = vnode.attrs.className;
                var left = Core__Option.getOr(vnode.attrs.left, "Empty");
                var right = Core__Option.getOr(vnode.attrs.right, "Empty");
                var content = Core__Option.getOr(vnode.attrs.content, "Logo");
                var fixed = Core__Option.getOr(vnode.attrs.fixed, false);
                var noClassName = Core__Option.isNone(className);
                return ReactDOM.jsxs("header", {
                            children: [
                              toElement(left),
                              ReactDOM.jsx("div", {
                                    children: toElement$2(content),
                                    className: "tw-flex-grow tw-flex tw-items-center tw-justify-center"
                                  }),
                              toElement$1(right)
                            ],
                            className: Mithril.$$class([
                                  [
                                    "t-app-header",
                                    true
                                  ],
                                  [
                                    "tw-fixed tw-top-0",
                                    fixed
                                  ],
                                  [
                                    "tw-w-full tw-h-header tw-min-h-header tw-max-h-header",
                                    noClassName
                                  ],
                                  [
                                    "tw-bg-white tw-shadow-header tw-px-4 tw-z-[1000]",
                                    noClassName
                                  ],
                                  [
                                    "tw-flex tw-flex-row tw-items-center tw-justify-between",
                                    noClassName
                                  ]
                                ], className)
                          });
              }));
}

var Left = {};

var Right = {};

var Content = {};

export {
  Left ,
  Right ,
  Content ,
  make$1 as make,
}
/* React Not a pure module */
