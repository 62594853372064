// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Comment from "./Comment.js";
import * as ReIndexed from "@kaiko.io/rescript-reindexed/lib/es6/src/ReIndexed.js";

var Definition = {};

var include = ReIndexed.MakeIdModel(Definition, {});

function make(id, reader) {
  return Promise.resolve({
              id: id,
              reader: reader
            });
}

var IdComparator = include.IdComparator;

var Id = include.Id;

export {
  Definition ,
  IdComparator ,
  Id ,
  make ,
}
/* include Not a pure module */
