// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../URLs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Rating from "../Rating.js";
import * as Vessel from "../../vessel/Vessel.js";
import * as Feather from "../../common/views/Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril$1 from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Procedure from "../Procedure.js";
import * as Inspection from "../Inspection.js";
import * as ListLayout from "../../common/views/ListLayout.js";
import * as LocalImage from "../../../libs/LocalImage.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ProcedureId from "../ProcedureId.js";
import * as TakePicture from "../../common/views/TakePicture.js";
import MarkdownIt from "markdown-it";
import * as QuestionType from "../QuestionType.js";
import * as NewInspection from "../NewInspection.js";
import * as KeepScrollLink from "../../../libs/KeepScrollLink.js";
import * as Mithril__Route from "../../../libs/rescript-mithril/src/Mithril__Route.js";
import * as NavigationPills from "../../common/views/NavigationPills.js";
import * as ProcedureAnswer from "../ProcedureAnswer.js";
import * as ConditionMatcher from "../../common/ConditionMatcher.js";
import * as ProtectedViewMaker from "../../accounts/views/ProtectedViewMaker.js";
import * as State__Inspections from "../../state/State__Inspections.js";

function guidancePictures(procedure, positionDiagram, exampleImage, lastPhoto) {
  var tmp;
  var exit = 0;
  var example;
  if (exampleImage !== undefined) {
    example = exampleImage;
    exit = 1;
  } else if (lastPhoto !== undefined) {
    example = lastPhoto;
    exit = 1;
  } else {
    tmp = Mithril.empty;
  }
  if (exit === 1) {
    tmp = ReactDOM.jsxs("div", {
          children: [
            ReactDOM.jsx("h4", {
                  children: "Example",
                  className: "text-center"
                }),
            ReactDOM.jsx("div", {
                  className: "divider blank"
                }),
            ReactDOM.jsxs("figure", {
                  children: [
                    React.jsx(LocalImage.Resource.make, {
                          resource: example.id,
                          maximize: true
                        }),
                    ReactDOM.jsx("div", {
                          className: "divider blank xl"
                        }),
                    ReactDOM.jsx("figcaption", {
                          children: Mithril$1.trust(new MarkdownIt().render(Prelude.$$String.$$default(procedure.example_image_desc, "The photo should look like this"))),
                          className: "figure-caption"
                        })
                  ],
                  className: "figure"
                }),
            ReactDOM.jsx("div", {
                  className: "divider blank xl"
                })
          ]
        });
  }
  return ReactDOM.jsxs("div", {
              children: [
                ReactDOM.jsx("div", {
                      className: "divider blank"
                    }),
                positionDiagram !== undefined ? ReactDOM.jsxs("div", {
                        children: [
                          ReactDOM.jsx("h4", {
                                children: "Positioning",
                                className: "text-center t-image-positioning-text"
                              }),
                          ReactDOM.jsx("div", {
                                className: "divider blank"
                              }),
                          ReactDOM.jsxs("figure", {
                                children: [
                                  React.jsx(LocalImage.Resource.make, {
                                        resource: positionDiagram.id,
                                        className: "img-responsive img-fit-contain p-centered",
                                        maximize: true
                                      }),
                                  ReactDOM.jsx("div", {
                                        className: "divider blank xl"
                                      }),
                                  ReactDOM.jsx("figcaption", {
                                        children: Mithril$1.trust(new MarkdownIt().render(Prelude.$$String.$$default(procedure.position_diagram_desc, "Position yourself like in the picture"))),
                                        className: "figure-caption"
                                      })
                                ],
                                className: "figure"
                              }),
                          ReactDOM.jsx("div", {
                                className: "divider blank xl"
                              })
                        ]
                      }) : Mithril.empty,
                Prelude.$$String.hasText(procedure.description) ? ReactDOM.jsxs("div", {
                        children: [
                          ReactDOM.jsx("div", {
                                children: ReactDOM.jsx("div", {
                                      children: ReactDOM.jsx("p", {
                                            children: Mithril$1.trust(new MarkdownIt().render(Prelude.default(procedure.description, ""))),
                                            className: "mb-0 pl-2 pr-2"
                                          }),
                                      className: "tile-content pt-0"
                                    }),
                                className: "tile tile-centered"
                              }),
                          ReactDOM.jsx("div", {
                                className: "divider blank xl"
                              })
                        ]
                      }) : Mithril.empty,
                tmp
              ]
            });
}

function title(state) {
  if (typeof state !== "object") {
    return "";
  }
  switch (state.defaultRating) {
    case 0 :
        return state.procedure.name;
    case 2 :
        return "Oops!";
    default:
      return "Checking...";
  }
}

async function load(vnode) {
  var procedureId = ProcedureId.Route.param("procedureId");
  var isRectification = Mithril__Route.paramBool("isRectification");
  var back = Prelude.default(Mithril__Route.paramInt("back"), -2);
  var rating = Prelude.default(Prelude.maybe(Mithril__Route.paramInt("rating"), Rating.fromJs), 0);
  var picturePrompted = Mithril__Route.paramBool("picturePrompted");
  var match = await Query.doEach([
        {
          TAG: "Read",
          _0: (function (param) {
              var newrecord = Caml_obj.obj_dup(Query.makeRead());
              newrecord.procedures = {
                TAG: "Get",
                _0: procedureId
              };
              return newrecord;
            })
        },
        {
          TAG: "Read",
          _0: (function (param) {
              var query = Query.makeRead();
              return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Prelude.$$Array.first(param.procedures), (function (p) {
                                var newrecord = Caml_obj.obj_dup(query);
                                newrecord.ratingStylingRules = Procedure.getRatingStyleRules(p);
                                newrecord.newInspections = NewInspection.ofProcedure(procedureId);
                                newrecord.inspections = Inspection.ofProcedure(procedureId);
                                newrecord.components = {
                                  TAG: "Get",
                                  _0: p.component_id
                                };
                                newrecord.vessels = {
                                  TAG: "Get",
                                  _0: p.vessel_id
                                };
                                return newrecord;
                              })), query);
            })
        }
      ]);
  var inspections = match.inspections;
  var procedures = match.procedures;
  var components = match.components;
  var vessels = match.vessels;
  if (vessels.length !== 1) {
    window.history.back();
    return ;
  }
  var vessel = vessels[0];
  if (procedures.length !== 1) {
    window.history.back();
    return ;
  }
  var procedure = procedures[0];
  if (components.length !== 1) {
    window.history.back();
    return ;
  }
  var component = components[0];
  var lastInspection = Prelude.$$Array.last(Prelude.$$Array.sort(Curry._2(Prelude.$$Array.concat, inspections, match.newInspections), Inspection.byTimestamp, undefined));
  var isRectification$1 = Prelude.default(isRectification, Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, lastInspection, (function (i) {
                  return NewInspection.isDeficient(i);
                })), false));
  var match$1 = procedure.question_type;
  var match$2;
  if (match$1 === "RatingQuestion") {
    match$2 = await ConditionMatcher.RatingProcedure.match(procedure);
  } else if (match$1 === "OkNotOkQuestion") {
    var previousAnswer = Curry._2(Prelude.OptionExported.$$Option.flatMap, Curry._2(Prelude.OptionExported.$$Option.flatMap, lastInspection, (function (i) {
                return i.value;
              })), (function (value) {
            if (typeof value === "object" && value.NAME === "OkNotOk") {
              if (value.VAL) {
                return ProcedureAnswer.fromRating(5);
              } else {
                return ProcedureAnswer.fromRating(1);
              }
            }
            
          }));
    var currentAnswer = isRectification$1 ? ProcedureAnswer.fromRating(5) : ProcedureAnswer.fromRating(rating);
    match$2 = await ConditionMatcher.OkNotOkProcedure.match(procedure, previousAnswer, currentAnswer);
  } else if (match$1 === "PhotoAlbumQuestion") {
    match$2 = [
      true,
      false
    ];
  } else {
    console.warn("StartInspectionProcedure", "Unexpected kind of procedure", procedure.question_type);
    match$2 = [
      false,
      false
    ];
  }
  if (match$2[0]) {
    var inspections$1 = Curry._2(Prelude.$$Array.keep, inspections, Inspection.withImages);
    var diagram = procedure.position_diagram;
    var example = procedure.example_image;
    var lastInspection$1 = Inspection.lastInspection(inspections$1);
    var lastPhoto = Curry._2(Prelude.OptionExported.$$Option.flatMap, lastInspection$1, (function (inspection) {
            return Curry._2(Prelude.OptionExported.$$Option.map, Inspection.mainEvidence(inspection), (function (attachment) {
                          return {
                                  id: attachment.id,
                                  file: attachment.file,
                                  thumbnail: attachment.thumbnail
                                };
                        }));
          }));
    return Mithril.setState(vnode, {
                TAG: "Loaded",
                back: back,
                defaultRating: rating,
                picturePrompted: Prelude.default(picturePrompted, false),
                vessel: vessel,
                procedure: procedure,
                ratingStylingRules: Curry._2(Prelude.OptionExported.$$Option.map, Prelude.$$Array.first(match.ratingStylingRules), (function (param) {
                        return param.rules;
                      })),
                component: component,
                positionDiagram: diagram,
                exampleImage: example,
                lastInspection: lastInspection$1,
                lastPhoto: lastPhoto,
                theCameraFailed: false,
                isRectification: isRectification$1
              });
  }
  var match$3 = procedure.question_type;
  var value = match$3 === "RatingQuestion" ? ({
        NAME: "Rating",
        VAL: rating
      }) : (
      match$3 === "OkNotOkQuestion" ? ({
            NAME: "OkNotOk",
            VAL: rating === 5
          }) : (
          match$3 === "PhotoAlbumQuestion" ? ({
                NAME: "PhotoAlbum",
                VAL: true
              }) : undefined
        )
    );
  if (value === undefined) {
    return ;
  }
  var match$4 = await State__Inspections.make(procedure, undefined, undefined, value, undefined, true, undefined, undefined, undefined, undefined);
  return Prelude.OptionExported.$$Option.$$do(match$4[1], (function (param) {
                KeepScrollLink.saveScroll();
                Mithril$1.route.set(URLs.editInspection(param.id), {
                      back: back,
                      currentStep: 2,
                      stepCount: 0,
                      isRectification: isRectification$1
                    });
              }));
}

function make(param) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), (function (v) {
                    load(v);
                  })), (function (vnode) {
                var match = vnode.state.data;
                var tmp;
                if (typeof match !== "object") {
                  tmp = Mithril.empty;
                } else {
                  var lastInspection = match.lastInspection;
                  var component = match.component;
                  var procedure = match.procedure;
                  var guidance = guidancePictures(procedure, match.positionDiagram, match.exampleImage, match.lastPhoto);
                  var match$1 = procedure.question_type;
                  var stepCount = match$1 === "PhotoAlbumQuestion" ? 1 : 2;
                  var match$2 = procedure.question_type;
                  var tmp$1;
                  if (match$2 === "SignatureQuestion" || match$2 === "DateQuestion" || match$2 === "TextQuestion" || match$2 === "YesNoQuestion" || match$2 === "WeightDeltaQuestion") {
                    tmp$1 = Mithril.empty;
                  } else if (match$2 === "OkNotOkQuestion") {
                    var match$3 = ProcedureAnswer.fromRating(match.defaultRating);
                    switch (match$3) {
                      case "Yes" :
                          tmp$1 = ReactDOM.jsxs("div", {
                                children: [
                                  ReactDOM.jsx("div", {
                                        className: "divider blank"
                                      }),
                                  ReactDOM.jsx("h4", {
                                        children: "You indicated that this item is OK.",
                                        className: "text-center"
                                      }),
                                  ReactDOM.jsx("div", {
                                        className: "divider blank xl"
                                      }),
                                  ReactDOM.jsx("h5", {
                                        children: procedure.name
                                      }),
                                  ReactDOM.jsx("div", {
                                        className: "divider blank xl"
                                      }),
                                  ReactDOM.jsxs("div", {
                                        children: [
                                          React.jsx(Feather.make, {
                                                icon: "info",
                                                style: "margin-bottom: -.2rem; margin-right: .2rem;",
                                                size: 22
                                              }),
                                          "Please take a picture to confirm and continue your inspection."
                                        ],
                                        className: "text-warning text-small text-center p-centerd",
                                        style: {
                                          lineHeight: "1rem"
                                        }
                                      }),
                                  guidance
                                ]
                              });
                          break;
                      case "No" :
                          tmp$1 = ReactDOM.jsxs("div", {
                                children: [
                                  ReactDOM.jsx("div", {
                                        className: "divider blank"
                                      }),
                                  ReactDOM.jsx("h4", {
                                        children: "You identified a finding regarding the " + component.name + ".",
                                        className: "t-deficiency-message"
                                      }),
                                  ReactDOM.jsx("div", {
                                        className: "divider blank xl"
                                      }),
                                  ReactDOM.jsx("h5", {
                                        children: procedure.name
                                      }),
                                  ReactDOM.jsx("div", {
                                        className: "divider blank xl"
                                      }),
                                  ReactDOM.jsxs("div", {
                                        children: [
                                          React.jsx(Feather.make, {
                                                icon: "info",
                                                style: "margin-bottom: -.2rem; margin-right: .2rem;",
                                                size: 22
                                              }),
                                          "Please take a picture of the finding."
                                        ],
                                        className: "text-warning text-small text-center p-centerd"
                                      }),
                                  guidance
                                ]
                              });
                          break;
                      case "Unknown" :
                          tmp$1 = ReactDOM.jsxs("div", {
                                children: [
                                  ReactDOM.jsx("div", {
                                        className: "divider blank"
                                      }),
                                  ReactDOM.jsx("h4", {
                                        children: "You are resolving a finding regarding the " + component.name + ".",
                                        className: "t-deficiency-message"
                                      }),
                                  ReactDOM.jsx("div", {
                                        className: "divider blank xl"
                                      }),
                                  ReactDOM.jsx("h5", {
                                        children: procedure.name
                                      }),
                                  ReactDOM.jsx("div", {
                                        className: "divider blank xl"
                                      }),
                                  ReactDOM.jsxs("div", {
                                        children: [
                                          React.jsx(Feather.make, {
                                                icon: "info",
                                                style: "margin-bottom: -.2rem; margin-right: .2rem;",
                                                size: 22
                                              }),
                                          "Please take a picture of the finding."
                                        ],
                                        className: "text-warning text-small text-center p-centerd"
                                      }),
                                  guidance
                                ]
                              });
                          break;
                      
                    }
                  } else if (match$2 === "PhotoAlbumQuestion") {
                    tmp$1 = ReactDOM.jsxs("div", {
                          children: [
                            ReactDOM.jsx("div", {
                                  className: "divider blank xl"
                                }),
                            ReactDOM.jsxs("div", {
                                  children: [
                                    React.jsx(Feather.make, {
                                          icon: "info",
                                          style: "margin-bottom: -.2rem; margin-right: .2rem;",
                                          size: 22
                                        }),
                                    "Please take pictures."
                                  ],
                                  className: "text-warning text-small text-center p-centerd"
                                }),
                            guidance
                          ]
                        });
                  } else {
                    var tmp$2;
                    if (lastInspection !== undefined) {
                      var match$4 = Rating.describe(QuestionType.Value.rate(lastInspection.value), match.ratingStylingRules);
                      tmp$2 = ReactDOM.jsx("span", {
                            children: String(match$4.number),
                            className: "label label-rating label-" + match$4.color
                          });
                    } else {
                      tmp$2 = ReactDOM.jsx("span", {
                            children: "None"
                          });
                    }
                    tmp$1 = ReactDOM.jsxs("div", {
                          children: [
                            ReactDOM.jsx("strong", {
                                  children: "Last rating:",
                                  className: "t-last-rating m-1"
                                }),
                            tmp$2,
                            lastInspection !== undefined ? ReactDOM.jsx("div", {
                                    children: lastInspection.annotations.map(function (annotation) {
                                          return ReactDOM.jsx("label", {
                                                      children: annotation,
                                                      className: "label"
                                                    });
                                        }),
                                    className: "columns pl-1 pr-1 text-tiny"
                                  }) : Mithril.empty,
                            ReactDOM.jsx("div", {
                                  className: "divider blank"
                                }),
                            guidance
                          ],
                          className: "t-last-inspection-info text-small"
                        });
                  }
                  tmp = ReactDOM.jsxs("div", {
                        children: [
                          React.jsx(NavigationPills.make, {
                                currentStep: 0,
                                stepCount: stepCount
                              }),
                          match.theCameraFailed ? ReactDOM.jsx("div", {
                                  children: "The camera failed to take the picture",
                                  className: "toast toast-error"
                                }) : Mithril.empty,
                          ReactDOM.jsx("div", {
                                className: "divider blank"
                              }),
                          tmp$1
                        ]
                      });
                }
                var match$5 = vnode.state.data;
                var tmp$3;
                if (typeof match$5 !== "object") {
                  tmp$3 = Mithril.empty;
                } else {
                  var vessel = match$5.vessel;
                  tmp$3 = React.jsxs(TakePicture.make, {
                        role: "MAIN_EVIDENCE",
                        className: "btn btn-primary btn-block btn-lg t-continue",
                        children: [
                          "Start inspection",
                          React.jsx(Feather.make, {
                                icon: "arrow_right",
                                className: "ml-2"
                              })
                        ],
                        onCapture: (function (extra, extra$1, extra$2) {
                            var attachment = extra$1;
                            var draftSubmissionId = Curry._2(Prelude.OptionExported.$$Option.map, Mithril$1.route.param("draftSubmissionId"), (function (i) {
                                    return i;
                                  }));
                            var match = vnode.state.data;
                            if (typeof match !== "object") {
                              return ;
                            }
                            var isRectification = match.isRectification;
                            var procedure = match.procedure;
                            var defaultRating = match.defaultRating;
                            var back = match.back;
                            var match$1 = procedure.question_type;
                            var value = match$1 === "RatingQuestion" ? ({
                                  NAME: "Rating",
                                  VAL: defaultRating
                                }) : (
                                match$1 === "OkNotOkQuestion" ? ({
                                      NAME: "OkNotOk",
                                      VAL: defaultRating === 5
                                    }) : (
                                    match$1 === "PhotoAlbumQuestion" ? ({
                                          NAME: "PhotoAlbum",
                                          VAL: true
                                        }) : undefined
                                  )
                              );
                            if (value !== undefined) {
                              return Prelude.thenDo(State__Inspections.make(procedure, attachment, undefined, value, match.picturePrompted, true, undefined, undefined, Caml_option.some(draftSubmissionId), undefined), (function (param) {
                                            Prelude.OptionExported.$$Option.$$do(param[1], (function (param) {
                                                    KeepScrollLink.saveScroll();
                                                    Mithril$1.route.set(URLs.editInspection(param.id), {
                                                          back: back,
                                                          isRectification: isRectification
                                                        });
                                                  }));
                                          }));
                            } else {
                              console.error("Cannot create an inspection for that procedure type", procedure);
                              return ;
                            }
                          }),
                        onCameraFailure: (function (extra) {
                            var state = vnode.state.data;
                            if (typeof state !== "object") {
                              return ;
                            } else {
                              return Mithril.setState(vnode, {
                                          TAG: "Loaded",
                                          back: state.back,
                                          defaultRating: state.defaultRating,
                                          picturePrompted: state.picturePrompted,
                                          vessel: state.vessel,
                                          procedure: state.procedure,
                                          ratingStylingRules: state.ratingStylingRules,
                                          component: state.component,
                                          positionDiagram: state.positionDiagram,
                                          exampleImage: state.exampleImage,
                                          lastInspection: state.lastInspection,
                                          lastPhoto: state.lastPhoto,
                                          theCameraFailed: true,
                                          isRectification: state.isRectification
                                        });
                            }
                          }),
                        useEmbeddedCamera: Vessel.getCameraConf(vessel),
                        resolution: vessel.max_pic_resolution
                      });
                }
                return React.jsx(ListLayout.make, {
                            children: tmp,
                            title: title(vnode.state.data),
                            bottom: Caml_option.some(tmp$3)
                          });
              }));
}

var include = ProtectedViewMaker.EnsureProfile({
      make: make
    });

var make$1 = include.make;

export {
  make$1 as make,
}
/* include Not a pure module */
