// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as RRule from "../../../libs/RRule.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Rating from "../Rating.js";
import * as Vessel from "../../vessel/Vessel.js";
import * as Browser from "../../../libs/Browser.js";
import * as Feather from "../../common/views/Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Procedure from "../Procedure.js";
import * as Inspection from "../Inspection.js";
import * as ListLayout from "../../common/views/ListLayout.js";
import * as LocalImage from "../../../libs/LocalImage.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Conditional from "../../../libs/Conditional.js";
import * as TakePicture from "../../common/views/TakePicture.js";
import * as DetailImages from "../../common/views/DetailImages.js";
import * as InspectionId from "../InspectionId.js";
import * as QuestionType from "../QuestionType.js";
import * as NewInspection from "../NewInspection.js";
import * as Mithril__Route from "../../../libs/rescript-mithril/src/Mithril__Route.js";
import * as NavigationPills from "../../common/views/NavigationPills.js";
import * as ProcedureAnswer from "../ProcedureAnswer.js";
import * as SurveyCommonViews from "./SurveyCommonViews.js";
import * as DateQuestionEditor from "./DateQuestionEditor.js";
import * as State__Inspections from "../../state/State__Inspections.js";
import * as TextQuestionEditor from "./TextQuestionEditor.js";
import * as NewInspectionManager from "../NewInspectionManager.js";
import * as IntegerQuestionEditor from "./IntegerQuestionEditor.js";

function header(vnode) {
  return ReactDOM.jsx("div", {
              children: ReactDOM.jsx("div", {
                    children: ReactDOM.jsx("h5", {
                          children: vnode.attrs.procedure.name,
                          className: "text-left t-procedure-title"
                        }),
                    className: "column"
                  }),
              className: "columns"
            });
}

var BaseInspectionEditor = {
  header: header
};

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var inspection = vnode.attrs.inspection;
                var deleteAttachment = vnode.attrs.deleteAttachment;
                return ReactDOM.jsxs("div", {
                            children: [
                              Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, NewInspection.getMainImage(inspection), (function (resource) {
                                          return React.jsx(LocalImage.Resource.make, {
                                                      resource: resource,
                                                      maxRetries: 3
                                                    });
                                        })), Mithril.empty),
                              ReactDOM.jsx("div", {
                                    className: "divider blank lg"
                                  }),
                              React.jsx(DetailImages.make, {
                                    deleteAttachment: deleteAttachment,
                                    attachments: NewInspection.getDetailedPhotos(inspection)
                                  })
                            ]
                          });
              }));
}

var PictureInspection = {
  make: make
};

function answer(vnode, answer$1, _event) {
  vnode.attrs.setValueByRating(ProcedureAnswer.toRating(answer$1), "OkNotOkQuestion");
}

function buttonClass(inspection, expectedAnswer) {
  if (QuestionType.Value.getAnswer(inspection.value) !== expectedAnswer) {
    return "";
  } else {
    return "btn-" + ProcedureAnswer.description(expectedAnswer).color;
  }
}

function make$1(param) {
  return Mithril.view(Mithril.oninit(Mithril.component("Unknown"), (function (vnode) {
                    vnode.state.data = ProcedureAnswer.fromRating(QuestionType.Value.rate(vnode.attrs.inspection.value));
                  })), (function (vnode) {
                var vessel = vnode.attrs.vessel;
                var inspection = vnode.attrs.inspection;
                var component = vnode.attrs.component;
                var toggleAnnotation = vnode.attrs.toggleAnnotation;
                var setAdditionalAnnotation = vnode.attrs.setAdditionalAnnotation;
                var setDeadline = vnode.attrs.setDeadline;
                var isRectification = vnode.attrs.isRectification;
                var deleteAttachment = vnode.attrs.deleteAttachment;
                var currentStep = vnode.attrs.currentStep;
                return ReactDOM.jsxs("div", {
                            children: [
                              React.jsx(NavigationPills.make, {
                                    currentStep: currentStep,
                                    stepCount: 2
                                  }),
                              header(vnode),
                              currentStep !== 1 ? (
                                  currentStep !== 2 ? Mithril.empty : [
                                      Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Inspection.getMainImage(inspection), (function (resource) {
                                                  return React.jsx(LocalImage.Resource.make, {
                                                              resource: resource,
                                                              maxRetries: 10
                                                            });
                                                })), Mithril.empty),
                                      ReactDOM.jsx("div", {
                                            className: "divider blank xl"
                                          }),
                                      ReactDOM.jsxs("div", {
                                            children: [
                                              ReactDOM.jsxKeyed("div", {
                                                    children: ReactDOM.jsxs("button", {
                                                          children: [
                                                            React.jsx(Feather.make, {
                                                                  icon: "check"
                                                                }),
                                                            "OK"
                                                          ],
                                                          className: "btn full-width " + buttonClass(inspection, "Yes"),
                                                          disabled: !isRectification,
                                                          onClick: (function (extra) {
                                                              return answer(vnode, "Yes", extra);
                                                            })
                                                        }),
                                                    className: "column col-6"
                                                  }, "yes", undefined),
                                              ReactDOM.jsxKeyed("div", {
                                                    children: ReactDOM.jsxs("button", {
                                                          children: [
                                                            React.jsx(Feather.make, {
                                                                  icon: "x"
                                                                }),
                                                            "Not OK"
                                                          ],
                                                          className: "btn full-width " + buttonClass(inspection, "No"),
                                                          disabled: !isRectification,
                                                          onClick: (function (extra) {
                                                              return answer(vnode, "No", extra);
                                                            })
                                                        }),
                                                    className: "column col-6"
                                                  }, "no", undefined)
                                            ],
                                            className: "columns"
                                          }),
                                      ReactDOM.jsx("div", {
                                            className: "divider blank"
                                          }),
                                      React.jsx(SurveyCommonViews.RatingReasonEditor.make, {
                                            vessel: vessel,
                                            inspection: inspection,
                                            component: component,
                                            toggleAnnotation: toggleAnnotation,
                                            setAdditionalAnnotation: setAdditionalAnnotation,
                                            setDeadline: setDeadline,
                                            isRectification: isRectification
                                          })
                                    ]
                                ) : React.jsx(make, {
                                      inspection: inspection,
                                      deleteAttachment: deleteAttachment
                                    })
                            ]
                          });
              }));
}

var QuestionInspection = {
  header: header,
  answer: answer,
  buttonClass: buttonClass,
  make: make$1
};

function answer$1(vnode, answer$2, _event) {
  vnode.attrs.setValueByAnswer(answer$2, "YesNoQuestion");
}

function buttonClass$1(inspection, expectedAnswer) {
  if (QuestionType.Value.getAnswer(inspection.value) !== expectedAnswer) {
    return "";
  } else {
    return "btn-" + ProcedureAnswer.description(expectedAnswer).color;
  }
}

function header$1(vnode) {
  return ReactDOM.jsx("div", {
              children: ReactDOM.jsx("div", {
                    children: ReactDOM.jsx("h5", {
                          children: vnode.attrs.procedure.name,
                          className: "text-left t-procedure-title"
                        }),
                    className: "column"
                  }),
              className: "columns"
            });
}

function make$2(param) {
  return Mithril.view(Mithril.oninit(Mithril.component("Unknown"), (function (vnode) {
                    vnode.state.data = QuestionType.Value.getAnswer(vnode.attrs.inspection.value);
                  })), (function (vnode) {
                var inspection = vnode.attrs.inspection;
                return ReactDOM.jsxs("div", {
                            children: [
                              header$1(vnode),
                              ReactDOM.jsx("div", {
                                    className: "divider blank xl"
                                  }),
                              ReactDOM.jsxs("div", {
                                    children: [
                                      ReactDOM.jsxKeyed("div", {
                                            children: ReactDOM.jsxs("button", {
                                                  children: [
                                                    React.jsx(Feather.make, {
                                                          icon: "check"
                                                        }),
                                                    "Yes"
                                                  ],
                                                  className: "btn full-width " + buttonClass$1(inspection, "Yes"),
                                                  onClick: (function (extra) {
                                                      return answer$1(vnode, "Yes", extra);
                                                    })
                                                }),
                                            className: "column col-6"
                                          }, "yes", undefined),
                                      ReactDOM.jsxKeyed("div", {
                                            children: ReactDOM.jsxs("button", {
                                                  children: [
                                                    React.jsx(Feather.make, {
                                                          icon: "x"
                                                        }),
                                                    "No"
                                                  ],
                                                  className: "btn full-width " + buttonClass$1(inspection, "No"),
                                                  onClick: (function (extra) {
                                                      return answer$1(vnode, "No", extra);
                                                    })
                                                }),
                                            className: "column col-6"
                                          }, "no", undefined)
                                    ],
                                    className: "columns"
                                  })
                            ]
                          });
              }));
}

var YesNoInspection = {
  answer: answer$1,
  buttonClass: buttonClass$1,
  header: header$1,
  make: make$2
};

function make$3(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var vessel = vnode.attrs.vessel;
                var inspection = vnode.attrs.inspection;
                var component = vnode.attrs.component;
                var toggleAnnotation = vnode.attrs.toggleAnnotation;
                var setAdditionalAnnotation = vnode.attrs.setAdditionalAnnotation;
                var setDeadline = vnode.attrs.setDeadline;
                var isRectification = vnode.attrs.isRectification;
                var ratingStylingRules = Prelude.default(vnode.attrs.ratingStylingRules, Rating.StylingRule.defaults);
                var r5 = ratingStylingRules[4];
                var r1 = ratingStylingRules[0];
                var match = QuestionType.Value.rate(inspection.value);
                var tmp;
                if (match === 0) {
                  tmp = Mithril.empty;
                } else {
                  var description = Rating.describe(QuestionType.Value.rate(inspection.value), ratingStylingRules);
                  tmp = [
                    ReactDOM.jsxs("div", {
                          children: [
                            ReactDOM.jsx("strong", {
                                  children: description.name
                                }),
                            ReactDOM.jsx("br", {}),
                            description.text
                          ],
                          className: "toast text-center toast-" + description.color
                        }),
                    ReactDOM.jsx("div", {
                          className: "divider blank"
                        })
                  ];
                }
                return ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsx("h5", {
                                    children: "Rate the condition",
                                    className: "text-left"
                                  }),
                              ReactDOM.jsx("div", {
                                    className: "divider blank xl"
                                  }),
                              ReactDOM.jsx("div", {
                                    children: Rating.descriptions.map(function (param) {
                                          var description = param[1];
                                          var rating = param[0];
                                          var tmp;
                                          switch (rating) {
                                            case 1 :
                                                tmp = ReactDOM.jsx("div", {
                                                      children: r1.label,
                                                      className: "mt-2 text-tiny",
                                                      style: {
                                                        lineHeight: "15px"
                                                      }
                                                    });
                                                break;
                                            case 5 :
                                                tmp = ReactDOM.jsx("div", {
                                                      children: r5.label,
                                                      className: "mt-2 text-tiny",
                                                      style: {
                                                        lineHeight: "15px"
                                                      }
                                                    });
                                                break;
                                            default:
                                              tmp = Mithril.empty;
                                          }
                                          return ReactDOM.jsxsKeyed("div", {
                                                      children: [
                                                        ReactDOM.jsx("button", {
                                                              children: rating,
                                                              className: Mithril.$$class([
                                                                    [
                                                                      "btn btn-action s-circle",
                                                                      true
                                                                    ],
                                                                    [
                                                                      "btn-" + description.color,
                                                                      QuestionType.Value.rate(inspection.value) === rating
                                                                    ]
                                                                  ], undefined),
                                                              onClick: (function (param) {
                                                                  vnode.attrs.setValueByRating(rating, "RatingQuestion");
                                                                })
                                                            }),
                                                        tmp
                                                      ],
                                                      className: "column"
                                                    }, description.name, undefined);
                                        }),
                                    className: "columns text-center"
                                  }),
                              ReactDOM.jsx("div", {
                                    className: "divider blank lg"
                                  }),
                              tmp,
                              React.jsx(SurveyCommonViews.RatingReasonEditor.make, {
                                    vessel: vessel,
                                    inspection: inspection,
                                    component: component,
                                    toggleAnnotation: toggleAnnotation,
                                    setAdditionalAnnotation: setAdditionalAnnotation,
                                    setDeadline: setDeadline,
                                    isRectification: isRectification
                                  })
                            ]
                          });
              }));
}

var RateInspection = {
  make: make$3
};

function make$4(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var vessel = vnode.attrs.vessel;
                var inspection = vnode.attrs.inspection;
                var component = vnode.attrs.component;
                var toggleAnnotation = vnode.attrs.toggleAnnotation;
                var setValueByRating = vnode.attrs.setValueByRating;
                var setAdditionalAnnotation = vnode.attrs.setAdditionalAnnotation;
                var setDeadline = vnode.attrs.setDeadline;
                var currentStep = vnode.attrs.currentStep;
                var deleteAttachment = vnode.attrs.deleteAttachment;
                var isRectification = vnode.attrs.isRectification;
                var ratingStylingRules = Prelude.default(vnode.attrs.ratingStylingRules, Rating.StylingRule.defaults);
                var stepCount = Prelude.default(vnode.attrs.stepCount, 2);
                return ReactDOM.jsxs("div", {
                            children: [
                              React.jsx(Conditional.make, {
                                    cond: stepCount > 0,
                                    children: React.jsx(NavigationPills.make, {
                                          currentStep: currentStep,
                                          stepCount: stepCount
                                        })
                                  }),
                              React.jsx(Conditional.make, {
                                    cond: isRectification,
                                    children: ReactDOM.jsx("div", {
                                          children: "This inspection may potentially resolve a finding depending on the evaluation.",
                                          className: "toast toast-primary mb-2 text-small"
                                        })
                                  }),
                              header(vnode),
                              currentStep !== 1 ? (
                                  currentStep !== 2 ? Mithril.empty : React.jsx(make$3, {
                                          vessel: vessel,
                                          inspection: inspection,
                                          component: component,
                                          setValueByRating: setValueByRating,
                                          toggleAnnotation: toggleAnnotation,
                                          setAdditionalAnnotation: setAdditionalAnnotation,
                                          setDeadline: setDeadline,
                                          isRectification: isRectification,
                                          ratingStylingRules: ratingStylingRules
                                        })
                                ) : React.jsx(make, {
                                      inspection: inspection,
                                      deleteAttachment: deleteAttachment
                                    })
                            ]
                          });
              }));
}

var MultiStepInspection = {
  header: header,
  make: make$4
};

function title(state) {
  if (typeof state !== "object") {
    return "";
  } else {
    return "Loaded!";
  }
}

async function deleteDetailImage(vnode, attachment, $$event) {
  $$event.preventDefault();
  $$event.stopPropagation();
  if (!Browser.$$Window.confirm("Are you sure?")) {
    return ;
  }
  var state = vnode.state.data;
  if (typeof state !== "object") {
    return ;
  }
  var inspection = await NewInspectionManager.deletePhoto(state.inspection, attachment);
  var newrecord = Caml_obj.obj_dup(Query.makeWrite());
  await Query.write((newrecord.newInspections = [{
            TAG: "Save",
            _0: inspection
          }], newrecord));
  vnode.state.data = {
    TAG: "Loaded",
    inspection: inspection,
    procedure: state.procedure,
    vessel: state.vessel,
    component: state.component,
    procedureType: state.procedureType,
    back: state.back,
    currentStep: state.currentStep,
    stepCount: state.stepCount,
    theCameraFailed: state.theCameraFailed,
    isRectification: state.isRectification,
    allowContinue: state.allowContinue,
    ratingStylingRules: state.ratingStylingRules
  };
}

async function saveNewInspection(state) {
  if (typeof state !== "object") {
    return "NotReady";
  }
  var inspection = state.inspection;
  var procedure = state.procedure;
  var recurrence = RRule.toSet(procedure.recurrence);
  var due_date = Prelude.default(RRule.$$Set.after(recurrence, inspection.timestamp, undefined), inspection.timestamp);
  var overdue_date = Prelude.default(RRule.$$Set.after(recurrence, due_date, undefined), due_date);
  var newrecord = Caml_obj.obj_dup(procedure);
  newrecord.overdue_date = overdue_date;
  newrecord.due_date = due_date;
  var newrecord$1 = Caml_obj.obj_dup(Query.makeRead());
  var match = await Query.read((newrecord$1.plannedProcedures = {
          TAG: "Get",
          _0: newrecord.id
        }, newrecord$1));
  var newrecord$2 = Caml_obj.obj_dup(Query.makeWrite());
  newrecord$2.newInspections = [{
      TAG: "Save",
      _0: inspection
    }];
  newrecord$2.draftInspections = ["Clear"];
  newrecord$2.procedures = [{
      TAG: "Save",
      _0: newrecord
    }];
  var query = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Prelude.$$Array.first(match.plannedProcedures), (function (param) {
              var newrecord$3 = Caml_obj.obj_dup(newrecord$2);
              newrecord$3.plannedProcedures = [{
                  TAG: "Save",
                  _0: newrecord
                }];
              return newrecord$3;
            })), newrecord$2);
  await Query.write(query);
  return state;
}

function addAttachment(vnode, attachment, saved) {
  var state = vnode.state.data;
  if (typeof state !== "object") {
    return ;
  }
  var inspection = NewInspectionManager.addAttachment(state.inspection, attachment);
  Prelude.thenDo(saveNewInspection({
            TAG: "Loaded",
            inspection: inspection,
            procedure: state.procedure,
            vessel: state.vessel,
            component: state.component,
            procedureType: state.procedureType,
            back: state.back,
            currentStep: state.currentStep,
            stepCount: state.stepCount,
            theCameraFailed: state.theCameraFailed,
            isRectification: state.isRectification,
            allowContinue: state.allowContinue,
            ratingStylingRules: state.ratingStylingRules
          }), (function (extra) {
          return Mithril.setState(vnode, extra);
        }));
}

function nextStep(vnode, _event) {
  var state = vnode.state.data;
  var tmp;
  tmp = typeof state !== "object" ? "NotReady" : ({
        TAG: "Loaded",
        inspection: state.inspection,
        procedure: state.procedure,
        vessel: state.vessel,
        component: state.component,
        procedureType: state.procedureType,
        back: state.back,
        currentStep: state.currentStep + 1 | 0,
        stepCount: state.stepCount,
        theCameraFailed: state.theCameraFailed,
        isRectification: state.isRectification,
        allowContinue: state.allowContinue,
        ratingStylingRules: state.ratingStylingRules
      });
  Mithril.setState(vnode, tmp);
}

function prevStep(vnode, _event) {
  var state = vnode.state.data;
  var tmp;
  tmp = typeof state !== "object" ? "NotReady" : ({
        TAG: "Loaded",
        inspection: state.inspection,
        procedure: state.procedure,
        vessel: state.vessel,
        component: state.component,
        procedureType: state.procedureType,
        back: state.back,
        currentStep: state.currentStep - 1 | 0,
        stepCount: state.stepCount,
        theCameraFailed: state.theCameraFailed,
        isRectification: state.isRectification,
        allowContinue: state.allowContinue,
        ratingStylingRules: state.ratingStylingRules
      });
  Mithril.setState(vnode, tmp);
}

function isItPossibleToContinue(vessel, isRectification, rating, additional_annotation, deadline) {
  var additional_annotation_filled = additional_annotation.trim().length > 0;
  if (rating === 0) {
    return false;
  }
  if (isRectification) {
    return additional_annotation_filled;
  }
  var hasLowRating = rating === 1 || rating === 2;
  var requiresDeadline = vessel.mandatory_findings_deadline;
  var deadlineProperlyFilled = hasLowRating && requiresDeadline ? deadline !== undefined : true;
  var requiresObservations = vessel.mandatory_findings_observations;
  var observationsProperlyFilled = hasLowRating && requiresObservations ? additional_annotation_filled : true;
  if (deadlineProperlyFilled) {
    return observationsProperlyFilled;
  } else {
    return false;
  }
}

function setValueByRating(vnode, rating, question_type) {
  var state = vnode.state.data;
  var tmp;
  if (typeof state !== "object") {
    tmp = "NotReady";
  } else {
    var init = state.inspection;
    tmp = {
      TAG: "Loaded",
      inspection: {
        id: init.id,
        procedure_id: init.procedure_id,
        component_id: init.component_id,
        section_id: init.section_id,
        vessel_id: init.vessel_id,
        inspector_id: init.inspector_id,
        timestamp: init.timestamp,
        code: init.code,
        annotations: init.annotations,
        additional_annotation: init.additional_annotation,
        picture_prompted: init.picture_prompted,
        walked_steps: init.walked_steps,
        app_version: init.app_version,
        deficiency_id: init.deficiency_id,
        attachments: init.attachments,
        value: question_type === "RatingQuestion" ? ({
              NAME: "Rating",
              VAL: rating
            }) : (
            question_type === "OkNotOkQuestion" ? ({
                  NAME: "OkNotOk",
                  VAL: rating === 5
                }) : undefined
          ),
        deadline: init.deadline,
        profile: init.profile
      },
      procedure: state.procedure,
      vessel: state.vessel,
      component: state.component,
      procedureType: state.procedureType,
      back: state.back,
      currentStep: state.currentStep,
      stepCount: state.stepCount,
      theCameraFailed: state.theCameraFailed,
      isRectification: state.isRectification,
      allowContinue: isItPossibleToContinue(state.vessel, state.isRectification, rating, state.inspection.additional_annotation, state.inspection.deadline),
      ratingStylingRules: state.ratingStylingRules
    };
  }
  vnode.state.data = tmp;
}

function setValueByAnswer(vnode, answer, question_type) {
  var state = vnode.state.data;
  var tmp;
  if (typeof state !== "object") {
    tmp = "NotReady";
  } else {
    var init = state.inspection;
    tmp = {
      TAG: "Loaded",
      inspection: {
        id: init.id,
        procedure_id: init.procedure_id,
        component_id: init.component_id,
        section_id: init.section_id,
        vessel_id: init.vessel_id,
        inspector_id: init.inspector_id,
        timestamp: init.timestamp,
        code: init.code,
        annotations: init.annotations,
        additional_annotation: init.additional_annotation,
        picture_prompted: init.picture_prompted,
        walked_steps: init.walked_steps,
        app_version: init.app_version,
        deficiency_id: init.deficiency_id,
        attachments: init.attachments,
        value: question_type === "YesNoQuestion" || question_type === "OkNotOkQuestion" ? ({
              NAME: "YesNo",
              VAL: answer === "Yes"
            }) : undefined,
        deadline: init.deadline,
        profile: init.profile
      },
      procedure: state.procedure,
      vessel: state.vessel,
      component: state.component,
      procedureType: state.procedureType,
      back: state.back,
      currentStep: state.currentStep,
      stepCount: state.stepCount,
      theCameraFailed: state.theCameraFailed,
      isRectification: state.isRectification,
      allowContinue: question_type === "OkNotOkQuestion" ? isItPossibleToContinue(state.vessel, state.isRectification, ProcedureAnswer.toRating(answer), state.inspection.additional_annotation, state.inspection.deadline) : true,
      ratingStylingRules: state.ratingStylingRules
    };
  }
  vnode.state.data = tmp;
}

function toggleAnnotation(vnode, annotation) {
  var state = vnode.state.data;
  var tmp;
  if (typeof state !== "object") {
    tmp = "NotReady";
  } else {
    var annotations = Prelude.$$Array.includes(state.inspection.annotations, annotation) ? Curry._2(Prelude.$$Array.keep, state.inspection.annotations, (function (i) {
              return i !== annotation;
            })) : Curry._2(Prelude.$$Array.concat, state.inspection.annotations, [annotation]);
    var init = state.inspection;
    tmp = {
      TAG: "Loaded",
      inspection: {
        id: init.id,
        procedure_id: init.procedure_id,
        component_id: init.component_id,
        section_id: init.section_id,
        vessel_id: init.vessel_id,
        inspector_id: init.inspector_id,
        timestamp: init.timestamp,
        code: init.code,
        annotations: annotations,
        additional_annotation: init.additional_annotation,
        picture_prompted: init.picture_prompted,
        walked_steps: init.walked_steps,
        app_version: init.app_version,
        deficiency_id: init.deficiency_id,
        attachments: init.attachments,
        value: init.value,
        deadline: init.deadline,
        profile: init.profile
      },
      procedure: state.procedure,
      vessel: state.vessel,
      component: state.component,
      procedureType: state.procedureType,
      back: state.back,
      currentStep: state.currentStep,
      stepCount: state.stepCount,
      theCameraFailed: state.theCameraFailed,
      isRectification: state.isRectification,
      allowContinue: state.allowContinue,
      ratingStylingRules: state.ratingStylingRules
    };
  }
  vnode.state.data = tmp;
}

function setAdditionalAnnotation(vnode, annotation) {
  var state = vnode.state.data;
  var tmp;
  if (typeof state !== "object") {
    tmp = "NotReady";
  } else {
    var init = state.inspection;
    tmp = {
      TAG: "Loaded",
      inspection: {
        id: init.id,
        procedure_id: init.procedure_id,
        component_id: init.component_id,
        section_id: init.section_id,
        vessel_id: init.vessel_id,
        inspector_id: init.inspector_id,
        timestamp: init.timestamp,
        code: init.code,
        annotations: init.annotations,
        additional_annotation: annotation,
        picture_prompted: init.picture_prompted,
        walked_steps: init.walked_steps,
        app_version: init.app_version,
        deficiency_id: init.deficiency_id,
        attachments: init.attachments,
        value: init.value,
        deadline: init.deadline,
        profile: init.profile
      },
      procedure: state.procedure,
      vessel: state.vessel,
      component: state.component,
      procedureType: state.procedureType,
      back: state.back,
      currentStep: state.currentStep,
      stepCount: state.stepCount,
      theCameraFailed: state.theCameraFailed,
      isRectification: state.isRectification,
      allowContinue: isItPossibleToContinue(state.vessel, state.isRectification, QuestionType.Value.rate(state.inspection.value), annotation, state.inspection.deadline),
      ratingStylingRules: state.ratingStylingRules
    };
  }
  vnode.state.data = tmp;
}

function setDeadline(vnode, deadline) {
  var state = vnode.state.data;
  var tmp;
  if (typeof state !== "object") {
    tmp = "NotReady";
  } else {
    var init = state.inspection;
    tmp = {
      TAG: "Loaded",
      inspection: {
        id: init.id,
        procedure_id: init.procedure_id,
        component_id: init.component_id,
        section_id: init.section_id,
        vessel_id: init.vessel_id,
        inspector_id: init.inspector_id,
        timestamp: init.timestamp,
        code: init.code,
        annotations: init.annotations,
        additional_annotation: init.additional_annotation,
        picture_prompted: init.picture_prompted,
        walked_steps: init.walked_steps,
        app_version: init.app_version,
        deficiency_id: init.deficiency_id,
        attachments: init.attachments,
        value: init.value,
        deadline: deadline,
        profile: init.profile
      },
      procedure: state.procedure,
      vessel: state.vessel,
      component: state.component,
      procedureType: state.procedureType,
      back: state.back,
      currentStep: state.currentStep,
      stepCount: state.stepCount,
      theCameraFailed: state.theCameraFailed,
      isRectification: state.isRectification,
      allowContinue: isItPossibleToContinue(state.vessel, state.isRectification, QuestionType.Value.rate(state.inspection.value), state.inspection.additional_annotation, deadline),
      ratingStylingRules: state.ratingStylingRules
    };
  }
  vnode.state.data = tmp;
}

function $$continue(vnode, _event) {
  var match = vnode.state.data;
  if (typeof match !== "object") {
    return ;
  }
  var back = match.back;
  Prelude.thenDo(saveNewInspection(vnode.state.data), (function (param) {
          window.history.go(back);
        }));
}

function setText(vnode, text) {
  var state = vnode.state.data;
  var tmp;
  if (typeof state !== "object") {
    tmp = "NotReady";
  } else {
    var init = state.inspection;
    tmp = {
      TAG: "Loaded",
      inspection: {
        id: init.id,
        procedure_id: init.procedure_id,
        component_id: init.component_id,
        section_id: init.section_id,
        vessel_id: init.vessel_id,
        inspector_id: init.inspector_id,
        timestamp: init.timestamp,
        code: init.code,
        annotations: init.annotations,
        additional_annotation: init.additional_annotation,
        picture_prompted: init.picture_prompted,
        walked_steps: init.walked_steps,
        app_version: init.app_version,
        deficiency_id: init.deficiency_id,
        attachments: init.attachments,
        value: {
          NAME: "Text",
          VAL: text
        },
        deadline: init.deadline,
        profile: init.profile
      },
      procedure: state.procedure,
      vessel: state.vessel,
      component: state.component,
      procedureType: state.procedureType,
      back: state.back,
      currentStep: state.currentStep,
      stepCount: state.stepCount,
      theCameraFailed: state.theCameraFailed,
      isRectification: state.isRectification,
      allowContinue: text.length > 0,
      ratingStylingRules: state.ratingStylingRules
    };
  }
  vnode.state.data = tmp;
}

function setInteger(vnode, value) {
  var state = vnode.state.data;
  var tmp;
  if (typeof state !== "object") {
    tmp = "NotReady";
  } else if (value !== undefined) {
    var init = state.inspection;
    tmp = {
      TAG: "Loaded",
      inspection: {
        id: init.id,
        procedure_id: init.procedure_id,
        component_id: init.component_id,
        section_id: init.section_id,
        vessel_id: init.vessel_id,
        inspector_id: init.inspector_id,
        timestamp: init.timestamp,
        code: init.code,
        annotations: init.annotations,
        additional_annotation: init.additional_annotation,
        picture_prompted: init.picture_prompted,
        walked_steps: init.walked_steps,
        app_version: init.app_version,
        deficiency_id: init.deficiency_id,
        attachments: init.attachments,
        value: {
          NAME: "Integer",
          VAL: value
        },
        deadline: init.deadline,
        profile: init.profile
      },
      procedure: state.procedure,
      vessel: state.vessel,
      component: state.component,
      procedureType: state.procedureType,
      back: state.back,
      currentStep: state.currentStep,
      stepCount: state.stepCount,
      theCameraFailed: state.theCameraFailed,
      isRectification: state.isRectification,
      allowContinue: true,
      ratingStylingRules: state.ratingStylingRules
    };
  } else {
    var init$1 = state.inspection;
    tmp = {
      TAG: "Loaded",
      inspection: {
        id: init$1.id,
        procedure_id: init$1.procedure_id,
        component_id: init$1.component_id,
        section_id: init$1.section_id,
        vessel_id: init$1.vessel_id,
        inspector_id: init$1.inspector_id,
        timestamp: init$1.timestamp,
        code: init$1.code,
        annotations: init$1.annotations,
        additional_annotation: init$1.additional_annotation,
        picture_prompted: init$1.picture_prompted,
        walked_steps: init$1.walked_steps,
        app_version: init$1.app_version,
        deficiency_id: init$1.deficiency_id,
        attachments: init$1.attachments,
        value: undefined,
        deadline: init$1.deadline,
        profile: init$1.profile
      },
      procedure: state.procedure,
      vessel: state.vessel,
      component: state.component,
      procedureType: state.procedureType,
      back: state.back,
      currentStep: state.currentStep,
      stepCount: state.stepCount,
      theCameraFailed: state.theCameraFailed,
      isRectification: state.isRectification,
      allowContinue: false,
      ratingStylingRules: state.ratingStylingRules
    };
  }
  vnode.state.data = tmp;
}

function make$5(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var callback = vnode.attrs.callback;
                var allowContinue = vnode.attrs.allowContinue;
                return ReactDOM.jsx("button", {
                            children: "Continue",
                            className: "btn btn-primary btn-block btn-lg t-continue-inspection",
                            disabled: !allowContinue,
                            onClick: callback
                          });
              }));
}

var ContinueButton = {
  make: make$5
};

function make$6(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var match = vnode.attrs.currentStep;
                if (match !== 1) {
                  if (match !== 2) {
                    return Mithril.empty;
                  } else {
                    return React.jsx(make$5, {
                                callback: vnode.attrs.continue,
                                allowContinue: vnode.attrs.allowContinue
                              });
                  }
                } else {
                  return ReactDOM.jsxs("div", {
                              children: [
                                React.jsxs(TakePicture.make, {
                                      requireLandscape: Prelude.default(vnode.attrs.requireLandscape, false),
                                      style: {
                                        height: "3rem",
                                        marginRight: "0.66666667%",
                                        width: "66%"
                                      },
                                      children: [
                                        React.jsx(Feather.make, {
                                              icon: "camera"
                                            }),
                                        "Add detail pictures"
                                      ],
                                      onCapture: (function (_image, attachment, saved) {
                                          vnode.attrs.takePicture(attachment, saved);
                                        }),
                                      useEmbeddedCamera: vnode.attrs.use_embedded_camera,
                                      resolution: vnode.attrs.resolution
                                    }),
                                ReactDOM.jsxs("button", {
                                      children: [
                                        React.jsx(Feather.make, {
                                              icon: "save"
                                            }),
                                        "Save"
                                      ],
                                      className: "btn btn-primary col-4",
                                      style: {
                                        height: "3rem"
                                      },
                                      onClick: vnode.attrs.savePhotos
                                    })
                              ],
                              className: "columns m-0"
                            });
                }
              }));
}

var PictureInspectionButtons = {
  make: make$6
};

function updateInspection(vnode, inspection) {
  var payload = vnode.state.data;
  if (typeof payload !== "object") {
    return ;
  }
  var allowContinue = Curry._1(Prelude.OptionExported.$$Option.isSome, inspection.value);
  Mithril.setState(vnode, {
        TAG: "Loaded",
        inspection: inspection,
        procedure: payload.procedure,
        vessel: payload.vessel,
        component: payload.component,
        procedureType: payload.procedureType,
        back: payload.back,
        currentStep: payload.currentStep,
        stepCount: payload.stepCount,
        theCameraFailed: payload.theCameraFailed,
        isRectification: payload.isRectification,
        allowContinue: allowContinue,
        ratingStylingRules: payload.ratingStylingRules
      });
}

function make$7(param) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), (function (vnode) {
                    var inspectionId = InspectionId.Route.param("inspectionId");
                    var isRectification = Mithril__Route.paramBool("isRectification");
                    var back = Prelude.default(Mithril__Route.paramInt("back"), -1);
                    var stepCount = Prelude.default(Mithril__Route.paramInt("stepCount"), 2);
                    var currentStep = Prelude.default(Mithril__Route.paramInt("currentStep"), 1);
                    Prelude.thenDo(Query.doEach([
                              {
                                TAG: "Read",
                                _0: (function (param) {
                                    var newrecord = Caml_obj.obj_dup(Query.makeRead());
                                    newrecord.newInspections = {
                                      TAG: "Get",
                                      _0: inspectionId
                                    };
                                    newrecord.draftInspections = {
                                      TAG: "Get",
                                      _0: inspectionId
                                    };
                                    return newrecord;
                                  })
                              },
                              {
                                TAG: "Read",
                                _0: (function (param) {
                                    var newrecord = Caml_obj.obj_dup(Query.makeRead());
                                    newrecord.procedures = Procedure.ofInspection(Prelude.$$Array.first(Curry._2(Prelude.$$Array.concat, param.newInspections, param.draftInspections)));
                                    return newrecord;
                                  })
                              },
                              {
                                TAG: "Read",
                                _0: (function (param) {
                                    var procedures = param.procedures;
                                    var newrecord = Caml_obj.obj_dup(Query.makeRead());
                                    newrecord.ratingStylingRules = Procedure.getRatingStyleRules(Prelude.$$Array.first(procedures));
                                    newrecord.components = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Prelude.$$Array.first(procedures), (function (p) {
                                                return {
                                                        TAG: "Get",
                                                        _0: p.component_id
                                                      };
                                              })), "NoOp");
                                    newrecord.vessels = {
                                      TAG: "In",
                                      _0: procedures.map(function (p) {
                                            return p.vessel_id;
                                          })
                                    };
                                    return newrecord;
                                  })
                              }
                            ]), (function (param) {
                            var ratingStylingRules = param.ratingStylingRules;
                            var match = Prelude.$$Array.first(param.components);
                            var match$1 = Prelude.$$Array.first(param.procedures);
                            var match$2 = Prelude.$$Array.first(Curry._2(Prelude.$$Array.concat, param.newInspections, param.draftInspections));
                            var match$3 = Prelude.$$Array.first(param.vessels);
                            if (match !== undefined) {
                              if (match$1 !== undefined) {
                                if (match$2 !== undefined) {
                                  if (match$3 !== undefined) {
                                    var tmp;
                                    if (isRectification !== undefined) {
                                      tmp = Promise.resolve(isRectification);
                                    } else {
                                      var newrecord = Caml_obj.obj_dup(Query.makeRead());
                                      tmp = Prelude.thenResolve(Query.read((newrecord.newInspections = NewInspection.ofProcedure(match$1.id), newrecord.inspections = Inspection.ofProcedure(match$1.id), newrecord)), (function (param) {
                                              return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Prelude.$$Array.last(Prelude.$$Array.sort(Curry._2(Prelude.$$Array.keep, Curry._2(Prelude.$$Array.concat, param.inspections, param.newInspections), (function (i) {
                                                                            return Caml_obj.lessthan(i.timestamp, match$2.timestamp);
                                                                          })), Inspection.byTimestamp, undefined)), (function (i) {
                                                                return NewInspection.isDeficient(i);
                                                              })), false);
                                            }));
                                    }
                                    return Prelude.thenDo(tmp, (function (isRectification) {
                                                  Mithril.setState(vnode, {
                                                        TAG: "Loaded",
                                                        inspection: match$2,
                                                        procedure: match$1,
                                                        vessel: match$3,
                                                        component: match,
                                                        procedureType: match$1.question_type,
                                                        back: back,
                                                        currentStep: currentStep,
                                                        stepCount: stepCount,
                                                        theCameraFailed: false,
                                                        isRectification: isRectification,
                                                        allowContinue: isItPossibleToContinue(match$3, isRectification, QuestionType.Value.rate(match$2.value), match$2.additional_annotation, match$2.deadline),
                                                        ratingStylingRules: Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Prelude.$$Array.first(ratingStylingRules), (function (param) {
                                                                    return param.rules;
                                                                  })), Rating.StylingRule.defaults)
                                                      });
                                                }));
                                  }
                                  window.history.back();
                                  return ;
                                }
                                window.history.back();
                                return ;
                              }
                              window.history.back();
                              return ;
                            }
                            window.history.back();
                          }));
                  })), (function (vnode) {
                var match = vnode.state.data;
                var title;
                if (typeof match !== "object") {
                  title = "Loading...";
                } else {
                  var match$1 = match.procedureType;
                  title = match$1 === "PhotoAlbumQuestion" || match$1 === "OkNotOkQuestion" || match$1 === "RatingQuestion" ? "Photo overview" : "Edit inspection";
                }
                var match$2 = vnode.state.data;
                var actions;
                if (typeof match$2 !== "object") {
                  actions = [];
                } else {
                  var inspection = match$2.inspection;
                  actions = [{
                      icon: "trash_2",
                      effect: {
                        TAG: "Fn",
                        _0: (function ($$event) {
                            $$event.preventDefault();
                            if (Browser.$$Window.confirm("Are you sure?")) {
                              return Prelude.thenDo(State__Inspections.$$delete(inspection), (function (param) {
                                            window.history.back();
                                          }));
                            }
                            
                          })
                      }
                    }];
                }
                var match$3 = vnode.state.data;
                var tmp;
                tmp = typeof match$3 !== "object" || !match$3.theCameraFailed ? Mithril.empty : ReactDOM.jsx("div", {
                        children: "The camera failed to take the picture",
                        className: "toast toast-error"
                      });
                var match$4 = vnode.state.data;
                var tmp$1;
                if (typeof match$4 !== "object") {
                  tmp$1 = Mithril.empty;
                } else {
                  var match$5 = match$4.procedureType;
                  var component = match$4.component;
                  var vessel = match$4.vessel;
                  var procedure = match$4.procedure;
                  var inspection$1 = match$4.inspection;
                  tmp$1 = match$5 === "RatingQuestion" ? React.jsx(make$4, {
                          currentStep: match$4.currentStep,
                          stepCount: match$4.stepCount,
                          vessel: vessel,
                          inspection: inspection$1,
                          procedure: procedure,
                          component: component,
                          setValueByRating: (function (extra, extra$1) {
                              return setValueByRating(vnode, extra, extra$1);
                            }),
                          toggleAnnotation: (function (extra) {
                              return toggleAnnotation(vnode, extra);
                            }),
                          setAdditionalAnnotation: (function (extra) {
                              return setAdditionalAnnotation(vnode, extra);
                            }),
                          setDeadline: (function (extra) {
                              return setDeadline(vnode, extra);
                            }),
                          addAttachment: (function (extra, extra$1) {
                              return addAttachment(vnode, extra, extra$1);
                            }),
                          deleteAttachment: (function (extra, extra$1) {
                              return deleteDetailImage(vnode, extra, extra$1);
                            }),
                          isRectification: match$4.isRectification,
                          ratingStylingRules: match$4.ratingStylingRules
                        }) : (
                      match$5 === "SignatureQuestion" || match$5 === "WeightDeltaQuestion" ? Mithril.empty : (
                          match$5 === "OkNotOkQuestion" ? React.jsx(make$1, {
                                  currentStep: match$4.currentStep,
                                  vessel: vessel,
                                  inspection: inspection$1,
                                  procedure: procedure,
                                  component: component,
                                  setValueByRating: (function (extra, extra$1) {
                                      return setValueByRating(vnode, extra, extra$1);
                                    }),
                                  toggleAnnotation: (function (extra) {
                                      return toggleAnnotation(vnode, extra);
                                    }),
                                  setAdditionalAnnotation: (function (extra) {
                                      return setAdditionalAnnotation(vnode, extra);
                                    }),
                                  setDeadline: (function (extra) {
                                      return setDeadline(vnode, extra);
                                    }),
                                  addAttachment: (function (a, b) {
                                      console.error("Trying to add additional attachments in a Yes/No procedure", a, b);
                                    }),
                                  deleteAttachment: (async function (a, v) {
                                      console.error("Trying to remove additional attachments in a Yes/No procedure", a, v);
                                    }),
                                  isRectification: match$4.isRectification
                                }) : (
                              match$5 === "YesNoQuestion" ? React.jsx(make$2, {
                                      inspection: inspection$1,
                                      procedure: procedure,
                                      setValueByAnswer: (function (extra, extra$1) {
                                          return setValueByAnswer(vnode, extra, extra$1);
                                        })
                                    }) : (
                                  match$5 === "TextQuestion" ? React.jsx(TextQuestionEditor.make, {
                                          procedure: procedure,
                                          inspection: inspection$1,
                                          setText: (function (extra) {
                                              return setText(vnode, extra);
                                            })
                                        }) : (
                                      match$5 === "DateQuestion" ? React.jsx(DateQuestionEditor.make, {
                                              procedure: procedure,
                                              inspection: inspection$1,
                                              onChange: (function (extra) {
                                                  return updateInspection(vnode, extra);
                                                })
                                            }) : (
                                          match$5 === "PhotoAlbumQuestion" ? ReactDOM.jsxs("div", {
                                                  children: [
                                                    React.jsx(NavigationPills.make, {
                                                          currentStep: match$4.currentStep,
                                                          stepCount: 1
                                                        }),
                                                    React.jsx(make, {
                                                          inspection: inspection$1,
                                                          deleteAttachment: (function (extra, extra$1) {
                                                              return deleteDetailImage(vnode, extra, extra$1);
                                                            })
                                                        })
                                                  ]
                                                }) : React.jsx(IntegerQuestionEditor.make, {
                                                  procedure: procedure,
                                                  inspection: inspection$1,
                                                  setValue: (function (extra) {
                                                      return setInteger(vnode, extra);
                                                    })
                                                })
                                        )
                                    )
                                )
                            )
                        )
                    );
                }
                var match$6 = vnode.state.data;
                var tmp$2;
                tmp$2 = typeof match$6 !== "object" ? -1 : match$6.back;
                var match$7 = vnode.state.data;
                var tmp$3;
                if (typeof match$7 !== "object") {
                  tmp$3 = Mithril.empty;
                } else {
                  var match$8 = match$7.procedureType;
                  var vessel$1 = match$7.vessel;
                  tmp$3 = match$8 === "OkNotOkQuestion" || match$8 === "RatingQuestion" ? React.jsx(make$6, {
                          continue: (function (extra) {
                              return $$continue(vnode, extra);
                            }),
                          savePhotos: (function (extra) {
                              return nextStep(vnode, extra);
                            }),
                          takePicture: (function (extra, extra$1) {
                              return addAttachment(vnode, extra, extra$1);
                            }),
                          resolution: vessel$1.max_pic_resolution,
                          currentStep: match$7.currentStep,
                          allowContinue: match$7.allowContinue,
                          use_embedded_camera: Vessel.getCameraConf(vessel$1)
                        }) : (
                      match$8 === "DateQuestion" ? React.jsx(make$5, {
                              callback: (function (_ev) {
                                  window.history.back();
                                }),
                              allowContinue: match$7.allowContinue
                            }) : (
                          match$8 === "PhotoAlbumQuestion" ? React.jsx(make$6, {
                                  continue: (function (extra) {
                                      return $$continue(vnode, extra);
                                    }),
                                  savePhotos: (function (extra) {
                                      return $$continue(vnode, extra);
                                    }),
                                  takePicture: (function (extra, extra$1) {
                                      return addAttachment(vnode, extra, extra$1);
                                    }),
                                  resolution: vessel$1.max_pic_resolution,
                                  currentStep: match$7.currentStep,
                                  allowContinue: match$7.allowContinue,
                                  use_embedded_camera: Vessel.getCameraConf(vessel$1)
                                }) : React.jsx(make$5, {
                                  callback: (function (extra) {
                                      return $$continue(vnode, extra);
                                    }),
                                  allowContinue: match$7.allowContinue
                                })
                        )
                    );
                }
                return React.jsxs(ListLayout.make, {
                            children: [
                              tmp,
                              tmp$1,
                              ReactDOM.jsx("div", {
                                    className: "divider blank"
                                  })
                            ],
                            title: title,
                            backButtonSteps: tmp$2,
                            bottom: Caml_option.some(tmp$3),
                            actions: actions
                          });
              }));
}

export {
  BaseInspectionEditor ,
  PictureInspection ,
  QuestionInspection ,
  YesNoInspection ,
  RateInspection ,
  MultiStepInspection ,
  title ,
  deleteDetailImage ,
  saveNewInspection ,
  addAttachment ,
  nextStep ,
  prevStep ,
  isItPossibleToContinue ,
  setValueByRating ,
  setValueByAnswer ,
  toggleAnnotation ,
  setAdditionalAnnotation ,
  setDeadline ,
  $$continue ,
  setText ,
  setInteger ,
  ContinueButton ,
  PictureInspectionButtons ,
  updateInspection ,
  make$7 as make,
}
/* Query Not a pure module */
