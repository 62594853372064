// Generated by ReScript, PLEASE EDIT WITH CARE

import * as LevelId from "./LevelId.js";
import * as AnswerId from "./AnswerId.js";
import * as VesselId from "../vessel/VesselId.js";
import * as FindingId from "./FindingId.js";
import * as ReIndexed from "@kaiko.io/rescript-reindexed/lib/es6/src/ReIndexed.js";
import * as QuestionId from "./QuestionId.js";

var Definition = {};

var null_comments = [];

var $$null = {
  id: FindingId.$$null,
  vessel_id: VesselId.$$null,
  created_by_id: AnswerId.$$null,
  solved_by_id: undefined,
  level_id: LevelId.$$null,
  question_id: QuestionId.$$null,
  status: "active",
  deadline: undefined,
  comments: null_comments
};

var Defaults = {
  $$null: $$null
};

var include = ReIndexed.MakeIdModel(Definition, {});

function ofVessel(vesselId) {
  return {
          TAG: "Is",
          _0: "vessel_id",
          _1: vesselId
        };
}

function createdBy(answerId) {
  return {
          TAG: "Is",
          _0: "created_by_id",
          _1: answerId
        };
}

var active = {
  TAG: "Is",
  _0: "status",
  _1: "active"
};

var rectified = {
  TAG: "Is",
  _0: "status",
  _1: "rectified"
};

function byDetectedWith(finding) {
  return finding.created_by_id;
}

function byLevel(finding) {
  return finding.level_id;
}

var Identifier;

var appName = "tree";

var IdComparator = include.IdComparator;

var Id = include.Id;

export {
  Identifier ,
  appName ,
  Definition ,
  Defaults ,
  IdComparator ,
  Id ,
  ofVessel ,
  createdBy ,
  active ,
  rectified ,
  byDetectedWith ,
  byLevel ,
}
/* include Not a pure module */
