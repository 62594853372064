// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as PageHeader from "./PageHeader.js";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.js";
import * as ProtectedView from "../../accounts/views/ProtectedView.js";
import * as KeepScrollLink from "../../../libs/KeepScrollLink.js";
import * as PageNavigation from "./PageNavigation.js";
import * as ToastNotification from "../components/ToastNotification.js";

function make(param) {
  return Mithril.view(Mithril.oncreate(Mithril.component(), (function (_vnode) {
                    KeepScrollLink.restoreScroll();
                  })), (function (vnode) {
                var header = Core__Option.getOr(vnode.attrs.header, React.jsx(PageHeader.make, {}));
                var footer = Core__Option.getOr(vnode.attrs.footer, React.jsx(PageNavigation.make, {}));
                return React.jsx(ProtectedView.make, {
                            children: ReactDOM.jsxs("div", {
                                  children: [
                                    header,
                                    ReactDOM.jsxs("main", {
                                          children: [
                                            React.jsx(ToastNotification.make, {}),
                                            vnode.children
                                          ],
                                          className: "tw-relative tw-flex tw-flex-col tw-flex-grow tw-overflow-auto"
                                        }),
                                    footer
                                  ],
                                  className: "t-app-view tw-flex tw-flex-col tw-h-full"
                                })
                          });
              }));
}

export {
  make ,
}
/* React Not a pure module */
