// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.js";
import * as KeepScrollLink from "../../../libs/KeepScrollLink.js";

function toClassName(theme) {
  return Core__Option.getOr(theme.color, "tw-text-primary");
}

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var disabled = Core__Option.getOr(vnode.attrs.disabled, false);
                var href = disabled ? "" : vnode.attrs.href;
                var theme = Core__Option.getOr(vnode.attrs.theme, {});
                return React.jsx(KeepScrollLink.make, {
                            href: href,
                            className: Mithril.classList([
                                  toClassName(theme),
                                  "tw-inline-flex tw-gap-1 tw-items-center tw-justify-center tw-py-1 tw-px-4",
                                  "tw-bg-transparent tw-border-0 tw-rounded-sm tw-h-7 tw-text-sm",
                                  "tw-appearance-none tw-no-underline tw-outline-none tw-select-none",
                                  "tw-text-center tw-align-middle tw-whitespace-nowrap",
                                  "tw-transition tw-duration-200",
                                  disabled ? "tw-cursor-default tw-pointer-events-none" : "tw-curson-pointer",
                                  disabled ? "tw-opacity-65" : "tw-opacity-100"
                                ], undefined),
                            children: Caml_option.some(vnode.children)
                          });
              }));
}

var Theme = {};

export {
  Theme ,
  make ,
}
/* React Not a pure module */
