// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../URLs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as State from "../../state/State.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril$1 from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as VesselId from "../VesselId.js";
import * as PageLayout from "../../common/views/PageLayout.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as State__User from "../../state/State__User.js";
import * as StillLoading from "../../common/views/StillLoading.js";
import * as ProtectedView from "../../accounts/views/ProtectedView.js";
import * as State__Memory from "../../state/State__Memory.js";

function findLandPage(vnode) {
  var user = vnode.state.data;
  if (typeof user !== "object") {
    if (user !== "Init") {
      return Prelude.thenDo(State__User.logout(), (function () {
                    Mithril$1.route.set("/login", undefined, {
                          replace: false
                        });
                  }));
    }
    var user$1 = State__Memory.Current.get().user;
    if (user$1 !== undefined) {
      return Prelude.thenDo(Query.doEach([
                      {
                        TAG: "Read",
                        _0: (function (param) {
                            var newrecord = Caml_obj.obj_dup(Query.makeRead());
                            newrecord.vessels = "All";
                            return newrecord;
                          })
                      },
                      {
                        TAG: "Read",
                        _0: (function (param) {
                            var newrecord = Caml_obj.obj_dup(Query.makeRead());
                            newrecord.staffers = {
                              TAG: "AnyOf",
                              _0: "vessel",
                              _1: param.vessels.map(function (v) {
                                    return v.id;
                                  })
                            };
                            return newrecord;
                          })
                      }
                    ]), (function (param) {
                    var vessels = param.vessels;
                    if (vessels.length !== 0) {
                      State.FetchVesselsList.$$fetch();
                      return $$continue(vnode, {
                                  TAG: "Deciding",
                                  _0: user$1,
                                  _1: vessels,
                                  _2: param.staffers
                                });
                    } else {
                      return $$continue(vnode, {
                                  TAG: "Refreshing",
                                  _0: user$1
                                });
                    }
                  }));
    } else {
      return $$continue(vnode, "InvalidState");
    }
  } else {
    switch (user.TAG) {
      case "Refreshing" :
          var user$2 = user._0;
          return Prelude.thenDo(Prelude.PromisedResult.bind(State.FetchVesselsList.$$fetch(), (async function (vessels) {
                            var newrecord = Caml_obj.obj_dup(Query.makeRead());
                            var match = await Query.read((newrecord.staffers = {
                                    TAG: "AnyOf",
                                    _0: "vessel",
                                    _1: vessels.map(function (v) {
                                          return v.id;
                                        })
                                  }, newrecord));
                            return {
                                    TAG: "Ok",
                                    _0: [
                                      vessels,
                                      match.staffers
                                    ]
                                  };
                          })), (function (res) {
                        if (res.TAG !== "Ok") {
                          return Mithril.setState(vnode, {
                                      TAG: "CannotSynchronize",
                                      _0: user$2
                                    });
                        }
                        var match = res._0;
                        var vessels = match[0];
                        if (vessels.length !== 0) {
                          return $$continue(vnode, {
                                      TAG: "Deciding",
                                      _0: user$2,
                                      _1: vessels,
                                      _2: match[1]
                                    });
                        } else {
                          return Mithril.setState(vnode, {
                                      TAG: "Empty",
                                      _0: user$2
                                    });
                        }
                      }));
      case "Deciding" :
          var vessels = user._1;
          var user$3 = user._0;
          var navigateBasedOnProfile = function (state, vessel) {
            if (Curry._1(Prelude.OptionExported.$$Option.isSome, state.profile)) {
              return leave(vnode, URLs.vessel(vessel.id), true);
            } else {
              return leave(vnode, "/landingpage/profile", true);
            }
          };
          var state = State__Memory.Current.get();
          var vesselIds = VesselId.$$Set.fromArray(vessels.map(function (v) {
                    return v.id;
                  }));
          var match = state.vessel;
          var len = vessels.length;
          if (len !== 1) {
            if (len !== 0) {
              if (match !== undefined && VesselId.$$Set.has(vesselIds, match.id)) {
                return navigateBasedOnProfile(state, match);
              } else {
                return leave(vnode, "/landingpage/vessel", true);
              }
            } else {
              return Mithril.setState(vnode, {
                          TAG: "Empty",
                          _0: user$3
                        });
            }
          }
          var vessel = vessels[0];
          var system = State__Memory.Transformations.setCurrentVessel(State__Memory.Transformations.make(state), vessel);
          var staffer = Curry._2(Prelude.$$Array.keep, user._2, (function (staff) {
                    return Caml_obj.equal(staff.vessel, vessel.id);
                  })).find(function (staff) {
                return Caml_obj.equal(staff.user, user$3.id);
              });
          State__Memory.Persisted.store(State__Memory.Transformations.run(Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, staffer, (function (staff) {
                              return State__Memory.Transformations.setCurrentRole(system, staff.role);
                            })), system)));
          return navigateBasedOnProfile(state, vessel);
      case "Leaving" :
          Mithril$1.route.set(user._0, undefined, {
                replace: user._1
              });
          return ;
      case "Empty" :
      case "CannotSynchronize" :
          return ;
      
    }
  }
}

function leave(vnode, url, replace) {
  $$continue(vnode, {
        TAG: "Leaving",
        _0: url,
        _1: replace
      });
}

function $$continue(vnode, state) {
  vnode.state.data = state;
  findLandPage(vnode);
}

function Vessels_Home(props) {
  return Mithril.view(Mithril.oninit(Mithril.component("Init"), findLandPage), (function (vnode) {
                var match = vnode.state.data;
                var tmp;
                var exit = 0;
                if (typeof match !== "object") {
                  tmp = React.jsx(StillLoading.make, {});
                } else {
                  switch (match.TAG) {
                    case "Empty" :
                    case "CannotSynchronize" :
                        exit = 1;
                        break;
                    default:
                      tmp = React.jsx(StillLoading.make, {});
                  }
                }
                if (exit === 1) {
                  var match$1 = vnode.state.data;
                  var tmp$1;
                  if (typeof match$1 !== "object") {
                    throw {
                          RE_EXN_ID: Prelude.AssertionError,
                          _1: "Empty or CannotSync",
                          Error: new Error()
                        };
                  }
                  switch (match$1.TAG) {
                    case "Empty" :
                        tmp$1 = "You are not assigned to any vessel";
                        break;
                    case "CannotSynchronize" :
                        tmp$1 = "Could not contact the cloud server";
                        break;
                    default:
                      throw {
                            RE_EXN_ID: Prelude.AssertionError,
                            _1: "Empty or CannotSync",
                            Error: new Error()
                          };
                  }
                  tmp = React.jsxs(PageLayout.make, {
                        footer: Caml_option.some(Mithril.empty),
                        children: [
                          ReactDOM.jsx("div", {
                                children: ReactDOM.jsx("h5", {
                                      children: tmp$1,
                                      className: "tw-text-center tw-text-lg"
                                    }),
                                className: "tw-w-full tw-p-4 tw-bg-slate-100 tw-m-b-2"
                              }),
                          ReactDOM.jsxs("p", {
                                children: [
                                  "You are currently logged with the email ",
                                  ReactDOM.jsx("b", {
                                        children: match._0.email
                                      }),
                                  ". Please, make sure this user has access to the Cloud Server."
                                ],
                                className: "tw-w-full tw-p-2 tw-text-sm"
                              }),
                          ReactDOM.jsxs("p", {
                                children: [
                                  "If this is not the right user, you can log out and log ",
                                  "in again with the right user."
                                ],
                                className: "tw-w-full tw-p-2 tw-text-sm"
                              }),
                          ReactDOM.jsxs("div", {
                                children: [
                                  ReactDOM.jsx("div", {
                                        className: "tw-flex-grow"
                                      }),
                                  ReactDOM.jsx("button", {
                                        children: "Log out!",
                                        className: "tw-border-solid tw-border-orange-600 hover:tw-border-orange-700\n                         tw-bg-orange-600 hover:tw-bg-orange-700 tw-text-white\n                         tw-drop-shadow tw-min-w-[7rem]\n                         tw-cursor-pointer tw-b-0 tw-text-sm tw-py-1 tw-px-4 tw-rounded-sm",
                                        onClick: (function (param) {
                                            State__User.logout();
                                          })
                                      }),
                                  ReactDOM.jsx("div", {
                                        className: "tw-flex-grow"
                                      })
                                ],
                                className: "tw-flex tw-gap-2 tw-w-full tw-p-2"
                              })
                        ]
                      });
                }
                return React.jsx(ProtectedView.make, {
                            children: tmp
                          });
              }));
}

var make = Vessels_Home;

export {
  make ,
}
/* URLs Not a pure module */
