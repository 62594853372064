// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Luxon from "luxon";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function fromCurrentDate(due_date, overdue_date) {
  var now = new Date();
  if (Caml_obj.lessequal(overdue_date, now)) {
    return "overdue";
  } else if (Caml_obj.lessthan(now, due_date)) {
    return "completed";
  } else {
    return "due";
  }
}

function filterOverdueStatus(ignore_overdueOpt, status) {
  var ignore_overdue = ignore_overdueOpt !== undefined ? ignore_overdueOpt : false;
  if (ignore_overdue && status === "overdue") {
    return "due";
  } else {
    return status;
  }
}

function getStatusColor(status) {
  if (status === "due") {
    return "gray";
  } else if (status === "overdue") {
    return "error";
  } else {
    return "success";
  }
}

function getDueTextFromDates(ignore_overdueOpt, due_date, overdue_date) {
  var ignore_overdue = ignore_overdueOpt !== undefined ? ignore_overdueOpt : false;
  var status = fromCurrentDate(due_date, overdue_date);
  var status$1 = filterOverdueStatus(ignore_overdue, status);
  if (status$1 === "due") {
    return "Due " + Luxon.DateTime.fromJSDate(overdue_date).setLocale("en").toRelative();
  } else if (status$1 === "overdue") {
    return "Overdue " + Luxon.DateTime.fromJSDate(overdue_date).setLocale("en").toRelative();
  } else {
    return "Completed";
  }
}

function min(items, cmp) {
  var len = items.length;
  if (len !== 1) {
    if (len !== 0) {
      return Caml_option.some(items.reduce((function (a, b) {
                        if (cmp(a, b)) {
                          return a;
                        } else {
                          return b;
                        }
                      }), items[0]));
    } else {
      return ;
    }
  } else {
    return Caml_option.some(items[0]);
  }
}

function greatestOverdueText(items) {
  var items$1 = items.map(function (i) {
        return {
                name: i.name,
                ignore_overdue: i.ignore_overdue,
                overdue_date: i.overdue_date,
                due_date: i.due_date,
                status: filterOverdueStatus(i.ignore_overdue, fromCurrentDate(i.due_date, i.overdue_date)),
                text: getDueTextFromDates(i.ignore_overdue, i.due_date, i.overdue_date)
              };
      });
  var earliest = min(items$1, (function (a, b) {
          var match = a.status;
          var match$1 = b.status;
          if (match === "due") {
            if (match$1 === "completed") {
              return true;
            }
            if (match$1 !== "due") {
              return false;
            }
            
          } else if (match === "overdue") {
            if (match$1 !== "overdue") {
              return true;
            }
            
          } else if (match$1 !== "completed") {
            return false;
          }
          if (Caml_obj.lessthan(a.overdue_date, b.overdue_date)) {
            return true;
          } else {
            return Caml_obj.lessthan(a.due_date, b.due_date);
          }
        }));
  if (earliest !== undefined) {
    return earliest.text;
  } else {
    return "";
  }
}

var Defaults = {
  completion: {
    totalNested: 0,
    totalInspected: 0,
    isOverdue: false
  }
};

function isFullyInspected(param) {
  return param.totalNested === param.totalInspected;
}

function reduce(completions) {
  return completions.reduce((function (a, b) {
                return {
                        totalNested: a.totalNested + b.totalNested | 0,
                        totalInspected: a.totalInspected + b.totalInspected | 0,
                        isOverdue: a.isOverdue || b.isOverdue
                      };
              }), {
              totalNested: 0,
              totalInspected: 0,
              isOverdue: false
            });
}

var _map = {"dueThisWeek":"dueThisWeek","dueThisMonth":"dueThisMonth","overdue":"overdue"};

function completionFilterToJs(param) {
  return param;
}

function completionFilterFromJs(param) {
  return _map[param];
}

function matchCompletionFilterFromDate(filter, due_date, overdue_date) {
  var month = Luxon.Duration.fromObject({
        months: 1
      });
  var week = Luxon.Duration.fromObject({
        weeks: 1
      });
  var today = Luxon.DateTime.now();
  var due_date$1 = Luxon.DateTime.fromJSDate(due_date);
  var overdue_date$1 = Luxon.DateTime.fromJSDate(overdue_date);
  if (filter === "dueThisMonth") {
    if (Caml_obj.greaterequal(today.plus(month), overdue_date$1) && Caml_obj.lessequal(due_date$1, today)) {
      return Caml_obj.lessthan(today, overdue_date$1);
    } else {
      return false;
    }
  } else if (filter === "overdue") {
    return Caml_obj.lessequal(overdue_date$1, today);
  } else if (Caml_obj.greaterequal(today.plus(week), overdue_date$1) && Caml_obj.lessequal(due_date$1, today)) {
    return Caml_obj.lessthan(today, overdue_date$1);
  } else {
    return false;
  }
}

export {
  fromCurrentDate ,
  filterOverdueStatus ,
  getStatusColor ,
  getDueTextFromDates ,
  greatestOverdueText ,
  Defaults ,
  isFullyInspected ,
  reduce ,
  completionFilterToJs ,
  completionFilterFromJs ,
  matchCompletionFilterFromDate ,
}
/* luxon Not a pure module */
